import Engine, { Logging, LoggingArea, NavCamComponent, NavMeshLink, NavPlayeableObjComponent, RPMAvatar } from "@ravespaceio/rave-engine";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { Object3D, Mesh, Vector3 } from "three"
import SpawnManager, { NavigationControllerTypes } from "../manager/SpawnManager";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import { getLsItem, setLsItem } from "../utils/storeHelper";
import { createNavLinkDebugSphere, debugNavMeshPerimeter } from "../utils/navigationDebug";




export function setupNavigation(scene: Object3D) {

	const space = getSpace()
	const engine = getEngine()

	// setup zones
	const zones = findObjects(scene, testUserData("NavmeshID"))
	zones.forEach((zoneMesh) => {
		const geo = (zoneMesh as Mesh).geometry
		const zoneName = zoneMesh.userData.NavmeshID
		engine.navigation.navigationComponentM.addZone(geo, zoneName);
	})
	Logging.trace(`Navigation zones: ${engine.navigation.getActiveZones()}`, LoggingArea.Space)

	// navLinks - the setup has to be call after addZone() because it needs the zones to be created in order to work
	engine.navigation.navLinkManagerM.createNavLinks();
	// ------------------------------------------------- debug NavLinks Visuals and debugui -----------------------------------------
	const navigationFolder = engine.debuggui.biggui.getFolderByName('Engine').getFolderByName('Navigation');
	if (!space.ENV.IS_PROD) {
		debugNavMeshPerimeter(engine.navigation.pathfinder.navMeshPerimeter, 0.2, navigationFolder, engine.scene, true);
		createNavLinkDebugSphere(engine.navigation.navLinkManagerM.getAllNavLinks(), navigationFolder, engine.scene, true);
	}


	// setup navComponents
	const playerNav = new NavPlayeableObjComponent(engine.navigation, { constrainedObject: engine.player.getPlayer() });
	const camNav = new NavCamComponent(engine.navigation, { constrainedObject: engine.player.camControllerM.getController<ThirdCameraController>(CamControllersNames.ORBIT_CAMERA) })
	engine.navigation.navigationComponentM.addNavigationComponent<NavigationControllerTypes>('playerNav', playerNav);
	engine.navigation.navigationComponentM.addNavigationComponent<NavigationControllerTypes>('camNav', camNav);
	engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, NavPlayeableObjComponent>('playerNav').setNavigationZones(engine.navigation.getActiveZones());
	engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, NavCamComponent>('camNav').setNavigationZones(engine.navigation.getActiveZones());

	// spawns
	const spawnManager = space.spawnManager
	spawnManager.parseScene(scene)
	Logging.trace(`Spawn points: ${spawnManager.getSpawnList()}`, LoggingArea.Space)

	const urlSpawn = space.browser.urlParameter.get("spawnAt")
	if (urlSpawn && spawnManager.hasSpawn(urlSpawn)) {
		spawnManager.defaultSpawnName = urlSpawn
		Logging.trace(`Default spawn point set to ${urlSpawn}`, LoggingArea.Space)
	}

	// cached logout position
	// setInterval(() => {
	// 	setLsItem("lougoutPosition__" + space.project.spaceId, engine.player.getPlayer().position)
	// 	// setLsItem("lougoutDirection__" + space.project.spaceId, engine.player.getPlayer().getWorldDirection(tempVec))
	// }, 5000)
	// let outpos = getLsItem("lougoutPosition__" + space.project.spaceId)
	// let outdir = getLsItem("lougoutDirection__" + space.project.spaceId)
	// if (outpos /*&& outdir */ && !urlSpawn) {
	// 	const spawn = new Object3D()
	// 	spawn.position.copy(outpos)
	// 	// spawn.lookAt(outdir) // TODO falsch
	// 	space.spawnManager.spawnAtObject(spawn)
	// } else {
	space.spawnManager.spawnDefault(new Vector3((Math.random() - 0.5), 0, (Math.random() - 0.5)))
	// }
}



