import Engine, { CircleShadow, EngineUtils, RPMAvatar } from "@ravespaceio/rave-engine";
import * as THREE from "three"
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Group } from "three"
import { storeToRefs } from "pinia";
import { ref, watch } from 'vue';
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";

export async function setupStage() {

	const engine = getEngine()
	const space = getSpace()

	var skybar_chairs = new Group() // chairs
	var skybar_lectern = new Group() // pult

	await space.loader.getLoadingPromise("skybar").then((gltf: GLTF) => {
		const empties = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR")
		for (var i = 0; i < empties.length; i++) {
			if (empties[i].userData.CMSR == "skybar_chairs") {
				skybar_chairs.attach(empties[i])
			}
			if (empties[i].userData.CMSR == "skybar_panel") {
				skybar_lectern.attach(empties[i])
			}
		}
	})

	engine.scene.add(skybar_chairs)
	engine.scene.add(skybar_lectern)

	space.eventbus.onAlready("skybarStageMode", (data: any) => {
		if (data == "panel") {
			skybar_chairs.visible = false
			skybar_lectern.visible = true
		}
		if (data == "chairs") {
			skybar_chairs.visible = true
			skybar_lectern.visible = false
		}
		if (data == "clear") {
			skybar_chairs.visible = false
			skybar_lectern.visible = false
		}
	});

}
