<template>
	<div>
		<div v-if="isDisconnected" class="multiplayer-status-hint">
			<template v-if="!rejoinTried">
				<p>You are disconnected from other users.</p>
				<div class="btn" :class="isLoading ? 'disabled' : ''" @click="rejoin()">Rejoin now
					<IconLoader v-if="isLoading" />
				</div>
			</template>
			<template v-else>
				<p>You are still disconnected from other users. Try tips from the <a href="https://ravespace.io/faq/you-are-disconnected-from-the-server" target="_blank">FAQ</a></p>
			</template>
		</div>

	</div>
</template>

<script setup lang="ts">
import { getEngine } from '~/space/engine';
import { joinAnyRoom } from '~/space/lib/mechanic/multiplayer';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';


const isDisconnected = ref(false)
const rejoinTried = ref(false)
const isLoading = ref(false)

const space = getSpace()
const engine = getEngine()
// TODO consider first mp join failed


onMounted(() => {
	if (!engine.multiplayer) return;
	engine.multiplayer!.onRoomJoinEvent.on((data) => {
		isDisconnected.value = false
		rejoinTried.value = false
		isLoading.value = false
	})
	engine.multiplayer!.onRoomLeftEvent.on((data) => {
		space.eventbinder.bindTimeout(() => {
			if (!engine.multiplayer!.room) isDisconnected.value = true
		}, 2000)
	})
})


function rejoin() {
	isLoading.value = true
	rejoinTried.value = true
	joinAnyRoom()
	space.eventbinder.bindTimeout(() => {
		rejoinTried.value = true
		isLoading.value = false
	}, 3000)
}
</script>

<style scoped lang="scss">
.multiplayer-status-hint {
	position: fixed;
	top: 10px;
	width: 300px;
	left: calc(50% - 150px);
	z-index: 99;
	color: white;
	background: rgba(0, 0, 0, 0.8);
	padding: 10px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-direction: column;

	.btn {
		padding: 9px 20px;
		margin-top: 12px;
		display: flex;
		justify-content: center;
		align-items: center;

		.loader {
			filter: invert(1);
			transform: translate(2px, 2px);
		}

	}


	p {
		font-size: 14px;
		text-align: center;
		color: white;

		a {
			color: white;
			font-weight: 700;
		}
	}
}

@media(max-width: 400px) {
	.multiplayer-status-hint {
		display: none;
	}
}
</style>
