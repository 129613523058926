import { EngineUtils } from "@ravespaceio/rave-engine";
import * as THREE from "three";
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"

export function setupStageScreen() {

	const space = getSpace()

	const screenMat = new THREE.MeshBasicMaterial()

	let urlCMS: undefined;

	space.loader.getLoadingPromise("skybar").then((gltf) => {
		const screens = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR") as THREE.Mesh[]
		screens.forEach((screen) => {
			// @ts-ignore
			if (screen.userData.CMSR == "skybar_bigscreen") {
				if (urlCMS !== undefined) {
					// change logic and place a different placeholder
				} else {
					// use placeholder from local file
					space.screenManager.addScreen(101, screen, screenMat, "useDefaultVideo");
				}
			}
		});
	})
}
