import { Logging, LoggingArea } from "@ravespaceio/rave-engine"


// local storage getter setter wrapper
const prefix = "__raveengine__" // + space.project.spaceId + "__"

export function getLsItem(key: string): any | undefined {
	const l = localStorage.getItem(prefix + key)
	if (!l) return undefined
	try { return JSON.parse(l) }
	catch { return l }
}


export function setLsItem(key: string, value: any): void {
	localStorage.setItem(prefix + key, JSON.stringify(value))
}


/**
 * helper function that watches on state change and saves to localStorage
 * reads localStorage initially
 *
 * @param ref
 * @param key
 * @param check check that must pass before an item is loaded from or saved to localStorage
 *
 * TODOS
 * - parser (string, bool, object)
 * - state obj type support
 * - support for state values (so e.g. ref(1))
 */
export function setupReactiveLocalStorage<T extends Object>(ref: Ref<T>, key: keyof T, check?: (x: any) => boolean) {
	const k = key.toString()
	const lsValue = getLsItem(k)
	if (lsValue !== undefined) {
		if (!check) {
			ref.value[key] = lsValue
		} else {
			if (check(lsValue)) {
				ref.value[key] = lsValue
			} else {
				Logging.warn(`RLS ${k} read check failed`, LoggingArea.Space)
			}
		}
	}

	watch(() => ref.value[key], (state) => {
		const k = key.toString()
		if (!check) {
			setLsItem(k, state)
		} else {
			if (check(state)) {
				setLsItem(k, state)
			} else {
				Logging.warn(`RLS ${k} write check failed`, LoggingArea.Space)
			}
		}
	}, { deep: true })
}

