import { Loader, Eventbus, Logging, LogLevel, EventbusStore, LoggingArea } from "@ravespaceio/rave-engine"
import EventBinder from "@ravespaceio/rave-engine/build/engine/src/helper/data/EventBinder"
import { isMobile, isVRSupportedAndNotMobile } from "@ravespaceio/rave-engine/build/engine/src/utils/browser"
import PCISystem from "./lib/manager/PCISystem"
import SpawnManager from "./lib/manager/SpawnManager"
import { BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from 'detect-browser'
import ScreenManager from "./lib/manager/ScreenManager"



export type StageType = "dev" | "stage" | "prod"

export type SpaceEnv = {
	IS_PROD: boolean
	STAGE: StageType
	GAMESERVER_HOST: string,
	GAMESERVER_URI: string,
}


var space: Space
export function getSpace(): Space {
	if (!space) { console.error("Space not setup") }
	return space
}

export type Space = {
	ENV: SpaceEnv
	project: {
		publicPath: string,
		id: string
	},
	browser: {
		urlParameter: URLSearchParams,
		info: BrowserInfo,
		isMobile: boolean
		isSafari: boolean
		isIos: boolean
		isVR: Promise<boolean>
	}
	//spaceScriptSys: SpaceScriptSystem,
	loader: Loader,
	eventbinder: EventBinder,
	eventbus: Eventbus<any> // <"firstinteract" | "onboardingDone" | "qualitysetting" | "respawn">,
	spawnManager: SpawnManager<any>, // <"spawn">
	screenManager: ScreenManager,
	pciSystem: PCISystem
}



export function setupSpace(): Space {

	// logger
	const ENV = useRuntimeConfig().public as SpaceEnv
	if (ENV.STAGE == "prod") {
		Logging.setupLogger(Logging.buildDefaultBrowserLogger(LogLevel.info));
	} else {
		Logging.setupLogger(Logging.buildDefaultBrowserLogger(LogLevel.trace));
		// Logging.enableExlusivLogging(LoggingArea.Space)
	}
	Logging.info("setupSpace", LoggingArea.Space)
	Logging.info("env: " + JSON.stringify(ENV), LoggingArea.Space)

	// space
	const bInfo = detect() as BrowserInfo
	const SPACE = space = {
		ENV,
		project: {
			publicPath: "rspSpace",
			id: "rsp"
		},
		browser: {
			info: bInfo,
			urlParameter: new URLSearchParams(window.location.search),
			isMobile: isMobile(),
			isSafari: bInfo.name == "safari",
			isIos: bInfo.os == "iOS",
			isVR: isVRSupportedAndNotMobile()
		},
		// spaceScriptSys: new SpaceScriptSystem(),
		loader: new Loader(),
		eventbus: new Eventbus(),
		eventbinder: new EventBinder(),
		spawnManager: new SpawnManager(),
		screenManager: new ScreenManager(),
		pciSystem: new PCISystem()
	}

	// ENGINE.multiplayer?.agoraSdk.setAgoraLogLevel(0)
	EventbusStore.customPrefix = space.project.id

	return SPACE
}
