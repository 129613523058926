


export const useGameStore = defineStore({
	id: "game",
	state: () => ({
		treasureHuntState: 0,
		miniGamePopup: false,
		gameStarted: false,
		hits: 0
		// onboardinDone: false,
		// quality: "" as ("low" | "high" | "mid")
	})
})

