import { MyLoaders } from "@ravespaceio/rave-engine"
import { Object3D, Material, SpotLight, SpotLightHelper, SphereGeometry, Group, Vector3, MeshBasicMaterial, ConeGeometry, Mesh } from "three"
import * as THREE from "three"



const tempvec = new Vector3()

/**
 * alternative
 * https://github.com/pmndrs/drei/blob/e3aafb0ffaa4c8a99927c0c0edd51950f87a4aab/src/materials/SpotLightMaterial.tsx
 * http://john-chapman-graphics.blogspot.com/2013/01/good-enough-volumetrics-for-spotlights.html
 *
 * // TODO target nicht childen sondenr von außen ne position geben
 */
export default class MovingHead extends Mesh {

	public readonly target: Object3D = new Object3D()
	public readonly lightCone: Mesh
	public readonly spotlight: SpotLight
	public readonly helper: SpotLightHelper

	private lightAnker: Object3D = new Object3D()

	public get coneMaterial(): THREE.Material {
		// @ts-ignore
		return this.lightCone.material
	}

	constructor(mat: Material) {
		super()

		// anker
		const lightAnker = this.lightAnker // TODO implement

		// pos für was?
		const lampPos = new Vector3(0, 0, 0)

		// target
		const target = this.target
		target.name = "MovingHeadTarget"
		const targetSphere = new Mesh(new SphereGeometry(0.2), new MeshBasicMaterial())
		target.add(targetSphere)


		// spotlight
		// const spotlight = new SpotLight()
		// spotlight.position.copy(lampPos)
		// spotlight.target = target
		// spotlight.intensity = 5
		// spotlight.distance = 10 // 0 equals infinity
		// spotlight.angle = 0.1 // Maximum angle of light dispersion from its direction whose upper bound is Math.PI/2.
		// spotlight.penumbra = 1 // percent of the spotlight cone that is attenuated due to penumbra. Takes values between zero and 1. Default is zero.
		// spotlight.decay = 0.1 // The amount the light dims along the distance of the light.
		// this.spotlight = spotlight
		// this.add(spotlight)

		// const helper = new SpotLightHelper(spotlight);
		// helper.visible = false;
		// this.helper = helper
		// this.add(helper)

		// light cone
		const alphaMap = MyLoaders.texLoader.load("/RaveEngine/textures/gradients/gradient_cloud.jpg")
		alphaMap.wrapS = alphaMap.wrapT = THREE.RepeatWrapping
		alphaMap.flipY = false
		// const coneMat = new MeshBasicMaterial({
		// 	side: THREE.DoubleSide,
		// 	transparent: true,
		// 	opacity: 0.3,
		// 	color: "red",
		// 	alphaMap,
		// 	alphaTest: 0.01,
		// })

		const coneGeo = new ConeGeometry(1, 1, 16, 4, true)
		coneGeo.translate(0, -0.5, 0)
		coneGeo.lookAt(new Vector3(0, 1, 0))
		coneGeo.scale(1, 1, 8) // z is cone length

		const lightCone = new Mesh(coneGeo, mat)
		lightCone.position.copy(lampPos)
		this.lightCone = lightCone
		this.add(lightCone)

		const spotLightAnker = new Mesh(new SphereGeometry(0.2), new MeshBasicMaterial())
		lightCone.add(spotLightAnker)
		spotLightAnker.visible = false
	}

	update(): void {
		this.lightCone.lookAt(this.target.getWorldPosition(tempvec))
		if (this.helper) {
			this.helper.update()
			this.helper.visible = false
		}
	}
}
