<template>
	<iframe id="iframe" class="content" allow="camera *; microphone *" src="https://ravespace.readyplayer.me" />
</template>


<script setup lang="ts">
import { RPMAvatar } from '@ravespaceio/rave-engine';
import { AvatarConfig, RPM_API_CONFIG, RPM_API_URL, getAvatarPreviewImg } from '~/space/lib/utils/rpm';
import { rpmRndScale } from '~/space/lib/utils/rpm';



const emits = defineEmits<{
	(e: 'avatarChosen', config: AvatarConfig): void
	(e: 'avatarPreview', preview: string): void
}>()

// Listen to messages from the iframe
window.addEventListener('message', (event) => {
	// Check if the received message is a string and a glb url
	if (typeof event.data === 'string' && event.data.startsWith('https://') && event.data.endsWith('.glb')) {
		const url = event.data
		const id = extractID(url, 'https://models.readyplayer.me/', '.glb');
		const jsonurl = url.replace("glb", "json");

		fetch(jsonurl).then(response => response.json()).then((data) => {
			const model = RPM_API_URL + id + RPM_API_CONFIG
			const config = {
				model,
				gender: data.outfitGender,
				scale: rpmRndScale(),
				preview: "loading",
			}
			emits("avatarChosen", config)
			getAvatarPreviewImg(model, (prev) => { emits("avatarPreview", prev) })
		})
	}
}, false)

function extractID(str: string, start: string, end: string) {
	const result = str.match(new RegExp(start + "(.*)" + end));
	return result![1];
}

</script>
