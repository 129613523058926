import Engine, { EngineUtils, RPMAvatar, MirrorSphere, Logging, LoggingArea } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { useToast } from 'vue-toastification';
import { useMultiplayerStore } from "~~/src/store/multiplayer";
import { RoomType } from "@ravespaceio/rave-engine/build/engineserver-common/src/RoomType";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import { setupAgoraVoiceGroups } from "./agora/voicegroupsSU";
import { setupAgoraCloudPlayer } from "./agora/cloudplayerSU";
import { setupScreenShare } from "./agora/screenshareSU";
import { setupMultichannelsRoles } from "./agora/multichannelSU";
import { useSpaceStore } from "~/store/space";
import { setupReactiveLocalStorage } from "../utils/storeHelper";
import { AvatarConfig, RPM_PRE_AVATARS, transformAvatarConfig } from "../utils/rpm";


export function setupMultiplayer() {
	const engine = getEngine()
	const space = getSpace()
	const roomType = RoomType.defaultRoom

	if (!engine.multiplayer || !roomType) return;

	const toast = useToast()
	const mpStore = useMultiplayerStore()
	const spaceStore = useSpaceStore()

	engine.multiplayer.setupServerConnection();

	// log server version
	fetch(space.ENV.GAMESERVER_URI + "/api/versions").then((d) => d.json()).then((e) => {
		Logging.info(`RaveServer version ${e.server} build for engine ${e.engine}`, LoggingArea.Multiplayer)
	})

	// when switching tab and navigation is not setup yet player is at origin https://app.asana.com/0/1201373246765589/1204258347159704/f
	watch(() => spaceStore.onboardingDone, () => {
		joinAnyRoom(roomType);
	})

	// setup agora on first join
	let agoraSetup = false
	engine.multiplayer.onRoomJoinEvent.on(async (data) => {
		if (!agoraSetup) {
			await setupAgoraVoiceGroups();
			await setupAgoraCloudPlayer();
			await setupMultichannelsRoles(); // this function has to be call first than setupScreenShare otherwise setupscreenshare will fail
			setupScreenShare();
			agoraSetup = true
		}
	})

	// multiplayer data
	engine.multiplayer!.chatHandler.onNewChatMessageEvent.on((chatmessage) => {
		if (chatmessage) mpStore.chats.push(chatmessage);
	})
	let onceNameSet = false
	engine.multiplayer.playerNameHandler.onNameChangedEvent.on((playerId) => {
		const myId = engine.multiplayer?.playerId
		if (playerId && myId && (myId === playerId)) {
			const myName = engine.multiplayer.onlinePlayerStates[myId].playerNameState
			if (myName) {
				if (onceNameSet) toast.success("Name changed to " + myName + ".");
				onceNameSet = true
			}
		}
	})
	let onceRoomJoined = false
	engine.multiplayer.onRoomJoinEvent.on((data) => {
		if (!data) return;
		mpStore.updateRoomAndPlayerID()
		if (onceRoomJoined) toast.success("Room " + data.id + " joined.");
		onceRoomJoined = true
	})
	engine.multiplayer.onRoomLeftEvent.on(() => {
		mpStore.updateRoomAndPlayerID()
		toast.success("Room " + mpStore.roomId + " left.");
	})

	// watch multiplayer related frontend state changes
	watch(() => spaceStore.playerName, (state) => { changeName(state) }, { immediate: true })
	watch(() => spaceStore.avatar, (state) => { changeAvatar(state) }, { immediate: true, deep: true })
}


export async function joinAnyRoom(roomType?: RoomType): Promise<void> {
	const engine = getEngine()
	if (!engine.multiplayer) return;
	if (engine.multiplayer.room) {
		Logging.info("you already in a room", LoggingArea.Multiplayer)
		return;
	}
	if (!roomType) {
		Logging.trace("No room type set, using default room", LoggingArea.Multiplayer)
		roomType = RoomType.defaultRoom
	}
	engine.multiplayer.setRoomType(roomType);
	const { name, avatar, token } = getMpParams()
	await engine.multiplayer.joinAnyRoom(name, avatar, { token });
}


export async function joinSpecificRoom(roomType: RoomType, roomId: string): Promise<void> {
	const engine = getEngine()
	if (!engine.multiplayer) return;
	if (engine.multiplayer.room) {
		Logging.info("you already in a room, leaving that room", LoggingArea.Multiplayer)
		await engine.multiplayer.leaveCurrentRoom();
	}
	engine.multiplayer.setRoomType(roomType);
	const { name, avatar, token } = getMpParams()
	await engine.multiplayer.joinSpecificRoom(roomId, name, avatar, { token }).then((success) => {
		if (success == false) {
			Logging.warn("cannot join, room is full. joining now any any other room", LoggingArea.Multiplayer);
			joinAnyRoom(roomType);
		}
	})
}


function getMpParams() {
	const space = getSpace()
	const spaceStore = useSpaceStore()
	return {
		name: spaceStore.playerName,
		token: space.browser.urlParameter.get("roleToken") || "",
		avatar: transformAvatarConfig(spaceStore.avatar)
	}
}


export function changeName(name: string) {
	useSpaceStore().playerName = name
	const engine = getEngine()
	if (engine.multiplayer!.room) {
		engine.multiplayer!.playerNameHandler.setName(name)
	}
}

export function changeAvatar(avatar: AvatarConfig) {
	// TODO check in engine dass nicht jedes mal neuer avatar geladen wird und zb ggf nur gescaled wird
	const spaceStore = useSpaceStore()
	spaceStore.avatar = avatar
	const engine = getEngine()
	if (engine.multiplayer!.room) {
		engine.multiplayer!.avatarHandler.setAvatar(transformAvatarConfig(avatar))
	}
}

/**
 * change player avatar for a given duration without saving to local storage
 */
export function changeAvatarInterim(model: string, seconds: number,) {
	const engine = getEngine()
	const spaceStore = useSpaceStore()
	const config = RPMAvatar.generateRpmConfig(model, "masculine")
	if (engine.multiplayer!.room) { engine.multiplayer!.avatarHandler.setAvatar(config) }

	setTimeout(() => {
		if (engine.multiplayer!.room) { engine.multiplayer!.avatarHandler.setAvatar(transformAvatarConfig(spaceStore.avatar)) }
	}, seconds * 1000)
}



