// import { watch } from 'vue'
import { Logging, LoggingArea } from "@ravespaceio/rave-engine"
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Role"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { useScreenStore } from "~/store/agora/screens"

export type channelNameType = 'all' | 'none'


/**
 * requires multichannel setup
 */
export async function setupScreenShare() {
	const engine = getEngine()
	const space = getSpace()


	const multiChannelHandler = engine.multiplayer!.multiChannelHandler;

	const screenStore = useScreenStore();
	screenStore.isScreenShareActive = true;



	multiChannelHandler.getAllHandlers().forEach((handler) => {


		handler.onVideoTrackAdded.on(data => {
			if (data === undefined) {
				return;
			}
			const videoTrack = data.videoTrack.getMediaStreamTrack()
			const mediaStream = new MediaStream();
			mediaStream.addTrack(videoTrack);
			videoTrack.addEventListener('ended', () => {
				engine.multiplayer!.videoScreenHandler.deleteScreen({ screen_id: data.screenId!, channel: data.channel!, userUid: data.userUid });
			})
			space.screenManager.replaceTexturebyId(mediaStream, data.screenId!);

			if (handler.userUid !== data.userUid) {
				screenStore.occupiedScreens = [...screenStore.occupiedScreens, data.screenId!]
				console.log(screenStore.occupiedScreens)
			}

		})

		// call when the videotrack is remove.

		handler.onVideoTrackRemoved.on(data => {
			if (data === undefined) return;

			space.screenManager.replaceTexturebyId(undefined, data.videoId);
			if (handler.userUid !== data.userUid!) {
				if (screenStore.occupiedScreens.includes(data.videoId!)) {
					screenStore.occupiedScreens = screenStore.occupiedScreens.filter(screenId => screenId !== data?.videoId!)
				}
			} else {
				if (screenStore.ownedScreens.includes(data.videoId!)) {
					screenStore.ownedScreens = screenStore.ownedScreens.filter(screenId => screenId !== data?.videoId!)
				}
			}
		})

		handler.onDeniedCameraAccess.on(() => {
			alert('please allow camera access');
		})

		handler.onDeniedMicrophoneAccess.on(() => {
			alert('please allow microphone access')
		})

	})

	// only for host user

	multiChannelHandler.getHostHandlers().forEach(handler => {
		handler.onScreenVideoIdInvalidEvent.on((payload) => {
			alert(payload!.message);
		});

		handler.onScreenOccupiedbyOtherUserEvent.on((payload) => {
			alert(payload!.message);
		});

		handler.onScreenOccupiedbyOwnUserEvent.on((payload) => {
			const anwser = confirm(payload!.message);
			if (anwser) {
				handler.updateBroadcastVideo(payload!.videoOptions);
			} else {
				alert('broadcast updated canceled');
			}
		});

		handler.onCreateVideoOnAnotherScreenEvent.on((payload) => {
			console.log(payload!.videoOptions)
			const anwser = confirm(payload!.message);
			if (anwser) {
				handler.updateBroadcastVideo(payload!.videoOptions);
			} else {
				alert('broadcast updated canceled');
			}

		})

		handler.onCpOccupiedScreenEvent.on((payload) => {
			alert(payload!.message);
		});

		handler.onCpRemoveUserVideoEvent.on((payload) => {
			alert(payload!.message);
		});

		registerScreensIds();
	})


	function registerScreensIds() {
		const screens = Array.from(engine.multiplayer!.room.state.videoScreenState.allowedScreens_perChannel_InRoom.get("all")!.screens.values());
		screenStore.screenIds = screens;
	}




	watch(() => screenStore.ownedScreens, (current, prev) => {
		screenStore.screenIds.forEach(screenId => {
			if (current.includes(screenId) && !screenStore.occupiedScreens.includes(screenId) && !prev.includes(screenId)) {
				console.log('broadcasting video')
				multiChannelHandler.getHostHandler<channelNameType>('all').broadcastVideo({ screenId, origin: 'screen' })
			} else if (!current.includes(screenId) && !screenStore.occupiedScreens.includes(screenId) && prev.includes(screenId)) {
				multiChannelHandler.getHostHandler<channelNameType>('all').stopBroadcastVideo(screenId);
			}
		})

	})
}



