<template>
	<SpaceModal :show=show @closed="close()">
		<div v-if="npcModal" class="content" :class="npcModal.npcImage ? 'npc-contains' : ''">
			<h2>{{ npcModal.title }}</h2>
			<p>{{ npcModal.text }}</p>
			<a v-if="npcModal.link" :href="npcModal.link" class="btn" target="_blank">More info</a>
			<img v-if="npcModal.npcImage" :src="npcModal.npcImage" class="npc">
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { useSpaceStore } from '~/store/space';
import { getSpace } from '~/space/space';
import { storeToRefs } from "pinia"

const space = getSpace()
const spaceStore = useSpaceStore()

const { npcModal } = storeToRefs(spaceStore)
const show = computed(() => !!npcModal.value)

function close() {
	npcModal.value = undefined
}

</script>


<style lang="scss" scoped>
@import "assets/css/variables";

.content {
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 30px 0 50px;

	p,
	h2 {
		color: white;
		text-align: center;
	}

	h2 {
		font-size: 35px;
		font-weight: 600;

	}

	p {
		margin: 12px 0 20px;
		max-width: 500px;
	}

	img {
		position: absolute;
		left: -82px;
		width: 300px;
		bottom: -10px;
	}

}

@media(max-width:800px) {
	img {
		display: none;
	}
}
</style>
