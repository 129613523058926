import Engine, { CircleShadow, EngineUtils, Logging, RPMAvatar } from "@ravespaceio/rave-engine";
import * as THREE from "three"
import { setupAudio } from "./scene/audio";
import { setupBar } from "./scene/bar";
import { setupModels } from "./scene/models";
import { setupNPCs } from "./scene/npcs";
import { setupAnimatedObjects } from "./scene/animatedObjects";
import { setupPlayer } from "./player";
import { setupEnviroment } from "./enviroment";
import { setupStadium } from "./stadium/main";
import { setupSkybar } from "./skybar/setup";
import { setupVR } from "./vr/vr";
import { setupTreasurehunt } from "./scene/treasurehunt";
import { setupPortals } from "./scene/portals";
import { setupMinigame } from "./minigame";
import { setupBoatRace } from "./Boat/race";
import CloudPlayerManager from "../lib/manager/CloudPlayerManager";
import { getEngine } from "../engine";
import { getSpace } from "../space";
import { setupMultiplayer } from "~/space/lib/mechanic/multiplayer";


export function setupRsp() {

	const space = getSpace()
	const engine = getEngine()

	setupPlayer()
	setupEnviroment()
	setupAudio()

	new CloudPlayerManager(engine, space.screenManager, { prefix: 'DEMO_', stage: space.ENV.STAGE })

	setupModels()
	setupMultiplayer()
	setupNPCs()
	setupStadium()
	setupSkybar()
	setupTreasurehunt()
	setupPortals()
	setupMinigame()
	setupBoatRace()

	// screens setup
	space.screenManager.setAllowedUids([100, 101]);
	engine.audioManager.setupPromise.then(() => {
		space.screenManager.videoElements.get(space.screenManager.defaultPlaceHolderId)!.play();
	})


	space.browser.isVR.then(isVR => {
		if (isVR) {
			Logging.info("Skipping setupEffects() and setupBar() because VR device")
			setupVR();
		} else {
			Logging.info("Skipping setupVR() because NOT VR device");
			setupBar() // VR doesnt allow postpro
		}
	})

	space.eventbus.on("onboardingDone", () => {
		setupAnimatedObjects(engine)
	})

	space.loader.startLoading()
}
