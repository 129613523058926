import Engine, { RPMAvatar, PlanarIntensityFade, HighlightCylinder, PostProcessingThree } from "@ravespaceio/rave-engine";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import Particles from "@ravespaceio/rave-engine/build/engine/src/objects/particles/Particles";
import { useSpaceStore } from "~/store/space";
import * as THREE from "three"
import { getEngine } from "~/space/engine";
import { BlockingHintCollider, PCICollider, blockForFrontend, unblockFromFrontend } from "~/space/lib/manager/PCISystem";
import { createNPC } from "~/space/lib/mechanic/npc";
import { getSpace } from "~/space/space";
import EffectManager from "~/space/lib/manager/EffectManager";
import Effect from "~/space/lib/manager/Effect";
import { changeAvatarInterim } from "~/space/lib/mechanic/multiplayer";


export function setupBar() {
	const space = getSpace()
	const engine = getEngine()
	const spaceStore = useSpaceStore()

	// engine.renderer.renderer.outputEncoding = THREE.sRGBEncoding
	engine.renderer.renderer.outputEncoding = THREE.LinearEncoding
	// engine.renderer.composerEnabled = true

	const ppManager = new PostProcessingThree(engine)
	engine.userData.ppManager = ppManager

	const effectManager = new EffectManager()
	engine.loop.register((dt) => {
		effectManager.update(dt)
	})
	space.eventbus.on("drinkBeerEffect", (drink) => {
		effectManager.activateEffect(drink.drinkID)
		const dur = effectManager.getEffect(drink.drinkID)!.duration
		space.eventbus.emit("drinkBeerEffectProgress", { drink, dur })
	})

	// const defaultSpeed = engine.player.physics.speed
	const defaultFOV = engine.player.camera.fov

	const effect1 = new Effect([{
		onEnableChanged: (enabled) => {
			ppManager.flowPass.enabled = enabled
			ppManager.bloomPass.enabled = enabled
			engine.player.camera.fov = defaultFOV
			engine.player.camera.updateProjectionMatrix()
			// engine.player.physics.speed = defaultSpeed
		},
		onUpdate: (dt, i) => {
			ppManager.flowPass.uniforms.time.value = performance.now() / 100
			ppManager.flowPass.uniforms.intensity.value = i
			ppManager.bloomPass.strength = i * 0.6
			engine.player.camera.fov = defaultFOV + i * 30
			engine.player.camera.updateProjectionMatrix()
			// engine.player.physics.speed = defaultSpeed + i * 0.7
		},
	}])
	const effect2 = new Effect([{
		onEnableChanged: (enabled) => {
			ppManager.bloomPass.enabled = enabled
			ppManager.effectBleach.enabled = enabled
			ppManager.afterImagePass.enabled = enabled
			// engine.player.physics.speed = defaultSpeed
		},
		onUpdate: (dt, i) => {
			// @ts-ignore
			ppManager.afterImagePass.uniforms.damp.value = i * 0.9
			ppManager.bloomPass.strength = i * 0.6
			ppManager.effectBleach.uniforms.opacity.value = i
			// engine.player.physics.speed = defaultSpeed - i * 0.3
			engine.loop.deltaTimeMultiplier = 1 - i * 0.3
		},
	}])

	effectManager.addEffect("mate", effect1)
	effectManager.addEffect("beer", effect2)
	effectManager.addEffect("pizza", effect2)




	// bar1 stadium
	const avatar = createNPC({
		name: "npc_bar",
		model: "/RaveEngine/avatar/female_model.glb",
		gender: "feminine",
		position: new THREE.Vector3(-25.2, 0.26, 45.3),
		rotation: 1.84,
		animation: "idle_dancing",
		scale: 1,
	}, true)
	engine.debuggui.biggui.addToSceneFolder(avatar)
	engine.loop.register(() => { avatar.lookAt(engine.player.getPlayer().position) })

	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.scale.set(0.5, 0.3, 0.5)
	hc.position.set(-23.4, -0.3, 44.7)
	engine.debuggui.biggui.addToSceneFolder(hc)

	const c1 = new BlockingHintCollider(new THREE.Vector3(-23.4, -0.3, 44.7), 1, { title: "Bar" }, {
		onEnter: () => {
			c1.hint.text = spaceStore.drink.drinkId ? "Sorry, one drink is enough. Come back later." : ""
			avatar.getLoadedAvatar()?.triggerAction("wave", 0.5, false)
		},
		onInteract: () => {
			if (spaceStore.drink.drinkId) return false;
			spaceStore.showBarModal = true
		},
		onClose: () => {
			c1.hint.text = spaceStore.drink.drinkId ? "Sorry, one drink is enough. Come back later." : ""
			spaceStore.showBarModal = false
		},
	})
	space.pciSystem.add(c1)


	// bar2 skybar
	const avatar2 = createNPC({
		name: "npc_bar2",
		model: "/RaveEngine/avatar/female_model.glb",
		gender: "feminine",
		position: new THREE.Vector3(-53.2, 19.59, -44.3),
		rotation: 0.7,
		animation: "idle_dancing",
		scale: 1,
	}, true)
	engine.debuggui.biggui.addToSceneFolder(avatar2)
	engine.loop.register(() => { avatar2.lookAt(engine.player.getPlayer().position) })

	const hc2 = new HighlightCylinder()
	engine.scene.add(hc2)
	hc2.scale.set(0.5, 0.3, 0.5)
	hc2.position.set(-53.2, 19.59, -42.3)
	engine.debuggui.biggui.addToSceneFolder(hc2)

	const c2 = new BlockingHintCollider(new THREE.Vector3(-53.2, 19.59, -42.3), 1, { title: "Bar" }, {
		onEnter: () => {
			c2.hint.text = spaceStore.drink.drinkId ? "Sorry, one drink is enough. Come back later." : ""
			avatar.getLoadedAvatar()?.triggerAction("wave", 0.5, false)
		},
		onInteract: () => {
			if (spaceStore.drink.drinkId) return false;
			spaceStore.showBarModal = true
		},
		onClose: () => {
			c2.hint.text = spaceStore.drink.drinkId ? "Sorry, one drink is enough. Come back later." : ""
			spaceStore.showBarModal = false
		},
	})
	space.pciSystem.add(c2)




	// action
	watch(() => spaceStore.drink, (state) => {
		if (!state.drinkId) return;
		if (state.drinkId === "pizza") {
			changeAvatarInterim(space.project.publicPath + "/avatars/capi.glb", state.duration)
		} else {
			const ef = effectManager.getEffect(state.drinkId)
			if (!ef) console.error("Cannot find drink with drinkId " + state.drinkId)
			ef.duration = spaceStore.drink.duration
			effectManager.activateEffect(state.drinkId)
		}
	}, { deep: true })
}
