import { defineStore } from "pinia";



export const useScreenStore = defineStore({
	id: 'screenStore',
	state: () => ({
		// own user states
		isScreenShareActive: false,
		screenIds: [] as number[],
		ownedScreens: [] as number[],
		occupiedScreens: [] as number[],
	}),
	actions: {
		castScreen_To_Screen(screenId: number) {
			if (this.occupiedScreens.includes(screenId)) {
				console.log("screen is occupied");
				return;
			}
			if (this.ownedScreens.includes(screenId)) {
				this.uncastScreen_To_Video(screenId)
				return;
			}
			this.ownedScreens = [...this.ownedScreens, screenId];
		},
		uncastScreen_To_Video(screenId: number) {
			this.ownedScreens = this.ownedScreens.filter((id) => id !== screenId);
		}
	},
})
