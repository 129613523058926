// eslint-disable-next-line import/default
import PlayerEntityI from "@ravespaceio/rave-engine/build/engine/src/engine/player/Entities/PlayerEntityI";
import BoatObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/BoatObject";
import { Vector3 } from "three";
const tempVec = new Vector3();


export default class BoatEmpty implements PlayerEntityI<BoatObject> {
	public name: string;
	protected _desiredVelocity: Vector3 = new Vector3();
	protected _acceleration: Vector3 = new Vector3();

	protected _desiredRotation: number = 0;
	protected _desiredDirection: Vector3 = new Vector3();
	protected _currentRotation: Vector3 = new Vector3();
	public object: BoatObject;

	constructor(object: BoatObject) {
		this.name = 'BoatEmpty'
		this.object = object;
	}
	update(dt) {

	}

	onEntityEnter(): void { }

	onEntityExit(): void { }

	fixUpdate(dt: number): void {
		// this.move();
		// cheap effect to stop the collider
		this.object.stats.velocity.lerp(tempVec, 0.05);

	}

}

