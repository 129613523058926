<template>
	<div v-show="active" id="voicechat-wrap" class="voicechat-wrap show">
		<div class="tag ui-tag">Voicechat ({{ membersIds.length }})</div>
		<div id="voicechat" class="inner">
			<div v-show="ownData" style="display: flex; flex-direction: row;">
				<p>You</p>
				<!-- <p>{{ ownData.volumenLevel }}</p>
				<p>{{ ownData.muted }}</p> -->
			</div>
			<div v-for="(uid, i) of membersIds" :key="uid" style="display: flex; flex-direction: row;">
				<p>{{ membersNames[i] }}</p>
				<!-- <p>{{ membersVolumenIndicator[i] }}</p> -->
				<!-- <p v-if="membersMutedState[i]" class="k" @click="mute(uid)">Mute</p>
				<p v-else class="k" @click="unmute(uid)">Unmute</p> -->

			</div>
		</div>
		<div class="key" @click="leave()"><span>L</span>Leave</div>
	</div>
</template>


<script setup lang="ts">
import { getEngine } from "~/space/engine";
import { useSpaceStore } from "~/store/space";
import { useVoiceGroupStore } from "~/store/agora/voicegroups.js";
import { canAnswerVoicehat } from "~/space/lib/manager/PCISystem";
import { getSpace } from "~/space/space";

const engine = getEngine();
const space = getSpace()
const vgStore = useVoiceGroupStore()
const spaceStore = useSpaceStore()

const active = ref(false);
const membersNames = ref([] as string[]);
const membersIds = ref([] as string[]);
const membersVolumenIndicator = ref([] as (number | undefined)[]);
const membersMutedState = ref([] as (boolean | undefined)[]);
const ownData = ref({} as { volumenLevel: number, muted?: boolean });



watch(() => vgStore.voiceGroupActive, (value) => {
	active.value = value
});
watch(() => vgStore.listOfNames, (listOfNames) => {
	membersNames.value = listOfNames
});
watch(() => vgStore.listOfUid, (listOfUid) => {
	membersIds.value = listOfUid
});
watch(() => vgStore.listOfVolumenLevel, (levels) => {
	membersVolumenIndicator.value = levels
});
watch(() => vgStore.listOfMutedIndicator, (value) => {
	membersMutedState.value = value
});
watch(() => vgStore.ownData, (value) => {
	ownData.value = value
});

space.eventbinder.bindDocument("keydown", (e) => {
	if (canAnswerVoicehat() && (e.key == "l" || e.key == "L")) {
		leave()
	}
});

function leave() {
	const voicehandle = engine.multiplayer!.voiceChatVgHandler;
	const channel = voicehandle.getAgoraOptions().channel
	if (channel) voicehandle.lu_Leave(channel);
}

function mute(uid: number) {
	vgStore.muteMember(uid)
}

function unmute(uid: number) {
	vgStore.unMutedMember(uid)
}
</script>


<style scoped lang="scss">
@import "assets/css/variables";

.voicechat-wrap {
	height: auto;
	width: 150px;
	@extend .box;
	margin-left: 6px;
	padding: 14px;
	padding-bottom: 48px;
	padding-top: 32px;
	position: relative;
	z-index: 4;
	transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
	background: rgba(0, 0, 0, 0.35);
	transform: translateY(300px);

	&.show {
		transform: translateY(0);
	}
}

.inner {
	height: calc(100% - 23px);

	p {
		font-size: 14px;
		margin-bottom: 2px;
		font-weight: 700;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $main;
	}
}

.key {
	position: absolute;
	bottom: 10px;
	color: $main;
	font-size: 14px;
	font-weight: 700;
	width: 100%;
	display: flex !important;
	justify-content: left !important;
	flex-direction: row !important;
	align-items: center !important;

	span {
		width: 20px;
		margin-bottom: 4px;
		color: $dark;
		height: 20px;
		background: $main;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 700;
		border-radius: 5px;
		margin-right: 12px;
	}
}
</style>
