import { defineStore } from 'pinia'
import { webSocket as ws } from 'rxjs/webSocket';
import { tap } from 'rxjs';
import { getSpace } from '~/space/space';



const WS_HOST = <string>useRuntimeConfig().public.STORE_WSS_URI || "wss://3a0osx7xi2.execute-api.eu-central-1.amazonaws.com/dev";
const HEART_BEAT_INTERVAL = 1000 * 60 // every minute

const defaultRoom = 'darkRoom';

let subject: any;
let heartbeat;

interface IObjectKeys {
	[key: string]: any
}

export const usePanelStore = defineStore({
	id: "panelStore",
	state: (): IObjectKeys => {
		return {
		};
	},
	actions: {
		get(key: string) {
			return this.$state[key];
		},
		async set(key: string, value: any) {
			await this.setLocal(key, value);
			await this.setRemote(key, value);
		},
		setLocal(key: string, value: any) {
			try {
				this.$state[key] = value;
			} catch (error) {
				return Promise.reject(error)
			}
			return Promise.resolve(true)
		},
		async setRemote(key: string, value: any) {
			try {
				subject.next({
					action: 'updateSettings',
					payload: [
						{
							room: defaultRoom,
							key,
							value,
						},
					],
				});
			} catch (error) {
				return Promise.reject(error)
			}
			return Promise.resolve(true)
		},
		ping(subject: any) {

		},
		init(engine: any) {
			const space = getSpace()
			const subject = ws({
				url: WS_HOST,
			});
			subject
				.asObservable()
				.pipe(
					tap((data: any) => {
						const { action } = data;
						if (!action) {
							return;
						}
						if (!data.payload || !data.payload.length) {
							return;
						}
						switch (action) {
							case 'INITIALISED':
								data.payload.map(async (p: any) => {
									const { key, value } = p;
									// console.warn("INITIALISED", key, value)
									await this.setLocal(key, value);
									space.eventbus.emit(key, value)
								});
								break;
							case 'UPDATED':
								data.payload.map(async (p: any) => {
									var { key, value } = p;
									console.warn("UPDATE", key, value)
									await this.setLocal(key, value);
									space.eventbus.emit(key, value)
								});
								break;
							case 'PONG':
								data.payload.map(async (p: any) => {
									var { date } = p;
									console.warn("PONG", date, p['date'])
								});
								break;
						}
					})
				)
				.subscribe();
			subject.next({ action: 'assignUser', roomName: defaultRoom });
			heartbeat = setInterval(() => subject.next({ action: 'ping' }), HEART_BEAT_INTERVAL)
		},
	},
})
