import * as THREE from "three"
import Engine, { Loader, Logging, LoggingArea, LogLevel, MeshPicker, RPMAvatar } from "@ravespaceio/rave-engine"
import { dependencies } from "../../package.json"
import LowFpsDetecter from "./lib/utils/LowFpsDetecter"
import { useToast } from "vue-toastification"
import { addAxis } from "@ravespaceio/rave-engine/build/engine/src/utils/three"
import { GuiEngineHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiEngineHelper"
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper"
import { getSpace } from "./space"
import { preventMediaControlls, preventBrowserGestures } from "@ravespaceio/rave-engine/build/engine/src/utils/browser"
import { useSpaceStore } from "~/store/space"
import { setupRsp } from "./Rsp/main"
import { usePanelStore } from "~/store/panel"



var engine: Engine
export function getEngine(): Engine {
	if (!engine) { console.error("Engine not setup"); }
	return engine
}



export function setupEngine(canvas: HTMLCanvasElement): Engine {
	Logging.info(`setupEngine v${dependencies['@ravespaceio/rave-engine']}`, LoggingArea.Space)

	const SPACE = getSpace()
	const ENGINE = engine = new Engine({
		canvas,
		gameserverSocketURL: SPACE.ENV.GAMESERVER_URI,
		reconnectMp: SPACE.ENV.IS_PROD,
		voicechat: true,
	})

	preventMediaControlls()
	preventBrowserGestures()

	SPACE.pciSystem.setup()

	// audio
	const c = document.getElementById("continue")
	if (c) ENGINE.gui.registerFirstInteractElements([c])
	ENGINE.audioManager.setup()

	const panelStore = usePanelStore()
	panelStore.init(engine);

	// start loading after everything is initialized/setup
	const spaceStore = useSpaceStore()
	ENGINE.renderer.paused = true
	watch(() => spaceStore.onboardingDone, (state) => {
		if (!state) return;
		window.dispatchEvent(new Event('resize'));
		window.scrollTo(0, 0);
		ENGINE.renderer.paused = false
		ENGINE.renderer.resize()

		const fpsDetect = new LowFpsDetecter(ENGINE)
		fpsDetect.lowFpsEvent.on(() => { useToast().error("Low framerate detected. Change the quality in the Menu (M)."); })
	}, { immediate: true })

	// debug
	if (SPACE.ENV.STAGE !== "dev") {
		ENGINE.debuggui.destroy()
	} else {
		// @ts-ignore
		window["space"] = SPACE
		// @ts-ignore
		window["engine"] = ENGINE
		window["THREE"] = THREE
		ENGINE.debuggui.show()
		addAxis(ENGINE.scene)
	}

	// exit
	if (SPACE.ENV.STAGE !== "dev") {
		window.onbeforeunload = (e) => {
			return "Do you want to close the page?";
		};
	}

	engine.onDestroy.on(() => {
		SPACE.eventbinder.clearAllEvents()
	})

	// render quality
	SPACE.eventbus.onAlready("qualitysetting", (q) => {
		const s = q == "high" ? 1 : q == "mid" ? 0.8 : 0.5
		engine.renderer.canvasScale = s
		engine.renderer.resize()
	})

	// RPMAvatar.defaultFemaleConfig.modelPath = "/SpaceShared/models/avatar/onart_girl.glb"
	// RPMAvatar.defaultMaleConfig.modelPath = "/SpaceShared/models/avatar/onart_boy.glb"

	// run space
	setupRsp()

	// loading
	SPACE.loader.addFiles([
		RPMAvatar.defaultMaleConfig.modelPath,
		RPMAvatar.defaultMaleConfig.animationsPath!,
		RPMAvatar.defaultFemaleConfig.modelPath,
		RPMAvatar.defaultFemaleConfig.animationsPath!,
	])
	SPACE.loader.startLoading()

	GuiEngineHelper.addEngine(engine.debuggui.biggui, engine)
	engine.debuggui.biggui.addToSceneFolder(engine.scene)

	return engine
}


