import Goal from "./goal";
import { Material, ContactMaterial, Quaternion } from 'cannon-es';
import { completeGoal, setupGround, setUpTrigger, setupWalls } from "./setupBoundaries";
import { setupBalls } from "./balls";
import { Vector3, Euler, Mesh, SphereGeometry, MeshBasicMaterial, Group } from 'three';
import PlayerObject, { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import FootballPlayer from "@ravespaceio/rave-engine/build/engine/src/engine/player/Entities/FootballPlayer"
import Engine from "@ravespaceio/rave-engine";


const xOffset = 11.95
const zOffset = 16.5

export function setupPhysicObjects() {
	const engine = getEngine()

	engine.physics.world.defaultContactMaterial.friction = 0;
	// setup goals
	const goalMaterial = new Material({ friction: 0.2, restitution: 0.5 })
	const groundMaterial = new Material('ground');
	const ballMaterial = new Material('ballMat');
	completeGoal(engine, { x: xOffset, y: 0, z: zOffset }, goalMaterial);

	// setup ground
	setupGround(engine, groundMaterial);
	const goal1 = new Goal(engine, goalMaterial, 3.4, 2.3);
	goal1.rotateOnAxis(new Vector3(0, 1, 0), Math.PI / 2)
	goal1.position.set(-21.9 - xOffset, 2.95, -35.8 - zOffset);
	goal1.updateMatrixWorld();

	// setup walls
	setupWalls(engine, { x: xOffset, y: 0, z: zOffset }, groundMaterial);


	// setupballs
	setupBalls(engine, { x: xOffset, y: 0, z: zOffset }, ballMaterial)
	const contactMaterial = new ContactMaterial(groundMaterial, ballMaterial, { friction: 0.9, restitution: 0.5 })
	const contactBallGoal = new ContactMaterial(goalMaterial, ballMaterial, { friction: 0.3, restitution: 0.6 })
	engine.physics.world.addContactMaterial(contactMaterial);
	engine.physics.world.addContactMaterial(contactBallGoal);

	let enterFootballArea = false;
	// setup entity player
	const footballEntity = engine.player.getPlayer<PlayerObject>().getEntity(PlayerEntities.football) as FootballPlayer;
	footballEntity.shootForce = 15

	let handleShoot = (e: any) => {
		footballEntity.kickBallDelay(150)
	};
	footballEntity.addInputEventListener.on((e) => {
		document.addEventListener('mousedown', handleShoot)
		document.addEventListener('touchstart', handleShoot)
	})

	footballEntity.removeInputEventListener.on(() => {
		document.removeEventListener('mousedown', handleShoot)
		document.removeEventListener('touchstart', handleShoot)
	})




	const footballAreaPos = new Vector3(-21.9 - xOffset, 2.95, -26.2 - zOffset);
	const helper = new Mesh(new SphereGeometry(0.2));
	helper.position.copy(footballAreaPos)
	engine.scene.add(helper)
	helper.visible = false
	const footballAreaPos2 = new Vector3(-21.9 - xOffset, 2.95, -26.6 - zOffset);
	function triggerEntityChange(centers: Vector3[], radius: number[]) {
		const playerObj = engine.player.getPlayer<PlayerObject>();

		for (let i = 0; i < centers.length - 1; i++) {
			if ((Math.abs(centers[i].distanceTo(playerObj.position)) < radius[i] || Math.abs(centers[i + 1].distanceTo(playerObj.position)) < radius[i + 1]) && !enterFootballArea) {
				engine.physics.isEnable = true;
				// @ts-ignore
				playerObj.changeEntity(PlayerEntities.football, { prevDirection: playerObj.getCurrentEntity().direction, y: playerObj.rotation.y });
				engine.navigation.navigationComponentM.getNavigationComponent('playerNav').setActive(false);
				resetBall(engine);
				enterFootballArea = true;

			} else if ((Math.abs(centers[i].distanceTo(playerObj.position)) > radius[i] && Math.abs(centers[i + 1].distanceTo(playerObj.position)) > radius[i + 1]) && enterFootballArea) {
				engine.physics.isEnable = false;
				engine.navigation.navigationComponentM.getNavigationComponent('playerNav').setActive(true);

				resetBall(engine);
				// @ts-ignore
				playerObj.changeEntity(PlayerEntities.playerPhysics, { prevDirection: playerObj.getCurrentEntity().direction, y: playerObj.rotation.y });
				enterFootballArea = false;

			}
		}
	}

	engine.loop.register((dt) => {
		triggerEntityChange([footballAreaPos, footballAreaPos2], [1.35, 0.4])
	})
}


export function resetBall(engine: Engine) {
	const ball = engine.scene.getObjectByName('ball')
	if (ball) {
		// @ts-ignore
		const collider = ball.collider._collider
		collider.position.set(-21.68 - xOffset, 3.12, -28 - zOffset);
		collider.quaternion.copy(new Quaternion());
		engine.physics.world.clearForces();
		collider.velocity.set(0, 0, 0);
		collider.updateInertiaWorld(true);

	}
}
