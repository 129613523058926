<template>
	<div>
		<SpaceInterfaceBoatMount />
		<div v-if="!$isMobile" id="keyboard--controls">
			<div id="clickToEnter" class="btn--transparent">Click to Enter</div>

			<div id="unhide" class="key">
				<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M0.59375 0.578125C0.226562 0.939453 0 1.44336 0 2V15C0 16.1055 0.894531 17 2 17H22C23.1055 17 24 16.1055 24 15V2C24 0.894531 23.1055 0 22 0H2C1.45117 0 0.955078 0.220703 0.59375 0.578125ZM8.69531 4.5V12.5H10.3867V9.19531H13.8203V12.5H15.5078V4.5H13.8203V7.80078H10.3867V4.5H8.69531Z" fill="black" />
				</svg>
				<p>Unhide</p>
			</div>

			<div v-if="multiplayerEnabled" id="interface-left">
				<SpaceInterfaceChat />
				<SpaceInterfaceVoiceInfo />
			</div>

			<div id="interface-right">
				<div class="key key--simple">
					<div>1-9</div>
					<p>Dance</p>
				</div>
				<div class="key key--simple">
					<div>T</div>
					<p>Teleport</p>
				</div>
				<div class="key key--simple">
					<div>V</div>
					<p>Speak</p>
				</div>
				<div class="key key--simple">
					<div>M</div>
					<p>Menu</p>
				</div>
				<div class="key key--simple" v-if="pl">
					<div>ESC</div>
					<p>Unlock</p>
				</div>
			</div>
		</div>

		<template v-else>

			<SpaceInterfaceChat v-if="multiplayerEnabled" />

			<div id="mobile--buttons">
				<div class="btn--row" @click="spaceStore.showTeleportModal = true">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M13 11.9V19H11V11.9C8.71776 11.4367 7 9.41896 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.41896 15.2822 11.4367 13 11.9ZM9 14.1573V16.1844C6.06718 16.5505 4 17.3867 4 18C4 18.807 7.57914 20 12 20C16.4209 20 20 18.807 20 18C20 17.3867 17.9328 16.5505 15 16.1844V14.1573C19.0559 14.6017 22 15.9678 22 18C22 20.5068 17.5203 22 12 22C6.47973 22 2 20.5068 2 18C2 15.9678 4.94412 14.6017 9 14.1573ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z" fill="black" />
						</g>
					</svg>
					<p>Teleport</p>
				</div>

				<div class="btn--row" @click="dance('wink')">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12.0321 19C8.67459 19 6.80643 17.2316 6.80643 14V13H17.1158L17.1434 13.9715C17.2358 17.2145 15.4003 19 12.0321 19ZM15.0875 15C14.8526 16.3955 13.9089 17 12.0321 17C10.1563 17 9.18179 16.3902 8.89677 15H15.0875ZM14 8H17V10H14V8ZM10 8H7V10H10V8Z" fill="black" />
						</g>
					</svg>
					<p>Emote</p>
				</div>

				<div class="btn--row" @click="spaceStore.showChat = true">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M6 21.8042L12.0868 18H20C21.1046 18 22 17.1046 22 16V4C22 2.89543 21.1046 2 20 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H6V21.8042ZM11.5132 16L8 18.1958V16H4V4H20V16H11.5132Z" fill="white" />
						</g>
					</svg>
					<p>Chat</p>
				</div>
				<div class="btn--row">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M12 1C14.2091 1 16 2.79086 16 5V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V5C8 2.79086 9.79086 1 12 1ZM13 19.9381V21H16V23H8V21H11V19.9381C7.05369 19.446 4 16.0796 4 12V10H6V12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12V10H20V12C20 16.0796 16.9463 19.446 13 19.9381ZM10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5V12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12V5Z" fill="black" />
						</g>
					</svg>
					<p>Talk</p>
				</div>
				<div class="btn--row" @click="spaceStore.showMenu = true">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M18.6562 20.897L20.8733 18.6798L20.0925 15.843L20.4327 15.0305L23 13.5818V10.4464L20.44 8.99173L20.1055 8.18067L20.8961 5.34235L18.6774 3.12683L15.8403 3.90748L15.0296 3.56758L13.5808 1H10.4454L8.99072 3.56004L8.17985 3.89446L5.34198 3.10281L3.1267 5.31809L3.90748 8.15567L3.56758 8.96634L1 10.4151V13.5496L3.55774 15.0076L3.89252 15.8193L3.10197 18.6572L5.31809 20.8733L8.15567 20.0925L8.96644 20.4325L10.4153 22.999H13.5498L15.0067 20.4412L15.8183 20.1065L18.6562 20.897ZM18.8527 13.6256L17.9809 15.7078L18.6362 18.0886L18.0678 18.657L15.692 17.9951L13.609 18.8542L12.3873 20.999H11.5829L10.3714 18.8529L8.29155 17.9808L5.90947 18.6362L5.34203 18.0688L6.00385 15.693L5.14482 13.6101L3 12.3876V11.583L5.1471 10.3715L6.0192 8.29155L5.36375 5.90947L5.93001 5.34321L8.30576 6.00595L10.3895 5.14655L11.6093 3H12.4129L13.6245 5.1471L15.7044 6.0192L18.087 5.36362L18.6558 5.93166L17.9941 8.30696L18.8534 10.3906L21 11.6103V12.4139L18.8527 13.6256ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="black" />
						</g>
					</svg>
					<p>Menu</p>
				</div>
			</div>

			<div id="voicechat-interface--mobile">
				<SpaceInterfaceVoiceInfo v-if="multiplayerEnabled" />
			</div>
		</template>

		<SpaceInterfaceVoiceRequest v-if="multiplayerEnabled" />
		<SpaceInterfaceWorldPointer />
		<SpaceInterfaceVoiceRoleHint />
		<SpaceModalTeleport />
	</div>
</template>

<script setup lang="ts">
import Vue from "vue";
import { TimelineLite, Power3, } from "gsap";
import { useToast } from "vue-toastification";
import { getSpace } from "~~/src/space/space";
import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getEngine } from "~/space/engine";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { useSpaceStore } from "~/store/space";

const space = getSpace();
const engine = getEngine()
const spaceStore = useSpaceStore()
const multiplayerEnabled = !!engine.config.gameserverSocketURL;

const pl = ref(false)



onMounted(() => {
	var canvasclicked = false;
	engine.gui.once("canvasclick", () => {
		canvasclicked = true;
	});

	watch(() => spaceStore.tutorialDone, () => {
		if (space.browser.isMobile) {
			const tl = new TimelineLite({});
			tl.to(".joystickOuter", 1, { y: 0, opacity: 1 });
			tl.to("#mobile--buttons", 0.7, { y: 0, opacity: 1, });
		} else if (canvasclicked) {
			showInterface();
		} else {
			showClickToEnter();
			engine.gui.once("canvasclick", () => {
				showInterface();
			});
		}
	})

	// Keys
	space.eventbinder.bindDocument("keyup", (e) => {
		if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
		else if (e.key == "h" || e.key == "H") {
			document.getElementById("keyboard--controls")?.classList.toggle("hide");
		}
	});

	engine.loop.register(() => {
		pl.value = engine.gui.isPointerLocked()
	})
})


function showClickToEnter() {
	const tl = new TimelineLite({});
	tl.to("#clickToEnter", 0.3, { y: 0 });
}

function showInterface() {
	const tl = new TimelineLite({});
	tl.to("#clickToEnter", 0.3, { y: 100 });
	tl.to("#interface-right, #interface-left", 0.4, {
		y: 0,
		opacity: 1,
		ease: Power3.easeOut,
	});
}

function dance(id: string) {
	const engine = getEngine();
	const player = engine.player.getPlayer<PlayerObject>()
	if (id == "wink") {
		player?.avatar?.triggerAction("wave", 1, false)
	} else if (id == "dance") {
		const randomDance = Math.floor(Math.random() * 4) + 1
		if (randomDance == 1) { player?.avatar?.triggerAction("rave_dance_1", 1, true) }
		if (randomDance == 2) { player?.avatar.triggerAction("dance_hiphop_1", 1, true) }
		if (randomDance == 3) { player?.avatar?.triggerAction("rave_dance_5", 1, true) }
		if (randomDance == 4) { player?.avatar?.triggerAction("dance_hiphop_1", 1, true) }
	}
}
</script>



<style scoped lang="scss">
@import "assets/css/variables";



#interface-left {
	position: absolute;
	z-index: 9999999;
	left: 20px;
	display: flex;
	z-index: 4;
	bottom: 20px;
}


.voicechat-wrap {
	height: auto;
	width: 150px;
	@extend .box;
	margin-left: 6px;
	padding: 14px;
	padding-top: 12;
	position: relative;
	z-index: 4;
	transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
	background: rgba(0, 0, 0, 0.35);
	transform: translateY(300px);

	#voicechat {
		height: calc(100% - 23px);

		p {
			font-size: 14px;
			margin-bottom: 2px;
			font-weight: 700;
			color: $main;
		}
	}

	.key {
		position: absolute;
		bottom: 10px;
		color: $main;
		font-size: 14px;
		font-weight: 700;
		align-items: flex-start;

		span {
			width: 20px;
			margin-bottom: 4px;
			color: $dark;
			height: 20px;
			background: $main;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			font-weight: 700;
			border-radius: 5px;
		}
	}

	&.show {
		transform: translateY(0);
	}
}

#unhide {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 3;
	transform: translateX(150px);
	width: 70px;
	transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
	background: $darkTransparent;
	border: $stroke solid $main;
	border-radius: $radius;
	padding: 9px 10px 8px 10px;
	color: $main;

	p {
		color: $main !important;
	}

	svg {
		width: 25px;
		height: auto;
		margin-bottom: 6px;

		* {
			fill: $main;
		}
	}
}

.key {
	@extend .flex-center;
	flex-direction: column;
	border-right: 1px solid rgba(255, 255, 255, 0.2);

	svg {
		margin-bottom: 2px;
		width: 44px;
		height: 44px;
	}

	p {
		font-size: 11px;
		font-weight: 700;
		line-height: 100%;
		text-align: center;
		color: $dark;
	}

	&:last-child {
		border-right: none;
	}
}

.key--simple {
	background: transparent;

	div {
		height: 23px !important;
		background: $main;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		width: 28px;
		padding: 0;
		font-size: 12px;
		color: black;
		font-weight: 700;
	}

	p {
		margin-top: 6px;
	}
}

#keyboard--controls {
	left: 0;
	width: 100%;
	height: 110px;
	bottom: 0;
	@extend .flex-center;
	position: absolute;
	pointer-events: none;
	z-index: 3;

	#clickToEnter {
		pointer-events: none;
		transform: translateY(100px);
	}

	&.hide {

		#interface-left,
		#interface-right {
			transform: translateY(300px) !important;
			opacity: 0 !important;
		}

		#unhide {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

#interface-right {
	background: $darkTransparent;
	border: $stroke solid $main;
	border-radius: $radius;
	@extend .flex-center;
	position: absolute;
	right: 20px;
	bottom: 20px;
	height: 60px;
	border: 1px solid #ffffff26;

	.key {
		padding: 6px 12px 8px 12px;
		height: 100%;
		color: $main;

		p {
			color: $main !important;
		}

		svg {
			* {
				fill: $main;
			}
		}
	}
}

#interface-left,
#interface-right {
	transform: translateY(100px);
	opacity: 0;
	transition: ease 0.5s;
}



/* MEDIA QUERIES */

@media (max-width: 1200px) {
	#interface-left {
		.chat-wrap {
			width: 220px;

			.input-wrap {
				p {
					font-size: 14px;
				}
			}
		}

		.voicechat-wrap {
			width: 130px;
		}
	}
}

#mobile--buttons {
	position: fixed;
	bottom: 30px;
	left: 30px;
	transform: translateY(200px);
	z-index: 3;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	@extend .noselect;
	width: 90px;
	@extend .box;

	.btn--row {
		opacity: 1;
		width: 100%;
		height: 30px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 1px solid $lineColor;
		padding: 0 6px;

		p {
			color: white;
			font-size: 12px;
			font-weight: 600;
			margin: 0
		}

		svg {
			margin-right: 6px;
			width: 16px;
			height: auto;

			* {
				fill: white;
			}
		}

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}
	}
}


#voicechat-interface--mobile {
	#voicechat-wrap {
		position: absolute;
		bottom: 80px;
		width: 100px;
		right: 21px;
		z-index: 10;
		height: 150px;
		margin: 0;

		#voicechat {
			overflow-y: auto;
			height: 60px;
		}

		.key {
			width: 100%;
			left: 14px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			span {
				margin-left: 6px;
				transform: translateX(-6px);
			}
		}
	}
}
</style>
