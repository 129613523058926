import Engine, { EngineUtils, NavPlayeableObjComponent } from "@ravespaceio/rave-engine"
import SphereColliderSystem from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SphereColliderSystem"
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import * as THREE from "three"
import { Group, Object3D, Vector3 } from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { NavigationControllerTypes } from "../manager/SpawnManager"
import PlayerObject, { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"
import { isMobile, isMovingKeyPressed } from "@ravespaceio/rave-engine/build/engine/src/utils/browser"
import { HintCollider, PCICollider, SICollider } from "../manager/PCISystem"
import { useSpaceStore } from "~/store/space"




export default class InteractionExtension {

	constructor(scene: Object3D, offset?: THREE.Vector3) {
		const space = getSpace()
		const spots = findObjects(scene, testUserData("SocketID"))
		spots.forEach((spot) => {
			space.pciSystem.add(new SICollider(spot.position, spot))
		})
	}
}



export function sitOutsideOfNavmesh(rate: number, playerheight: number, spot: Object3D) {
	const space = getSpace()
	const engine = getEngine()
	const spaceStore = useSpaceStore()

	const player = engine.player.getPlayer<PlayerObject>()
	engine.navigation.enabled = false
	player.changeEntity(PlayerEntities.autoPlayer);

	// workaround to enable navigation again after sitting
	document.addEventListener("keydown", standUpKey)
	engine.inputManager.joystick.inner.addEventListener("touchstart", standUpMobile)
	function standUpKey(e) { if (isMovingKeyPressed(e.key)) { standUp() } }
	function standUpMobile() { standUp() }

	function standUp() {
		engine.navigation.enabled = true;
		player.changeEntity(PlayerEntities.playerPhysics);
		document.removeEventListener("keydown", standUpKey)
		engine.inputManager.joystick.inner.removeEventListener("touchstart", standUpMobile);
	}

	// smoothing
	let couter = 0
	while (couter <= 1) {
		couter += rate
		player.position.lerp(new Vector3(spot.position.x, spot.position.y - playerheight, spot.position.z), couter);
		player.quaternion.slerp(spot.quaternion, couter);
	}
	engine.player.camControllerM.goBehindPlayer(true);
	spaceStore.hintTop.title = undefined

}
