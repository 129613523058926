import Engine, { RPMAvatar } from "@ravespaceio/rave-engine";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import FirstPersonCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/FirstPersonCameraController";
import FreeCamInputController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/FreeCamInputController";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import { FOVCamEffect } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamManagerAddOns";
import { getEngine } from "~/space/engine";
import PlayerObject, { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { GuiHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiHelper";
import { getSpace } from "~/space/space";
import PlayerStatusBar from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerStatusBar";
import { canUseActionKey } from "~/space/lib/manager/PCISystem";
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper";


export function setupPlayer() {

	const engine = getEngine()

	// fov effect
	const fovAddOn = new FOVCamEffect(engine.player.camera);
	engine.player.camControllerM.add(fovAddOn);
	fovAddOn.fovRange = { minVal: 0.8, maxVal: 1.2 };

	// player object
	const playerObj = new PlayerObject(engine);
	engine.player.setPlayer(playerObj);
	engine.scene.add(playerObj);
	engine.loop.add(playerObj);
	engine.player.camControllerM.setTargetObject(playerObj);
	engine.player.setAvatarByConfig(RPMAvatar.defaultMaleConfig)
	playerObj.inputController.emulatedRunning = false
	playerObj.stats.speed = 2

	// controllers
	const first = engine.player.camControllerM.getController<FirstPersonCameraController>(CamControllersNames.FIRST_CAM)
	first.angularSpeed = 1.5
	first.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1.1
	})

	const third = engine.player.camControllerM.getController<ThirdCameraController>(CamControllersNames.ORBIT_CAMERA)
	third.minVerticalAngle = -1
	third.maxVerticalAngle = 0.5
	third.desiredManualDistance = 3
	third.maxZoomOut = 3.5
	third.maxZoomIn = 0.5
	third.angularSpeed = 1.5
	third.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1.3
	})

	const freeCam = engine.player.camControllerM.getController<FreeCamInputController>(CamControllersNames.FREE_CAM)
	freeCam.speed = 2
	freeCam.angularSpeed = 0.2
	freeCam.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1
		engine.player.getPlayer<PlayerObject>().changeEntity(PlayerEntities.autoPlayer)
	})

	const camera = engine.player.camera
	camera.fov = 68
	camera.far = 500
	camera.updateProjectionMatrix()

	setupGestures()
	setupDroneButton()
	addPlayerToGUI()
}


function setupGestures() {
	const engine = getEngine()
	const space = getSpace()
	space.eventbinder.bindDocument("keyup", (e) => {
		if (!canUseActionKey()) return;
		const avatar = engine.player.getPlayer<PlayerObject>().avatar
		if (!avatar) return

		if (e.key == "1") { avatar.triggerAction("wave", 0.2, false) }
		if (e.key == "2") { avatar.triggerAction("joy_jump", 1, false) }
		if (e.key == "3") { avatar.triggerAction("spin", 1, false) }
		if (e.key == "4") { avatar.triggerAction("dance1", 1, true) }
		if (e.key == "5") { avatar.triggerAction("dance2", 1, true) }
		if (e.key == "6") { avatar.triggerAction("dance3", 1, true) }
		if (e.key == "7") { avatar.triggerAction("surfing", 1, true) }
	})

	engine.inputManager.gamepad.on("buttondown", (btn) => {
		if (!canUseActionKey()) return;
		const avatar = engine.player.getPlayer<PlayerObject>().avatar
		if (!avatar) return
		if (btn == "RB") { avatar.triggerAction("dance1", 1, true) }
		else if (btn == "LB") { avatar.triggerAction("dance3", 1, true) }
	})
}

function setupDroneButton() {
	const engine = getEngine()
	const space = getSpace()
	GuiHelper.addButton(engine.debuggui.biggui.getFolderOrCreate("Space"), () => {
		engine.player.camControllerM.changeController(CamControllersNames.FREE_CAM)
		space.pciSystem.helper.visible = false
		document.body.classList.add("drone")
		PlayerStatusBar.initialVisibility = false
		engine.multiplayer.group.traverse((child) => {
			if (child instanceof PlayerStatusBar) child.visible = false
		})
	}).name("DroneMode")
}

export function addPlayerToGUI() {
	const engine = getEngine();
	const playerFolder = engine.debuggui.biggui.addFolder('Player');
	GuiThreeHelper.addVector(playerFolder, engine.player.getPlayer().position, { disable: true, listen: true }).title("world position")
	GuiThreeHelper.addVector(playerFolder, engine.player.getPlayer().rotation, { disable: true, listen: true }).title('world rotation')
	playerFolder.add(engine.player.getPlayer<PlayerObject>().stats, 'speed', 0, 5);
	playerFolder.add({ CurrentEntity: engine.player.getPlayer<PlayerObject>().getCurrentEntity().name }, 'CurrentEntity', [PlayerEntities.playerPhysics, PlayerEntities.orbit, PlayerEntities.football, PlayerEntities.autoPlayer]).onChange(e => {
		engine.player.getPlayer<PlayerObject>().changeEntity(e);
	});
}
