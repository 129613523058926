import Engine, { RPMAvatar, PlanarIntensityFade, MyLoaders, RPMAvatarHull, HighlightCylinder, EngineUtils, PostProcessingThree } from "@ravespaceio/rave-engine";
import * as THREE from "three"
import { useGameStore } from "~/store/game";
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils'
import { clamp } from "@ravespaceio/rave-engine/build/engine/src/utils/math";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { getEngine } from "../engine";
import { getSpace } from "../space";
import { BlockingHintCollider } from "../lib/manager/PCISystem";
import { replaceMaterial } from "~/space/lib/utils/material";
import { useSpaceStore } from "~/store/space";
import { EffectComposer as EffectComposerPMNDRS } from "postprocessing"
import { EffectComposer as EffectComposerTHREE } from 'three/examples/jsm/postprocessing/EffectComposer';


export function setupMinigame() {

	const space = getSpace()
	const engine = getEngine()
	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()

	initSounds()

	space.loader.on("loaded", async () => {

		const modelGltf = await MyLoaders.gltfLoader.loadAsync("/rspSpace/models/minigame.glb") as GLTF
		const model = modelGltf.scene
		const clip = THREE.AnimationClip.findByName(modelGltf.animations, 'trashBinAnim').optimize().trim()
		const collider = new THREE.Mesh(new THREE.SphereGeometry(0.2), new THREE.MeshBasicMaterial({ wireframe: true, visible: false }))

		const tonnen: THREE.Object3D[] = []
		const spots = findObjects(engine.scene, testUserData("CMSR", "trashGame"))
		for (let i = 0; i < spots.length; i++) {
			const spot = spots[i]
			const tonne = SkeletonUtils.clone(model)
			spot.add(tonne)
			const mixer = new THREE.AnimationMixer(tonne)
			const action = mixer.clipAction(clip)
			action.reset().play()
			tonnen.push(tonne)
			tonne.userData.mixer = mixer
			tonne.userData.action = action
			tonne.userData.t = 0
			tonne.userData.m = 1
			const c = collider.clone()
			c.position.y += 1.5
			c.userData.tonnenId = i
			tonne.userData.c = c
			tonne.add(c)
		}

		engine.loop.register((dt) => {
			for (const tonne of tonnen) {
				const mixer = tonne.userData.mixer as THREE.AnimationMixer
				let t = tonne.userData.t + dt * tonne.userData.m
				if (t > clip.duration) {
					t = t + clip.duration - t
					tonne.userData.m = -1
				}
				t = clamp(t, 0, clip.duration - 0.001)
				tonne.userData.t = t
				mixer.setTime(t)
			}
		})

		setInterval(() => {
			lastId = -1
			if (gameStore.gameStarted) {
				const i = Math.floor(Math.random() * tonnen.length)
				reset(i)
				activate(i)
			} else {
				for (let i = 0; i < tonnen.length; i++) { reset(i) }
			}
		}, 2000)

		function activate(i: number) {
			const tonne = tonnen[i]
			tonne.userData.m = 1
		}
		function reverse(i: number) {
			const tonne = tonnen[i]
			tonne.userData.m = -1
		}
		function reset(i: number) {
			const tonne = tonnen[i]
			tonne.userData.m = 0
			tonne.userData.t = 0
		}
		// @ts-ignore
		window["playgame"] = activate


		// collider
		const gamePosition = new THREE.Vector3(-3.19, 2.95, -51.62)
		const hc = new HighlightCylinder()
		engine.scene.add(hc)
		hc.scale.set(0.5, 0.3, 0.5)
		hc.position.set(-3.19, 2.95, -51.62)


		space.pciSystem.add(new BlockingHintCollider(gamePosition, 1.5, { title: "Start Minigame" }, {
			onInteract: () => {
				if (!gameStore.miniGamePopup) {
					gameStore.miniGamePopup = true
				}
			},
		}))

		watch(() => gameStore.miniGamePopup, (state) => {
			if (!state) { gameStore.miniGamePopup = false }
		});

		watch(() => gameStore.gameStarted, (state) => {
			state ? enterGameMode() : leaveGameMode()
			hc.visible = !state
		});

		const raycaster = new THREE.Raycaster()
		const pointer = new THREE.Vector2();
		const colliders = tonnen.map((tonne) => tonne.userData.c)

		let lastId: number = -1 // to prevent clicking the same collider multiple times
		window.addEventListener('click', (event) => {
			if (!gameStore.gameStarted) return;
			pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
			pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
			raycaster.setFromCamera(pointer, engine.renderer.renderCamera);
			const intersects = raycaster.intersectObjects(colliders);
			const hit = intersects[0]
			if (!hit) return;
			const tonnenId = hit.object.userData.tonnenId
			const tonne = tonnen[tonnenId]
			const action = tonne.userData.action as THREE.AnimationAction

			// collider active zwischen keyframe 6 und 12
			const active = action.time > clip.duration / 2
			console.log("hit tonne " + tonnenId + " active " + active);
			if (active) {
				if (tonnenId == lastId) {
					console.log("double click prevent")
				} else {
					lastId = tonnenId
					reverse(tonnenId)
					gameStore.hits += 1
				}
			}
		});
	})

	const gamePos = new THREE.Vector3(-3.19, 2.95, -51.62)
	const gameCam = new THREE.PerspectiveCamera()
	gameCam.name = "MinigameCamera"
	gameCam.lookAt(gamePos)
	gameCam.position.set(-4.9, 4.2, -49.1)
	gameCam.rotation.set(0, -6.8, 0)

	if (window.innerWidth < 1200 && window.innerWidth > 900) {
		gameCam.fov = 65
	} else if (window.innerWidth < 900) {
		gameCam.fov = 100
	}

	engine.debuggui.biggui.addToSceneFolder(gameCam)


	function enterGameMode() {
		engine.inputManager.unLockCursor()
		switchCamera(engine, gameCam)
		engine.player.getPlayer<PlayerObject>().visible = false
		engine.renderer.resize()
	}

	function leaveGameMode() {
		engine.inputManager.lockCursor()
		switchCamera(engine, engine.player.camera)
		engine.player.getPlayer<PlayerObject>().visible = true
		engine.renderer.resize()
	}



	function initSounds() {
		watch(() => gameStore.hits, (state) => {
			var audio = new Audio('/rspSpace/audio/game/' + Math.floor(Math.random() * 6 + 1) + '.mp3');
			audio.play();
		});
	}

	function saveToLeaderboard() {
		const username = localStorage.getItem("__raveengine__rspSpace__username")
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			"name": username,
			"points": gameStore.hits
		});

		fetch("https://cloud.ravespace.io/hosting/rspparadise/leaderboard/", {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
			mode: "no-cors"
		})
			.then(response => response.text())
			.then(result => console.log(result))
			.catch(error => console.log('error', error));
	}

	space.eventbus.on("gameFinished", (state) => {
		saveToLeaderboard()
		setTimeout(() => {
			updateLeaderboardTexture()
		}, 3000);
	})


	function updateLeaderboardTexture() {
		space.loader.getLoadingPromise("city").then((gltf) => {
			const screens = EngineUtils.Findings.findMeshs(gltf.scene, testUserData("CMSR"))
			screens.forEach((screen) => {
				if (screen.userData.CMSR == "trashGameLeaderboard") {
					replaceMaterial(screen, "https://cloud.ravespace.io/hosting/rspparadise/leaderboard/leaderboard.png?v=" + Date.now())
				}
			}
			)
		})
	}
	updateLeaderboardTexture()
}

export function switchCamera(engine: Engine, cam: THREE.PerspectiveCamera) {
	engine.renderer.renderCamera = cam
	cam.add(engine.audioManager.listener)
	engine.renderer.resize()

	const ppManager = engine.userData.ppManager as PostProcessingThree
	ppManager.renderPass.camera = cam
}
