import Engine, { EngineUtils, HighlightCylinder, NavPlayeableObjComponent } from "@ravespaceio/rave-engine";
import { useGameStore } from "~/store/game";
import { setupStage } from "./stage";
import { Group } from "three"
import * as THREE from "three"
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { ref, watch } from 'vue';
import { BlockingHintCollider } from "~/space/lib/manager/PCISystem";
import { NavigationControllerTypes } from "~/space/lib/manager/SpawnManager";
import { setupStageScreen } from "./screen";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";

export function setupSkybar() {

	const engine = getEngine()
	const space = getSpace()

	setupStage()
	setupStageScreen()
	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.scale.set(0.7, 0.7, 0.7)
	hc.position.set(-48, 19.6, -48.9)
	engine.debuggui.biggui.addToSceneFolder(hc)

	space.pciSystem.add(new BlockingHintCollider(new THREE.Vector3(-48, 19.6, -48.9), 1.5, { title: "Back to city" }, {
		onInteract() {
			engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, NavPlayeableObjComponent>('playerNav').jumpTo(new THREE.Vector3(-41.45, 2.95, -28.8))
			engine.player.camControllerM.goBehindPlayer(false);
			setTimeout(() => {
				engine.inputManager.lockCursor()
				engine.inputManager.unBlockInput()
			})
		}
	}))


	const hc2 = new HighlightCylinder()
	engine.scene.add(hc2)
	hc2.scale.set(0.7, 0.7, 0.7)
	hc2.position.set(-44.9, 2.95, -28.9)
	engine.debuggui.biggui.addToSceneFolder(hc2)

	space.pciSystem.add(new BlockingHintCollider(new THREE.Vector3(-44.9, 2.95, -28.9), 1.5, { title: "To the Skybar" }, {
		onInteract() {

			engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, NavPlayeableObjComponent>('playerNav').jumpTo(new THREE.Vector3(-48.68, 19.6, -46.1))
			engine.player.camControllerM.goBehindPlayer(false);

			setTimeout(() => {
				engine.inputManager.lockCursor()
				engine.inputManager.unBlockInput()
			})
		}
	}))

}
