import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getLsItem, setupReactiveLocalStorage } from "~/space/lib/utils/storeHelper";
import { ArtworkType } from "~/space/lib/gltfExtensions/ArtworkExtension";
import { QualityEnum, QualityLevelsType } from "~/space/lib/manager/MeshQualityMaterialManager";
import { getSpace } from "~/space/space";
import { AvatarConfig, RPM_PRE_AVATARS } from "~/space/lib/utils/rpm";
import { Vector3 } from "three";


var tid: any

/**
 * merge mit player store?
 * use new audio and volume mechanic
 *
 * there are way more states that define the project (zb chatActive, ingameControlsEnabled)
 * but if they are only used in one components we dont need to add them to the central store
 */
export const useSpaceStore = defineStore({
	id: "space",
	state: () => {
		const stateObj = ref({
			// onboarding states
			onboardingDone: false,
			tutorialDone: false,

			// interface states
			showMenu: false,
			showAvatarGenerator: false,
			showPointer: false,
			showChat: false,
			showTutorial: false,

			// interface data
			hint: {
				title: undefined as string | undefined,
				text: undefined as string | undefined,
				actionKeyworld: undefined as string | undefined,
			},
			hintTop: {
				title: undefined as string | undefined,
				text: undefined as string | undefined,
				actionKeyworld: undefined as string | undefined,
			},
			drink: {
				drinkId: "",
				name: "",
				img: "",
				duration: 0,
			},
			npcModal: undefined as { title: string, text: string, link: string, name: string, npcImage: string } | undefined,
			artworkModal: undefined as ArtworkType | undefined,
			showBarModal: false,
			showTeleportModal: false,

			// player data for frontend. cached. multiplayer is watching and updating itself
			playerName: undefined as string,
			avatar: {
				model: undefined as string,
				gender: undefined as "masculine" | "feminine",
				scale: 1 as number,
				preview: undefined as "loading" | string,
			},
			quality: undefined as undefined | QualityLevelsType,
		})

		// default name
		setupReactiveLocalStorage(stateObj, "playerName")
		if (!stateObj.value.playerName) stateObj.value.playerName = "Guest"

		// default avatar
		setupReactiveLocalStorage(stateObj, "avatar", legitCheckAvatar)
		if (!stateObj.value.avatar.model) stateObj.value.avatar.model = RPM_PRE_AVATARS[0].model
		if (!stateObj.value.avatar.gender) stateObj.value.avatar.gender = RPM_PRE_AVATARS[0].gender
		if (!stateObj.value.avatar.preview) stateObj.value.avatar.preview = RPM_PRE_AVATARS[0].preview

		// quality
		const space = getSpace()
		setupReactiveLocalStorage(stateObj, "quality", legiCheckQuality)
		if (stateObj.value.quality === undefined) stateObj.value.quality = space.browser.info.os == "iOS" ? "low" : "high"
		watch(() => stateObj.value.quality, (state) => { if (state) space.eventbus.emit("qualitysetting", state) }, { immediate: true })

		return stateObj
	},
	actions: {
		showPointerForFiveSec() {
			this.showPointer = true
			clearTimeout(tid)
			tid = setTimeout(() => {
				this.showPointer = false
			}, 5000)
		}
	}
})


export function legitCheckAvatar(config: AvatarConfig): boolean {
	if (!config) return false;
	if (config.scale === undefined) return false
	if (config.scale > 1.06 || config.scale < 0.89) config.scale = 1
	const modelPath = config.model
	const pattern = /^((http|https|ftp):\/\/)/;
	if (pattern.test(modelPath)) {
		return !!(modelPath.includes("readyplayer.me") || modelPath.includes("cloud.ravespace"))
	} else {
		return true
	}
}

export function legiCheckQuality(qual: string): boolean {
	if (!qual) return false
	return qual == "low" || qual == "mid" || qual == "high"
}
