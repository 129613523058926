import Engine from "@ravespaceio/rave-engine";
import { CamControllerSwitchOptions, CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import { PlayableObject } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Entities/PlayableObject";
import PlayerObject, { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";

export function toggleMount<T extends PlayableObject<T>, G extends PlayableObject<G>>(engine: Engine, from: PlayableObject<T> | undefined, to: PlayableObject<G>, camOptions: CamControllerSwitchOptions, navComp: { navName: string, active: boolean }) {

	if (from && from instanceof PlayerObject && from.isPlaying) {
		from.changeEntity(PlayerEntities.autoPlayer, { _mountObject: to });
		from.navi?.disableEntity(); //old Navigation
	} else if (from && !(from instanceof PlayerObject) && from.isPlaying) {
		from.navi?.disableEntity(); //old Navigation
	}

	if (to && to instanceof PlayerObject && !to.isPlaying) {
		to.changeEntity(PlayerEntities.playerPhysics);
	}


	engine.navigation.navigationComponentM.getNavigationComponent(navComp.navName).setActive(navComp.active);

	if (from) {
		from.isPlaying = !from.isPlaying;
		if (from.isPlaying) engine.player.camControllerM.setTargetObject(from)
	}

	if (to) {
		to.isPlaying = !to.isPlaying;
		if (to.isPlaying) engine.player.camControllerM.setTargetObject(to)
	} else {
		engine.player.camControllerM.setTargetObject(undefined);
		engine.player.camControllerM.changeController(CamControllersNames.FREE_CAM);
	}

	engine.player.camControllerM.updateAllControllers(camOptions);



}
