import Engine from '@ravespaceio/rave-engine';
import * as THREE from 'three';
import { Body, Material, Vec3 } from "cannon-es";
import confetti from "canvas-confetti"
import { resetBall } from './main';
import BoxCollider from "@ravespaceio/rave-engine/build/engine/src/engine/physics/old/BoxCollider";

export default class Goal extends THREE.Object3D {
	public colliders: BoxCollider[] | null[] = [null, null, null]
	public _engine: Engine;
	public _mesh!: THREE.Mesh;
	public physicsMaterial: Material;
	public height: number;
	public width: number;
	public postWidth: number = 0.1;
	public goaltrigger!: Body | null;
	constructor(engine: Engine, material: Material, width: number, height: number, mesh?: THREE.Mesh) {
		super()
		this._engine = engine;
		this.physicsMaterial = material;
		this.width = width;
		this.height = height;
		if (mesh) this._mesh = mesh;
		this.start();

	}


	start() {
		this.createPostColliders();
		this.createGoalShape();
		this.createTriggerGoal();
		this.addGoalEvent();
		// this.showWireframe();

	}


	createTriggerGoal() {
		const trigger = new BoxCollider(this._engine, { mass: 0, isTrigger: true });
		this.add(trigger);
		trigger._shape!.halfExtents = new Vec3(0.1, this.height / 2 - this.postWidth, this.width / 2 - this.postWidth);
		trigger.offset.set(0, this.height / 2, 0);
		this.goaltrigger = trigger._collider;

	}

	addGoalEvent() {
		this.goaltrigger!.addEventListener('collide', (event: any) => {
			if (event.body.parent.name === 'ball') {
				confetti({
					particleCount: 100,
					spread: 70,
					origin: { y: 0.6 }
				});
				console.log("Goal");
				resetBall(this._engine);
			}
		})
	}



	createPostColliders() {

		this.colliders = this.colliders.map((index) => {

			const collider = new BoxCollider(this._engine, { mass: 0, material: this.physicsMaterial })
			this.add(collider);
			collider.parenInControl = true;
			return collider
		})


	}



	createGoalShape() {

		const postPosition = [this.width / 2, -this.width / 2]
		const postHeight = this.height / 2 - this.postWidth / 2;
		let postIndex = 0;

		this.colliders.forEach((collider, index) => {
			// side goal bars
			if (index % 2 === 0) {
				collider!.offset.set(postPosition[postIndex], postHeight, 0);
				collider!._shape!.halfExtents = new Vec3(this.postWidth, postHeight, this.postWidth);
				postIndex += 1;
				// top bar
			} else {
				collider!._shape!.halfExtents = new Vec3(this.postWidth, this.postWidth, this.width / 2 + this.postWidth,);
				collider!.offset.set(0, this.height, 0);
			}

		})



	}


	showWireframe() {
		this.colliders.forEach((collider) => {
			this._engine.scene.add(collider!.renderWireframe());
		})

	}

	setWorldPosition(x: number, y: number, z: number) {



	}



}
