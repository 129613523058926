import Engine, { EngineUtils } from "@ravespaceio/rave-engine";
import { isHigherMat } from "@ravespaceio/rave-engine/build/engine/src/utils/materials";
import { Mesh, Object3D, Material, MeshPhongMaterial, MeshBasicMaterial, MeshStandardMaterial, MeshLambertMaterial } from "three"
import * as THREE from "three"
import AoLightManager from "./AoLightManager";
import { findMeshs } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";




export enum QualityEnum { "low", "mid", "high" }
export type QualityLevelsType = keyof typeof QualityEnum

/**
 * manages meshes which should change material based on a quality level
 *
 * Automated Material Quality AoLight Levels Workflow
 * 0. before: setup high materials used as base
 * 1. replace with shared material, if avaiable
 * 2. seperate/clone materials with ao/light to set individuel maps
 * 3. create downgraded materials if material.userData.__noQualityTransfer=true is not set
 * 4. when material switched and has ao lazy load lower/higher ao
 *
 * Ideas
 * - Unmanage
 * - Mark already managed meshes so we can call this again for models that are loaded later e.g.
 * - Create quality mats dynamcally on use
 */
export default class MeshQualityMaterialManager {

	// map of managed meshes
	public readonly meshs: { [uuid: string]: Mesh } = {}

	// map of used materials
	public readonly materials: {
		[mqId: string]: {
			high: Material,
			mid: Material,
			low: Material
		}
	} = {}

	constructor(qualityMeshs: Mesh[], private config: {
		midRefMat: MeshBasicMaterial | MeshLambertMaterial | MeshPhongMaterial
		lowRefMat: MeshBasicMaterial | MeshLambertMaterial | MeshPhongMaterial,
	}) {
		for (const mesh of qualityMeshs) {
			this.register(mesh)
		}
	}

	public register(mesh: Mesh) {
		const highMat = mesh.material
		if (highMat instanceof Array) {
			console.warn("MeshQualityMaterialManager does not support array materials");
			return
		}

		// use highMat uuid as id for internal recognition
		const mqId = highMat.uuid
		mesh.userData.mqId = mqId
		this.meshs[mesh.uuid] = mesh

		// check if material already known, if not create downgraded materials
		if (this.materials[mqId]) return;
		const midMat = isHigherMat(highMat, this.config.midRefMat) ? transferWithUserData(highMat, this.config.midRefMat.clone()) : highMat
		const lowMat = isHigherMat(highMat, this.config.lowRefMat) ? transferWithUserData(highMat, this.config.lowRefMat.clone()) : highMat
		this.materials[mqId] = { "high": highMat, "mid": midMat, "low": lowMat }
	}


	public unregister(mesh: Mesh, reset: boolean = true) {
		delete this.meshs[mesh.uuid]
	}

	public changeQuality(q: QualityLevelsType) {
		for (const mesh of Object.values(this.meshs)) {
			const matQ = this.materials[mesh.userData.matMapId]
			if (!matQ) continue;
			const newMat = matQ[q] as MeshStandardMaterial

			const oldMat = mesh.material as MeshStandardMaterial
			if (oldMat instanceof Array) continue;

			// switch material
			mesh.material = newMat
			AoLightManager.applyAoLightMap(oldMat, newMat)
		}
	}

	public static findMeshs(object: Object3D): Mesh[] {
		const qualityMatMeshs = findMeshs(object, (mesh) => {
			if (!mesh.material || mesh.material instanceof Array) return false;
			if (mesh.userData.CMSReference) return false
			if (mesh.material.userData.__noQualityTransfer) return false // TODO ohne das gehts mit base mat?
			return true
		})
		return [...new Set(qualityMatMeshs)]
	}
}

function transferWithUserData<T extends Material>(src: T, dst: T): T {
	// add in enginer add transfer param for userData und onBeforeCompoile
	EngineUtils.Materials.transferMaterialPropertiesTo(src, dst)
	dst.userData = JSON.parse(JSON.stringify(src.userData))
	// for custom shader
	if (src.onBeforeCompile) {
		dst.onBeforeCompile = src.onBeforeCompile
	}
	return dst
}

