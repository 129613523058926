import Engine, { HighlightCylinder } from "@ravespaceio/rave-engine";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { useSpaceStore } from "~/store/space";
import * as THREE from "three"
import { getEngine } from "~/space/engine";
import { BlockingHintCollider } from "~/space/lib/manager/PCISystem";
import { createNPC } from "~/space/lib/mechanic/npc";
import { getSpace } from "~/space/space";


export function setupNPCs() {
	const engine = getEngine()
	const npcs = new THREE.Group()
	engine.scene.add(npcs)

	createInteractiveNPC(
		engine,
		npcs,
		"Fabu",
		"/rspSpace/avatars/fabu.glb",
		"masculine",
		new THREE.Vector3(2, 1.4, 19.6),
		-0.24,
		"sitting",
		"Whatsup?",
		"Have you already checked out all of our engine features? We're currently working on a lot of live event features. Go check them here: ",
		"https://ravespace.io/engine",
		"/rspSpace/img/avatar/fabu.png",
		false
	)


	createInteractiveNPC(
		engine,
		npcs,
		"Bouncer",
		"/rspSpace/avatars/bouncer-female.glb",
		"feminine",
		new THREE.Vector3(28.5, 2.95, -39.79),
		-1.48,
		"idle_dancing",
		"Enter the Rave Space",
		"Our new club is currently in the making. But check out our old club RAVE SPACE CLUB which we opened during the pandemic and hosted over 40 virtual events.",
		"https://enter.ravespace.club/",
		"/rspSpace/img/avatar/bouncer.png",
		false
	)

	// createInteractiveNPC(
	// 	engine,
	// 	npcs,
	// 	"Frank",
	// 	"/rspSpace/avatars/frank.glb",
	// 	"masculine",
	// 	new THREE.Vector3(-0.4, 3, 9.8),
	// 	0.6,
	// 	"idle_dancing",
	// 	"Soldano?",
	// 	"Hey, ich bin Frank und nebenbei CEO. Willst du einen Frame kaufen?",
	// 	"https://ravespace.io",
	// 	"/rspSpace/img/avatar/frank.png"
	// )

	createInteractiveNPC(
		engine,
		npcs,
		"Konsti",
		"/rspSpace/avatars/konsti.glb",
		"masculine",
		new THREE.Vector3(-5.5, -0.5, 37.8),
		-4,
		"idle_dancing",
		"Blockout, Baking, Basemap?",
		"Want to know how a 3D Design process for the Metaverse looks like? Here you go",
		"https://docs.google.com/document/d/1idnxfNVAbPXBsmJH9b4JsP5vbCtaMWAFjM1yiO1KvNs/edit?usp=sharing",
		"/rspSpace/img/avatar/konsti.png",
		true
	)


	createInteractiveNPC(
		engine,
		npcs,
		"Lena",
		"/rspSpace/avatars/lena.glb",
		"feminine",
		new THREE.Vector3(-18, 2.87, 20),
		3.68,
		"idle_conversation",
		"Like what we do?",
		"We're currently looking for colleagues in the field of creative coding, game development and marketing.",
		"https://ravespace.io/jobs",
		"/rspSpace/img/avatar/lena.png",
		true
	)

	createInteractiveNPC(
		engine,
		npcs,
		"Esteban",
		"/rspSpace/avatars/esteban.glb",
		"masculine",
		new THREE.Vector3(24.54, 2.95, -45),
		-1.3,
		"idle_conversation",
		"Need usecases?",
		"We're building experiences for Art, Culture, Community Spaces, Events, Concerts, NFTs, Retail, Fashion and much more. Check our Use Cases page to see examples.",
		"https://ravespace.io/use-cases/",
		"/rspSpace/img/avatar/esteban.png",
		false
	)


	createInteractiveNPC(
		engine,
		npcs,
		"Tom",
		"/rspSpace/avatars/tom.glb",
		"masculine",
		new THREE.Vector3(-17.5, 0.3, 64.1),
		3.16,
		"idle_dancing",
		"Hey!",
		"Events launching soon! For now, check out our other projects:",
		"https://ravespace.io/use-cases/",
		"/rspSpace/img/avatar/tom.png",
		true
	)



	const michi = createNPC({
		name: "Michi",
		model: "/rspSpace/avatars/michi.glb",
		gender: "masculine",
		position: new THREE.Vector3(-18.6, 2.85, 19.369),
		rotation: 1.14,
		animation: "idle_conversation",
		scale: 1,
	}, true)


}


function createInteractiveNPC(engine: Engine, group: THREE.Object3D, name: string, path: string, skelett: "masculine" | "feminine", position: THREE.Vector3, rotation: number, animation: string, title: string, text: string, link: string, npcImage?: string, lookAtPlayer?: boolean) {
	const space = getSpace()
	const spaceStore = useSpaceStore()

	const npc = createNPC({ name, position, rotation, animation, scale: 1, model: path, gender: skelett }, true)
	engine.debuggui.biggui.addToSceneFolder(npc);
	group.add(npc)

	if (lookAtPlayer) {
		engine.loop.register(() => {
			npc.lookAt(engine.player.getPlayer<PlayerObject>().position)
		})
	}

	let haswaved = false
	space.pciSystem.add(new BlockingHintCollider(position, 2, { title: "Talk to " + name }, {
		onEnter() {
			if (haswaved == false && lookAtPlayer) {
				haswaved = true
				npc.getLoadedAvatar()?.triggerAction("wave", 0.5, false)
			}
		},
		onInteract() {
			spaceStore.npcModal = { title, text, link, name, npcImage }
		},
		onClose() {
			spaceStore.npcModal = undefined
		}
	}))
}




// function createNextliveNpc() {
// 	const space = getSpace()

// 	const nextlive = createNPC({
// 		name: "Anna",
// 		path: "/rspSpace/avatars/girl1.glb",
// 		skelett: "feminine",
// 		position: new THREE.Vector3(-12.95, 3.6, 23.67),
// 		rotation: 2.9,
// 		animation: "idle_dancing",
// 		scale: 1,
// 	}, true)


// 	const hc = new HighlightCylinder()
// 	space.engine.scene.add(hc)
// 	hc.scale.set(0.5, 0.3, 0.5)
// 	hc.position.set(-12.83, 3.6, 22.85)
// 	space.engine.debuggui.biggui.addToSceneFolder(hc)

// 	space.engine.debuggui.biggui.addToSceneFolder(nextlive)

// 	const data = {
// 		title: "NEXTLIVE.FESTIVAL 2023",
// 		type: "nextlive"
// 	}


// 	space.pciSystem.add(new BlockingHintCollider(new THREE.Vector3(-12.95, 3.6, 23.67), 4, "NEXTLIVE.FESTIVAL 2023", {
// 		onInteract() {
// 			space.eventbus.emit("nextliveModal", data)
// 		},
// 		onClose() {
// 			space.eventbus.emit("nextliveModal", undefined)
// 		}
// 	}))

// }


