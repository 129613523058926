import { Vector3, BoxGeometry, WireframeGeometry, LineSegments, Quaternion as THREEQuaternion } from 'three';
import Engine from '@ravespaceio/rave-engine';
import { Body, Quaternion } from 'cannon-es';


export function transformInputSpace(forwardVector: Vector3, rightVector: Vector3, inputVector: Vector3, outVector: Vector3): Vector3 {

	const zAxis = forwardVector;
	// ignore y component
	zAxis.set(zAxis.x, 0, zAxis.z).normalize();
	return outVector.addVectors(zAxis.multiplyScalar(inputVector.z), rightVector.multiplyScalar(inputVector.x));

}



export function debuggerWireframe(engine: Engine, body: Body) {
	if (body.shapes.length < 0) return null;

	const shape = body.shapes[0];
	// @ts-ignore
	const threeGeometry = new BoxGeometry(shape.halfExtents.x * 2, shape.halfExtents.y * 2, shape.halfExtents.z * 2)
	const wireframe = new WireframeGeometry(threeGeometry);
	const line = new LineSegments(wireframe);
	// @ts-ignore
	line.material.depthTest = true;
	// @ts-ignore

	line.material.opacity = 0.5;
	// @ts-ignore

	line.material.transparent = true;

	engine.scene.add(line);

	engine.loop.register((dt) => {

		line.position.copy(body.position as unknown as Vector3);
		line.quaternion.copy(body.quaternion as unknown as THREEQuaternion);

	})
}
