

import { Logging, LoggingArea } from "@ravespaceio/rave-engine";
import { VoiceChatResponseTypes } from "@ravespaceio/rave-engine/build/engineserver-common/src/MultiplayerMessages/VoiceChatResponseTypes";
import { useVoiceGroupStore } from "~/store/agora/voicegroups";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { useSpaceStore } from "~/store/space";
import { canAnswerVoicehat } from "~/space/lib/manager/PCISystem";



export async function setupAgoraVoiceGroups(): Promise<any> {
	const engine = getEngine()
	const space = getSpace()
	if (!engine.multiplayer || !engine.config.voicechat) {
		return;
	}


	const voicehandle = engine.multiplayer.voiceChatVgHandler;
	const vgStore = useVoiceGroupStore()
	const spaceStore = useSpaceStore()
	try {
		const agoraAppID = await fetch(`${engine.config.gameserverSocketURL}/agora/get_AppID`).then(res => res.json());
		// voicechat
		voicehandle.setAgoraController({
			appId: agoraAppID.appID,
			codec: 'vp8',
			mode: 'rtc',
		});

		Logging.trace("Agora Voice Group", LoggingArea.Agora)

	} catch (err) {
		Logging.error("Failed to setup agora", LoggingArea.Agora)
		return;
	}

	voicehandle.onLu_LeaveEvent.on(() => {
		vgStore.membersData = {};
		vgStore.voiceGroupActive = false;
		vgStore.showMsg(`You left the voicechat`)
	});

	voicehandle.onKickEvent.on((userId) => {
		vgStore.membersData = {};
		vgStore.voiceGroupActive = false;
		vgStore.showMsg("Everybody left the voicechat.")
	});

	voicehandle.onInv_OkEvent.on(() => {
		vgStore.showMsg("You joined a voicechat")
		vgStore.voiceGroupActive = true;
		voicehandle.controller.agoraClient.enableAudioVolumeIndicator()
		space.eventbus.emit("newMixpanel", { eventName: "joinVoicechat", customData: undefined })
	});

	voicehandle.onSm_JoinEvent.on((usersInChat) => {
		const state = { ...vgStore.membersData }
		for (const playerId in usersInChat) {
			if (playerId === engine.multiplayer!.playerId) continue;

			state[usersInChat[playerId]] = { ...state[usersInChat[playerId]], playerId };
		}
		vgStore.membersData = state;
	})

	voicehandle.controller.agoraClient.on('volume-indicator', (result: { uid: number, level: number }[]) => {
		const state = { ...vgStore.membersData }
		result.forEach((volumen, index) => {
			if (volumen.uid === voicehandle.userUid) {
				vgStore.ownData = { ...vgStore.ownData, volumenLevel: Math.round(volumen.level) };
				return;
			}
			state[volumen.uid] = { ...state[volumen.uid], volumenLevel: Math.round(volumen.level) }
		})

		vgStore.membersData = state;
	})

	voicehandle.onSm_LeftEvent.on((playerId) => {
		const state = { ...vgStore.membersData };
		Object.keys(state).forEach((key) => {
			if (state[key].playerId === playerId) {
				delete state[key]
			}
		})
		vgStore.membersData = state;
	})

	voicehandle.controller.agoraClient.on('user-published', (user: any, mediaType: any) => {
		if (mediaType === 'audio') {
			const state = { ...vgStore.membersData };
			state[user.uid] = { ...state[user.uid], muted: !user.audioTrack?.isPlaying }
			vgStore.membersData = state;
		}
	})

	engine.multiplayer.onRoomLeftEvent.on((data) => {
		vgStore.membersData = {}
	})

	voicehandle.onInv_BadEvent.on((response) => {
		let msg = 'Cannot Invite: ';
		const name = engine.multiplayer!.onlinePlayerStates[response!.otherPlayerId].playerNameState || response!.otherPlayerId

		switch (response!.responseCode) {
			case VoiceChatResponseTypes.groupErr:
				msg += `You and ${name} are in a channel.`
				break;
			case VoiceChatResponseTypes.decline:
				msg += `${name} decline invitation.`
				break;
			case VoiceChatResponseTypes.req_pend_local:
				msg += 'You have already a request pending'
				break;
			case VoiceChatResponseTypes.req_pend_remote:
				msg += `${name} has a request pending.`
				break;
			case VoiceChatResponseTypes.mic_permit_remote:
				msg += `${name} did not allow microphone access`
				break;
			case VoiceChatResponseTypes.mic_permit_local:
				msg += `Please allow microphone to use voice chat`
				break;
			case VoiceChatResponseTypes.server_Err:
				msg += `Somthing went wrong with the server`
				break;
			case VoiceChatResponseTypes.cancel_local:
				msg += `Invitation cancel`
				break;
			case VoiceChatResponseTypes.cancel_remote:
				// removes ui element
				vgStore.requestedName = '';
				// removes keypress listener
				document.removeEventListener("keydown", vgStore.respondKeyListener);
				msg += `${name} cancel the invitation`
				break;
			case VoiceChatResponseTypes.channelFull:
				msg += `Channel group is full`
				break;
			case VoiceChatResponseTypes.agora_Err:
				msg += `Agora error: Players did not join`
				break;
			case VoiceChatResponseTypes.user_disconnect:
				msg += `Invitation cancel user ${name} got disconnected`
				break;
		}

		vgStore.showMsg(msg);
	});


	voicehandle.onInviteNotificationEvent.on((otherUserId) => {
		const name = engine.multiplayer!.onlinePlayerStates[otherUserId!].playerNameState || otherUserId
		vgStore.showMsg(`You ask ${name} to join in a voice chat`);
	});


	voicehandle.onInviteRequestEvent.on((from: any) => {
		console.log('invited')
		const name = engine.multiplayer!.getOnlinePlayerStates(from).playerNameState;
		vgStore.requestedName = name;
		document.addEventListener("keydown", vgStore.respondKeyListener);
	});



	vgStore.respondKeyListener = (e) => {
		if (canAnswerVoicehat() === false) return;
		if (e.key == "y" || e.key == "Y") {
			if (voicehandle.answerInvite) voicehandle.answerInvite(true);
			vgStore.showMsg(`Invite from ${vgStore.requestedName} accepted`)

			document.removeEventListener("keydown", vgStore.respondKeyListener);
			vgStore.requestedName = '';


		} else if (e.key == "n" || e.key == "N") {
			if (voicehandle.answerInvite) voicehandle.answerInvite(false);
			vgStore.showMsg(`Invite from ${vgStore.requestedName} declined`)

			document.removeEventListener("keydown", vgStore.respondKeyListener);
			vgStore.requestedName = '';
		}
	}




}

