// import { watch } from 'vue'
import Engine, { Logging, LoggingArea, VideoScreenF } from "@ravespaceio/rave-engine"
import { AgoraClientConfig } from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/Handler/ClientMulitplayerAgoraHandlerBase"
import ClientMultiplayerMultiChannelHandler from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/Handler/ClientMultiplayerMultiChannelHandler"
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Role"
import { useVoiceRolesStore } from "~/store/agora/voiceroles"
import { getSpace } from "~/space/space"
import { getEngine } from "~/space/engine"

export type channelNameType = 'all' | 'none'

export const listChannelNames: channelNameType[] = ['all']


export const roleSchema: { [key: number]: channelNameType[] } = {
	[Role.default]: ['none'],
	[Role.superAdmin]: ['all']
}


export async function setupMultichannelsRoles() {
	const engine = getEngine()
	const space = getSpace()
	const channelPrefix = `${space.ENV.STAGE.toUpperCase()}_IA_ROLECHANNEL`
	let liveModeConfig: AgoraClientConfig;
	try {
		const agoraAppID = await fetch(`${engine.config.gameserverSocketURL}/agora/get_AppID`).then(res => res.json())
		liveModeConfig = {
			mode: "live",
			appId: agoraAppID.appID,
			codec: "vp8"
		}
		Logging.info(`Agora Roles/ScreenShare setup with channel prefix ${channelPrefix}`, LoggingArea.Agora)

	} catch (err) {
		Logging.error(`Agora appId was not receive abort..`, LoggingArea.Agora)
		return;

	}


	const multiChannelHandler = engine.multiplayer!.multiChannelHandler;
	const voiceRolesStore = useVoiceRolesStore()
	ClientMultiplayerMultiChannelHandler.initHandler<channelNameType>(multiChannelHandler, { channelNames: listChannelNames, roleSchema, config: liveModeConfig }, true, channelPrefix);
	const role = engine.multiplayer!.roleHandler.getLocalPlayerRole();
	await multiChannelHandler.createHandlers(role);
	// define behaviour when user enters the experience
	Logging.trace(`Joining with role ${role}`, LoggingArea.Agora)
	voiceRolesStore.userRole = role;
	multiChannelHandler.getAllHandlers().forEach((handler) => {
		handler.onHostJoinEvent.on((data) => {
			const { channelName, user } = data!
			// user from agora
		})

		handler.onHostLeavesEvent.on((data) => {
			const { channelName, user } = data!

			// user from agora
		})

		handler.onJoinEvent.on((data) => {
			const { role, channel, uid, playerId } = data!;

			if (role === 'host') {
				Logging.debug('join as host');

				voiceRolesStore.activeHostChannelInfo = { ...voiceRolesStore.activeHostChannelInfo, [removePrefix(channel)]: { muted: false, broadcasting: true } }
			} else if (role === 'audience') {
				Logging.debug('join as audience');
				voiceRolesStore.activeAudienceChannelInfo = { ...voiceRolesStore.activeAudienceChannelInfo, [removePrefix(channel)]: { muted: false, broadcasting: false } }
			}
		})

		handler.onLeaveEvent.on((data) => {
			const { role, channel, uid, playerId } = data!
			if (role === 'host') {
				const copyState = { ...voiceRolesStore.activeHostChannelInfo };
				delete copyState[removePrefix(channel)];
				voiceRolesStore.activeHostChannelInfo = copyState;
			} else if (role === 'audience') {
				const copyState = { ...voiceRolesStore.activeAudienceChannelInfo };
				delete copyState[removePrefix(channel)];
				voiceRolesStore.activeAudienceChannelInfo = copyState;
			}

		})

		handler.onUserInfoUpdated.on((data) => {
			// user from agora
			const { uid, msg } = data!;
			console.log(`${uid} is ${msg}`);

		})

		handler.controller.agoraClient.enableAudioVolumeIndicator();

		handler.controller.agoraClient.on('volume-indicator', (result: { uid: number, level: number }[]) => {
			result.forEach((volume) => {
				if (volume.uid === handler.userUid) {
					voiceRolesStore.volumenLevel = volume.level
				}
			})
		})

	})



	watch(() => voiceRolesStore.activeHostChannelInfo, (hostInfos) => {
		for (const channelName in hostInfos) {
			multiChannelHandler.muteAudioMic(channelName, hostInfos[channelName].muted)
		}
	})

	watch(() => voiceRolesStore.isJoined, (isJoined) => {
		if (isJoined) {
			multiChannelHandler.joinChannel<channelNameType>('all')
		} else {
			multiChannelHandler.leaveChannel<channelNameType>('all')
		}
	})


	function removePrefix(ch: string) {
		return ch.split(channelPrefix)[1]
	}


}



