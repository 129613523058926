import { defineStore } from 'pinia'
import { getEngine } from '../../space/engine';



export const useVoiceGroupStore = defineStore({
	id: "voicegroup",
	state: () => ({
		message: '' as string,
		voiceGroupActive: false,
		membersData: {} as { [key: string]: { playerId: string, volumenLevel?: number, muted?: boolean } },
		ownData: {} as { volumenLevel: number, muted?: boolean },
		respondKeyListener: (e: any) => { },
		requestedName: '' as string
	}),
	getters: {
		// combined states
		listOfNames(): string[] {
			const engine = getEngine()
			const listOfNames = Object.keys(this.membersData).map(key => engine.multiplayer!.onlinePlayerStates[this.membersData[key].playerId]?.playerNameState);
			return listOfNames
		},
		listOfVolumenLevel(): (number | undefined)[] {
			const listVolumenLevel = Object.keys(this.membersData).map(key => this.membersData[key].volumenLevel);
			return listVolumenLevel
		},
		listOfMutedIndicator(): (boolean | undefined)[] {
			const listMutedIndicator = Object.keys(this.membersData).map(key => this.membersData[key].muted);
			return listMutedIndicator
		},
		listOfUid(): string[] {
			const listOfUid = Object.keys(this.membersData)
			return listOfUid
		}
		// navigation restriction

	},
	actions: {
		showMsg(msg: string) {
			this.message = msg;
			setTimeout(() => {
				if (this.message === msg) this.message = '';
			}, 5000);
		},
		muteMember(uid: number) {
			const state = { ...this.membersData };
			state[uid].muted = true;
			this.membersData = state;
		},
		unMutedMember(uid: number) {
			const state = { ...this.membersData }
			state[uid].muted = false;
			this.membersData = state;
		}
	}
})
