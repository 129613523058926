<template>
	<div v-show="showTutorial">
		<div id="tutorial">
			<div class="inner">
				<div id="tutbox">
					<div id="tutid">Tutorial</div>
					<div id="tuticons">

						<div id="tuticons1" class="tuticons">
							<div id="tutkey1" class="keyicon">W</div>
							<div id="tutkey2" class="keyicon">A</div>
							<div id="tutkey3" class="keyicon">S</div>
							<div id="tutkey4" class="keyicon">D</div>
						</div>
						<div id="tuticons1-mobile" class="tuticons">
							<div class="joystick-outer">
								<div id="joystick-inner" class="joystick-inner" />
							</div>
						</div>

						<svg id="tuticons2" class="tuticons" width="35" height="57" viewBox="0 0 35 57" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.1309 57C21.6743 57 26.0316 55.1951 29.2443 51.9825C32.4569 48.7698 34.2618 44.4125 34.2618 39.8691V17.0913C34.2613 12.7404 32.6051 8.55274 29.6295 5.37847C26.6539 2.2042 22.5818 0.281248 18.24 0V7.51596C18.2389 7.58729 18.2328 7.65846 18.2218 7.72895C18.9838 7.96264 19.6507 8.43458 20.1245 9.07541C20.5984 9.71625 20.8542 10.4922 20.8543 11.2892V19.0529C20.8543 20.0404 20.462 20.9875 19.7637 21.6857C19.0655 22.384 18.1184 22.7763 17.1309 22.7763C16.1434 22.7763 15.1963 22.384 14.498 21.6857C13.7998 20.9875 13.4075 20.0404 13.4075 19.0529V11.2892C13.4076 10.4922 13.6633 9.71623 14.1372 9.07539C14.611 8.43455 15.2779 7.96262 16.0399 7.72895C16.029 7.65845 16.0229 7.58729 16.0218 7.51596V0C11.68 0.281242 7.60791 2.2042 4.63228 5.37846C1.65665 8.55273 0.000475817 12.7404 0 17.0913V39.8691C-1.66341e-06 42.1188 0.443102 44.3464 1.30401 46.4248C2.16492 48.5033 3.42677 50.3917 5.01752 51.9825C6.60827 53.5732 8.49676 54.8351 10.5752 55.696C12.6536 56.5569 14.8812 57 17.1309 57V57Z" fill="#F4D4FB" />
							<path d="M15.625 19.0531C15.625 19.4523 15.7836 19.8352 16.0659 20.1174C16.3481 20.3997 16.731 20.5583 17.1302 20.5583C17.5294 20.5583 17.9123 20.3997 18.1946 20.1174C18.4768 19.8352 18.6354 19.4523 18.6354 19.0531V11.2894C18.6354 10.8902 18.4768 10.5073 18.1946 10.225C17.9123 9.94276 17.5294 9.78418 17.1302 9.78418C16.731 9.78418 16.3481 9.94276 16.0659 10.225C15.7836 10.5073 15.625 10.8902 15.625 11.2894V19.0531Z" fill="#F4D4FB" />
						</svg>
						<svg id="tuticons2-mobile" class="tuticons" width="38" height="52" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M27.8738 14.855C26.5493 14.855 25.4734 15.9319 25.4734 17.2554V22.3315C25.4734 22.7706 25.1162 23.1278 24.6776 23.1278C24.2391 23.1278 23.8819 22.7706 23.8819 22.3315V14.2477C23.8819 12.9242 22.805 11.8473 21.4815 11.8473C20.1754 11.8473 19.1095 12.8969 19.0833 14.1978L19.0812 14.2094L19.0818 22.3315C19.0818 22.77 18.7251 23.1273 18.2871 23.1273C17.8485 23.1273 17.4913 22.77 17.4913 22.331L17.4918 12.7417C17.4897 11.4182 16.4118 10.3418 15.0899 10.3418C13.7654 10.3418 12.6895 11.4187 12.6895 12.7422V22.3299C12.6895 22.769 12.3323 23.1262 11.8937 23.1262C11.4552 23.1262 11.098 22.769 11.098 22.3299V2.64499C11.098 1.32153 10.0211 0.244629 8.69818 0.244629C7.37368 0.244629 6.29782 1.32153 6.29782 2.64499V23.8353C6.29782 24.2743 5.9406 24.6315 5.50207 24.6315C5.06355 24.6315 4.70632 24.2743 4.70632 23.8353V18.5409L3.78573 19.2905C1.40323 21.2297 0.0917969 23.7115 0.0917969 26.2786C0.0917969 34.5985 6.86114 41.3696 15.1828 41.3696C23.5044 41.3707 30.2752 34.6008 30.2752 26.2799V17.2561C30.2752 15.9326 29.1993 14.8568 27.8748 14.8568L27.8738 14.855Z" fill="#F4D4FB" />
						</svg>

						<div id="tuticons3" class="tuticons">
							<div id="tutkey5" class="keyicon">M</div>
						</div>
						<div id="tuticons3-mobile" class="tuticons">
							<div id="tutkey5-mobile">Settings</div>
						</div>

					</div>
					<div id="tuttext">Walk in a direction</div>
					<p id="tuttext2">Change your Avatar & Quality</p>
					<div id="tutprogress" />
				</div>
				<div id="skiptutorial">
					<div class="box">Press <span>SPACE</span> to skip</div>
				</div>
			</div>
		</div>

		<div v-show="$isMobile" id="skiptutorial-mobile" @click="showTutorial = false">Click to skip tutorial</div>
	</div>
</template>

<script scoped setup lang="ts">
import Vue from 'vue'
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space'
import { getLsItem, setLsItem } from '~/space/lib/utils/storeHelper';
import { storeToRefs } from 'pinia';
import { VideoScreenF } from '@ravespaceio/rave-engine';


const space = getSpace();
const spaceStore = useSpaceStore()
const { showTutorial } = storeToRefs(spaceStore)

// cache
const tutorialPlayed = getLsItem("tutorialPlayed")
watch(() => spaceStore.onboardingDone, () => {
	if (tutorialPlayed) {
		spaceStore.tutorialDone = true
	} else {
		setTimeout(() => {
			startTutorial()
		}, 2000)
	}
})

// watch in state change
watch(() => spaceStore.showTutorial, (state) => {
	state ? startTutorial() : killTutorial()
})

// skip
space.eventbinder.bindDocument("keyup", (e) => {
	if (e.key == " ") { showTutorial.value = false }
})

let tl: TimelineLite | undefined
function killTutorial() {
	spaceStore.tutorialDone = true
	setLsItem("tutorialPlayed", true)
	if (tl) {
		tl.kill()
		tl = undefined
	}
	document.getElementById("tutbox")!.style.height = "280px";
	document.getElementById("tuticons")!.style.display = "flex";
	document.getElementById("tuticons1")!.style.display = "none";
	document.getElementById("tuticons2")!.style.display = "none";
	document.getElementById("tuticons3")!.style.display = "none";
	document.getElementById("tuticons1-mobile")!.style.display = "none";
	document.getElementById("tuticons2-mobile")!.style.display = "none";
	document.getElementById("tuticons3-mobile")!.style.display = "none";
	document.getElementById("tuttext")!.innerHTML = "Walk in a direction"
	document.getElementById("tutorial")!.style.display = "none";
}


function startTutorial() {
	tl = new TimelineLite({
		onComplete: () => {
			showTutorial.value = false
		}
	});
	if (space.browser.isMobile) { tl.to("#skiptutorial", 0.01, { display: "none" }); }
	tl.to("#tutorial", 0.1, { display: "block" });
	tl.to("#tutbox", 0.7, { opacity: 1, y: 0 });
	tl.to("#tuticons", 0.7, { opacity: 1 });
	tl.to("#tuttext", 0.7, { opacity: 1 });

	// Tutorial Walk
	if (!space.browser.isMobile) {
		document.getElementById("tuticons1")!.style.display = "block"
		tl.to("#tutkey1", 0.1, { scale: 0.84 }, "+=1.5");
		tl.to("#tutkey1", 0.2, { scale: 1 });
		tl.to("#tutkey3", 0.1, { scale: 0.84 }, "+=0.2");
		tl.to("#tutkey3", 0.2, { scale: 1 });
		tl.to("#tutkey2", 0.1, { scale: 0.84 }, "+=0.2");
		tl.to("#tutkey2", 0.2, { scale: 1 });
		tl.to("#tutkey4", 0.1, { scale: 0.84 }, "+=0.2");
		tl.to("#tutkey4", 0.2, { scale: 1 });
		tl.to("#tuticons", 0.7, { opacity: 0 }, "+=0.8");
		tl.to("#tuttext", 0.7, { opacity: 0 }, "-=0.7");
		tl.add(function () {
			document.getElementById("tuttext")!.innerHTML = "Look around"
			document.getElementById("tuticons1")!.style.display = "none"
			document.getElementById("tuticons2")!.style.display = "block"
		})
	} else {
		tl.to("#skiptutorial-mobile", 0.5, { opacity: 1 });
		document.getElementById("tuticons1-mobile")!.style.display = "block"
		tl.to("#tutkey1", 0.1, { scale: 0.84 }, "+=1.5");
		tl.to("#joystick-inner", 0.6, { y: -10, x: 18 });
		tl.to("#joystick-inner", 0.6, { y: 20, x: -5 });
		tl.to("#joystick-inner", 0.6, { y: -20, x: -10 });
		tl.to("#tuticons", 0.7, { opacity: 0 }, "+=0.8");
		tl.to("#tuttext", 0.7, { opacity: 0 }, "-=0.7");
		tl.add(function () {
			document.getElementById("tuttext")!.innerHTML = "Look around"
			document.getElementById("tuticons1-mobile")!.style.display = "none"
			document.getElementById("tuticons2-mobile")!.style.display = "block"
		})
	}


	// Tutorial Look
	tl.to("#tuticons", 0.7, { opacity: 1 }, "+=0.5");
	tl.to("#tuttext", 0.7, { opacity: 1 }, "-=0.2");

	if (!space.browser.isMobile) {
		tl.to("#tuticons2", 0.7, { rotation: -20, x: -10 });
		tl.to("#tuticons2", 1.4, { rotation: 20, x: 10 });
		tl.to("#tuticons2", 1.4, { rotation: -20, x: -10 });
		tl.to("#tuticons", 0.7, { opacity: 0 }, "+=1");
		tl.to("#tuttext", 0.7, { opacity: 0 }, "-=0.7");
		tl.add(function () {
			document.getElementById("tuttext")!.innerHTML = "Menu"
			document.getElementById("tuttext2")!.style.display = "block"
			document.getElementById("tuttext")!.style.marginTop = "-20px"
			document.getElementById("tuticons2")!.style.display = "none"
			document.getElementById("tuticons3")!.style.display = "block"
		})
	} else {
		tl.to("#tuticons2-mobile", 0.7, { rotation: -20, x: -10 });
		tl.to("#tuticons2-mobile", 1.4, { rotation: 20, x: 10 });
		tl.to("#tuticons2-mobile", 1.4, { rotation: -20, x: -10 });
		tl.to("#tuticons", 0.7, { opacity: 0 }, "+=1");
		tl.to("#tuttext", 0.7, { opacity: 0 }, "-=0.7");
		tl.add(function () {
			document.getElementById("tuttext")!.innerHTML = "Customize"
			document.getElementById("tuttext2")!.style.display = "block"
			document.getElementById("tuttext")!.style.marginTop = "-20px"
			document.getElementById("tuticons2-mobile")!.style.display = "none"
			document.getElementById("tuticons3-mobile")!.style.display = "block"
		})
	}


	// Tutorial Menu
	tl.to("#tuticons", 0.7, { opacity: 1 }, "+=0.5");
	tl.to("#tuttext", 0.7, { opacity: 1 }, "-=0.2");
	tl.to("#tuttext2", 0.7, { opacity: 1 }, "-=0.2");
	tl.to("#tutkey5", 0.1, { scale: 0.84 }, "+=0.8");
	tl.to("#tutkey5", 0.2, { scale: 1 });
	tl.to("#tuticons", 0.7, { opacity: 0 }, "+=1.7");
	tl.to("#tuttext", 0.7, { opacity: 0 }, "-=0.7");
	tl.to("#tuttext2", 0.7, { opacity: 0 }, "-=0.7");
	tl.add(function () {
		document.getElementById("tuttext")!.innerHTML = "Let's go!"
		document.getElementById("tuttext")!.style.marginTop = "5px"
		document.getElementById("tuticons")!.style.display = "none"
		document.getElementById("tuttext2")!.style.display = "none"
	})
	tl.to("#skiptutorial", 0.4, { opacity: 0 });
	if (space.browser.isMobile) {
		tl.to("#skiptutorial-mobile", 0.3, { opacity: 0 });
	}
	tl.to("#tutbox", 0.5, { height: "160px" }, "+=0.2");
	tl.to("#tuttext", 0.7, { opacity: 1 }, "-=0.2");
	tl.to("#tutbox", 0.7, { opacity: 0, y: -20 }, "+=2");
}

</script>



<style lang="scss" scoped>
@import "assets/css/variables";

#tutorial {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: none;
	pointer-events: none;

	.inner {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		#tutbox {
			@extend .box;
			position: relative;
			width: 450px;
			height: 280px;
			max-width: calc(100% - 30px);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			opacity: 0;
			transform: translateY(20px);

			#tutid {
				position: absolute;
				text-align: center;
				padding: 3px;
				top: -14px;
				font-weight: 600;
				background: black;
				left: calc(50% - 45px);
				width: 90px;
				border: 1px solid $main;
				color: $main;
				border-radius: 10px;
			}

			#tuttext {
				color: $main;
				font-weight: 600;
				font-size: 22px;
				text-align: center;
				opacity: 0;
			}

			#tuttext2 {
				display: none;
			}

			#tuticons {
				width: 180px;
				height: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;

				.keyicon {
					width: 38px;
					height: 38px;
					background: $main;
					border-radius: 2px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 700;
					position: absolute;
				}
			}
		}
	}
}

#skiptutorial {
	color: white;
	text-align: center;
	width: 100%;
	margin-top: 20px;
	position: relative;
	z-index: 999;
	cursor: pointer;
	@extend .flex-center;

	.box {
		@extend .flex-center;
		padding: 10px;
		font-weight: 700;
		color: $main;

		span {
			background: $main;
			color: $dark;
			font-weight: 700;
			padding: 2px 7px 2px 9px;
			margin-right: 1px;
			border-radius: 4px;
			font-size: 12px;
			margin: 0 6px;
		}
	}
}



#tuticons1,
#tuticons3 {
	position: relative;
	width: 100%;
	height: 100%;

	#tutkey1 {
		left: calc(50% - 19px);
		top: -7px;
	}

	#tutkey2 {
		left: calc(50% - 66px);
		top: 39px;
	}

	#tutkey3 {
		left: calc(50% - 19px);
		top: 39px;
	}

	#tutkey4 {
		left: calc(50% + 28px);
		top: 39px;
	}

	#tutkey5 {
		left: calc(50% - 19px);
		top: 20px;
	}
}

.tuticons {
	display: none;

	* {
		fill: $main;
	}
}

#tuttext2 {
	opacity: 0;
	color: $main;
	text-align: center;
	margin-top: 6px;
}

.joystick-outer {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(143, 143, 143, 0.4);
	border: 2px solid rgba(49, 49, 49, 0.12);
	box-shadow: rgb(0 0 0 / 45%) 0px 0px 7px inset;
	margin-bottom: 10px;
}

.joystick-inner {
	width: 55px;
	height: 55px;
	border-radius: 100%;
	background: rgb(192, 192, 192);
	box-shadow: rgb(0 0 0 / 41%) 0px -1px 9px inset;
}

#tutkey5-mobile {
	@extend .btn;
	height: 30px;
	@extend .btn--mini;
	background: $main;
}

#skiptutorial-mobile {
	@extend .btn;
	position: fixed;
	bottom: 20px;
	left: calc(50% - 100px);
	width: 200px;
	opacity: 0;
	z-index: 99;
	padding: 14px;
	text-align: center;
}
</style>
