import { Mesh, Material, MeshBasicMaterial, Scene, Texture, Vector2, Vector3, MeshStandardMaterial } from "three"
import Engine, { EngineUtils, MyLoaders, VertexWindDisplacer, VideoScreenF, VolumetricLightMaterial } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { findAllMaterials, findMaterial, findMesh, findMeshs, findObjects, findObjectsByUserData, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { setupMaterialBlending } from "~/space/lib/shader/blend"
import { setupWaterMesh } from "./water"
import { setupAlwaysPlayerFacing } from "~/space/lib/shader/billboard"
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper"
import { setupAoMapLightMapQualityManager } from "~/space/lib/mechanic/materials"
import { applyMaterials } from "~/space/lib/mechanic/materials"

var defaultEnvMap: Texture

export function getEnvMap(): Texture {
	if (defaultEnvMap) return defaultEnvMap
	const space = getSpace()
	return defaultEnvMap = getMatMap(space.loader, `/${space.project.publicPath}/textures/env_map.jpg`, { encoding: THREE.LinearEncoding, mapping: THREE.EquirectangularReflectionMapping, flipY: true })
}



export function setupMaterials(): (group: THREE.Object3D) => void {

	const engine = getEngine()
	const space = getSpace()
	const loader = space.loader
	const defaultEnvMap = getEnvMap()

	const texPath = space.project.publicPath + "/textures/"
	var isIos = space.browser.info.os == "iOS"
	var isMobile = space.browser.isMobile
	var suffix
	var forceLowTex = isIos = true
	if (forceLowTex) { isIos = true }
	if (isMobile) { suffix = "low/" } else { suffix = "high/" }
	forceLowTex = true
	const texQualityPath = texPath + suffix
	console.log(texQualityPath);


	const MATS = {
		Glass: new MeshStandardMaterial({
			color: 0xD3E5FF,
			metalness: 1,
			roughness: 0,
			transparent: true,
			opacity: 0.4,
			depthTest: true,
			depthWrite: false,
			alphaTest: 0,
			envMap: defaultEnvMap,
			envMapIntensity: 0.5,
		}),
		Water: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "water_base.jpg", { encoding: THREE.sRGBEncoding }),
			roughnessMap: isIos ? undefined : getMatMap(loader, texQualityPath + "water_rough.jpg"),
			normalMap: isIos ? undefined : getMatMap(loader, texQualityPath + "water_normal.jpg"),
			metalness: 0,
			roughness: 1,
			envMap: defaultEnvMap,
		}),
		Base: new MeshStandardMaterial({
			map: getMatMap(loader, texQualityPath + "base_base.jpg", { encoding: THREE.sRGBEncoding }),
			color: 0xFFFFFF,
			metalness: 0,
			roughness: 1,
		}),
		Black: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "black_base.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		Metal: new MeshStandardMaterial({
			color: 0x666666,
			roughnessMap: getMatMap(loader, texQualityPath + "metal_rough.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 1,
			roughness: 1,
			envMap: defaultEnvMap,
			envMapIntensity: 1,
		}),
		Mirror: new MeshStandardMaterial({
			color: 0x666666,
			metalness: 1,
			roughness: 0,
			envMap: defaultEnvMap,
			envMapIntensity: 1,
		}),
		Concrete: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "concrete_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "concrete_normal.jpg"),
			roughnessMap: getMatMap(loader, texQualityPath + "concrete_rough.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		Concrete_Dark: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "concrete_new_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "concrete_new_normal.jpg"),
			roughnessMap: getMatMap(loader, texQualityPath + "concrete_new_rough.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		Sand: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "sand_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "sand_normal.jpg"),
			roughnessMap: getMatMap(loader, texQualityPath + "sand_rough.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		Street: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "pavement_base.jpg", { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, texQualityPath + "pavement_rough.jpg"),
			normalMap: getMatMap(loader, texQualityPath + "pavement_normal.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		Grass: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "grass_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "grass_normal.jpg"),
			// roughnessMap:  getMatMap(loader, `/${PROJECT}` + SUFFIX + "grass_rough.jpg"),
			metalness: 0,
			roughness: 0.8,
		}),
		Path: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "path_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "path_normal.jpg"),
			// roughnessMap:  getMatMap(loader, `/${PROJECT}` + SUFFIX + "path_rough.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		Streets: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "asphalt_base.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 0.8,
		}),
		Wood: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "wood_base.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		TS_Wood: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "ts_wood_old_natural_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "ts_wood_old_natural_normal.jpg"),
			roughnessMap: getMatMap(loader, texQualityPath + "ts_wood_old_natural_rough.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		Facade: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "facade.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		Facade01: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "facade01.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		Bricks: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "brickwall_base.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		wall_mid_base: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "wall_mid_base.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		Cloth: new MeshStandardMaterial({
			color: 0xE6E8C8,
			map: getMatMap(loader, texQualityPath + "fabric_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "fabric_normal.jpg"),
			metalness: 0,
			roughness: 0.8,
		}),
		Stone: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "rock_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "rock_normal.jpg"),
			roughnessMap: getMatMap(loader, texQualityPath + "rock_rough.jpg"),
			metalness: 0,
			roughness: 1,
		}),
		TA_RSP: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "ta_rsp.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		TA_PalmTreePack: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "ta_palmtreepack_base.jpg", { encoding: THREE.sRGBEncoding }),
			normalMap: getMatMap(loader, texQualityPath + "ta_palmtreepack_normal.jpg"),
			roughnessMap: getMatMap(loader, texQualityPath + "ta_palmtreepack_rough.jpg"),
			alphaMap: getMatMap(loader, texQualityPath + "ta_palmtreepack_alpha.jpg"),
			alphaTest: 0.5,
			metalness: 0,
			roughness: 1,
			side: THREE.DoubleSide,
			envMap: defaultEnvMap,
			envMapIntensity: 0.4,
		}),
		RAS: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "rsas_base.jpg", { encoding: THREE.sRGBEncoding }),
			// normalMap: getMatMap(loader, texQualityPath + "rsas_normal.jpg"),
			// roughnessMap: getMatMap(loader, texQualityPath + "rsas_rough.jpg"),
			metalness: 0,
			roughness: 1,
			// envMap: defaultEnvMap,
			// envMapIntensity: 0.5,
		}),
		Map: new MeshBasicMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `/${texPath}` + "map.jpg", { encoding: THREE.sRGBEncoding }),
		}),
		ta_navigation: new MeshBasicMaterial({
			color: 0x999999,
			alphaMap: getMatMap(loader, `/${texPath}` + "ta_navigation.jpg", { encoding: THREE.sRGBEncoding }),
			alphaTest: .5,
		}),
		Cubemap_Windows: new MeshBasicMaterial({
			map: getMatMap(loader, texQualityPath + "cubemap_windows.jpg", { encoding: THREE.sRGBEncoding }),
			color: 0x666666,
		}),
		Lamp: new MeshStandardMaterial({
			map: getMatMap(loader, `/${texPath}` + "lamp.jpg", { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: .4,
			envMap: defaultEnvMap,
			envMapIntensity: 0.4,
			// color: 0x333333,
		}),
		TA_Decals: new MeshBasicMaterial({
			map: getMatMap(loader, texQualityPath + "TA_Decals.png", { encoding: THREE.sRGBEncoding }),
			color: 0x333333,
			alphaTest: .2,
		}),
		Arrows: new MeshBasicMaterial({
			alphaMap: getMatMap(loader, `/${texPath}` + "arrows.jpg", { encoding: THREE.sRGBEncoding }),
			color: 0xffffff,
			alphaTest: .2,
		}),
		Waterfall: new MeshBasicMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `/${texPath}` + "fulid_03.jpg", { encoding: THREE.sRGBEncoding }),
			alphaMap: getMatMap(loader, `/${texPath}` + "waterfall_alpha.jpg"),
			transparent: true,
			alphaTest: 0,
			depthWrite: false,
			depthTest: true,
		}),
		Waterfall_L1: new MeshBasicMaterial({
			color: 0xFFFFFF,
			alphaMap: getMatMap(loader, `/${texPath}` + "t1_alpha.jpg"),
			transparent: true,
			alphaTest: 0,
			depthWrite: false,
			depthTest: true,
		}),
		Waterfall_L2: new MeshBasicMaterial({
			color: 0xFFFFFF,
			alphaMap: getMatMap(loader, `/${texPath}` + "t2_alpha.jpg"),
			transparent: true,
			alphaTest: 0,
			depthWrite: false,
			depthTest: true,
		}),
		Emissive: new MeshBasicMaterial({
			color: 0x6245F2,
		}),
		Boat: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, texQualityPath + "boat_base_color.jpg", { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, texQualityPath + "boat_roughness.jpg"),
			normalMap: getMatMap(loader, texQualityPath + "boat_normal_directx.jpg"),
			metalness: 0,
			roughness: 1,
			envMap: defaultEnvMap,
		})
	}



	MATS.Glass.userData.__noQualityTransfer = true
	MATS.Water.userData.__noQualityTransfer = true
	MATS.Metal.userData.__noQualityTransfer = true
	MATS.Mirror.userData.__noQualityTransfer = true
	MATS.TA_PalmTreePack.userData.__noQualityTransfer = true




	engine.loop.register((dt) => {
		MATS.Waterfall.map!.offset.y -= (dt / -8)
		MATS.Waterfall_L1.alphaMap!.offset.y -= (dt / -6)
		MATS.Waterfall_L2.alphaMap!.offset.y -= (dt / -4)
		MATS.Arrows.alphaMap!.offset.x += (dt / -10)

		const t = performance.now() / 1000
		MATS.Water.map.offset.y += (Math.sin(t / 2) / 1000)
		MATS.Water.map.offset.x += (Math.sin(t / 3) / 1000)
	})




	const PER_MESH_SHADER = {
		water: (m: Mesh) => {
			setupWaterMesh(m)
		},
		bilboard: (m: Mesh) => {
			m.userData.__noQualityTransfer = true
			setupAlwaysPlayerFacing(m)
		},
		blend: (m: Mesh) => {
			if (m.userData.REID == "island") setupMaterialBlending(m, MATS.Path, MATS.Grass.clone())
		},
		volumetric: (m: Mesh) => {
			const vMat = m.material = new VolumetricLightMaterial(m.getWorldPosition(new Vector3()), new THREE.Color(0x6245F2), 20, 2)
			vMat.userData.__noQualityTransfer = true
			engine.debuggui.biggui.addToSceneFolder(vMat)
		},
	}
	const PER_SCENE_SHADER = {
		vertexWind: (m: Mesh[]) => {
			const vwd = new VertexWindDisplacer(m)
			engine.loop.registerI(vwd)
			VertexWindDisplacer.addToGui(engine.debuggui.biggui, vwd)
		}
	}

	return (scene) => {
		applyMaterials(scene, MATS, PER_MESH_SHADER, PER_SCENE_SHADER)
		setupAoMapLightMapQualityManager(scene)
	}
}


