import Engine, { EngineUtils } from "@ravespaceio/rave-engine"
import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three"
import { findMeshs, testName, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { MeshBasicMaterial, MeshLambertMaterial } from "three"
import { usePanelStore } from "~/store/panel";
import { hashToHexColor } from "./main";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";



export default async function setupNeons() {

	const engine = getEngine()
	const space = getSpace()

	const gltf = await space.loader.getLoadingPromise("stadium") as GLTF
	const baseMaterial = new MeshBasicMaterial()

	const fxidsmeshs = findMeshs(gltf.scene, testUserData("FXID"))
	const neonsArr: MeshBasicMaterial[][] = []
	neonsArr[0] = []
	neonsArr[1] = []

	for (var i = 0; i < fxidsmeshs.length; i++) {
		const mesh = fxidsmeshs[i]
		const fxid = mesh.userData.FXID

		let neonid = fxid.split("_")[1]
		if (!neonid) continue;

		// const a = parseInt(neonid[0])
		// const b = parseInt(neonid[1])
		// if (!neonsArr[a]) neonsArr[a] = []
		// neonsArr[a][b] = mesh.material = baseMaterial.clone()

		let id = parseInt(neonid)
		let set = 0
		if (id >= 16) {
			id -= 16
			set = 1
		}
		neonsArr[set][id] = mesh.material = baseMaterial.clone()
	}

	// ANIMATION
	const lighColor1 = new THREE.Color("blue")
	const lighColor2 = new THREE.Color("purple")
	let aniSeq

	space.eventbus.onAlready("laserarray_color", (state) => { lighColor1.setHex(hashToHexColor(state)) })
	space.eventbus.onAlready("laserarray_sequence", (state) => { aniSeq = state })

	engine.loop.register(() => {
		if (!aniSeq) {
			resetAll()
			return
		};
		const t = Date.now() / 1000
		if (aniSeq == 1) {
			loop(neonsArr[0], t * 8, lighColor1)
			loop(neonsArr[1], t * 8, lighColor2)
			// idk(neonsArr[2], t * 2)
		}
		else if (aniSeq == 2) {
			onof(neonsArr[0], t * 6, lighColor1)
			onof(neonsArr[1], t * 6, lighColor2)
			// inout(neonsArr[2], t * 4)
		}
	})


	// TODO combine with laser arrays code?

	function resetAll() {
		for (let i = 0; i < neonsArr.length; i++) {
			for (let j = 0; j < neonsArr[i].length; j++) {
				const element = neonsArr[i][j]
				element.color.setRGB(0, 0, 0)
			}
		}
	}
	function onof(arr: MeshBasicMaterial[], t: number, baseColor: THREE.Color) {
		const on = t % 2
		for (var i = 0; i < arr.length; i++) {
			const mat = arr[i]
			mat.color.copy(baseColor).multiplyScalar(on / 2)
		}
	}
	function loop(arr: MeshBasicMaterial[], t: number, baseColor: THREE.Color) {
		for (var i = 0; i < arr.length; i++) {
			const mat = arr[i]
			const r = (t + i) % arr.length
			mat.color.copy(baseColor).multiplyScalar(r / arr.length)
		}
	}
	function inout(arr: MeshBasicMaterial[], t: number, baseColor: THREE.Color) {
		for (var i = 0; i < arr.length; i++) {
			const mat = arr[i]
			const j = Math.round(t % arr.length)
			const r = i == j || arr.length - i - 1 == j
			mat.color.copy(baseColor).multiplyScalar(Number(r))
		}
	}
	function idk(arr: MeshBasicMaterial[], t: number, baseColor: THREE.Color) {
		for (let i = 0; i < arr.length; i++) {
			const mat = arr[i]
			const r = ((t * 4 + i) % arr.length) / arr.length / 2
			const b = t % 2
			mat.color.copy(baseColor)
			mat.color.r *= r
			mat.color.b *= b / 2
		}
	}
}


