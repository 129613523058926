import { MyLoaders, RPMAvatar } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { findObjects, testName, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import InstancedAvatarExtension from "~/space/lib/gltfExtensions/InstancedAvatarExtension";

export function setupStadiumNpcs() {

	const space = getSpace()
	const engine = getEngine()

	space.loader.onceAlready("loaded", async () => {

		const spotsAll = findObjects(engine.scene, testUserData("SocketID", "stadium"))
		const spots1: THREE.Object3D[] = []
		const spots2: THREE.Object3D[] = []
		spotsAll.forEach((s) => { Math.random() > 0.5 ? spots1.push(s) : spots2.push(s) })
		const loader = MyLoaders.gltfLoader

		// males
		{
			const animtionGltf = await loader.loadAsync(RPMAvatar.defaultMaleConfig.animationsPath!) as GLTF
			const modelGltf = await loader.loadAsync(RPMAvatar.defaultMaleConfig.modelPath) as GLTF
			modelGltf.animations = animtionGltf.animations

			const model = modelGltf.scene.getObjectByName('Wolf3D_Avatar') as THREE.SkinnedMesh
			const clip1 = THREE.AnimationClip.findByName(modelGltf.animations, "sitting")
			const clip2 = THREE.AnimationClip.findByName(modelGltf.animations, "cheering")
			const clip3 = THREE.AnimationClip.findByName(modelGltf.animations, "clapping")
			const ex = new InstancedAvatarExtension(model, [clip1, clip2, clip3], spots1)
		}

		// females
		{
			const animtionGltf = await loader.loadAsync(RPMAvatar.defaultFemaleConfig.animationsPath!) as GLTF
			const modelGltf = await loader.loadAsync(RPMAvatar.defaultFemaleConfig.modelPath) as GLTF
			modelGltf.animations = animtionGltf.animations
			RPMAvatar.fixV2FeamelEyes(modelGltf)

			const model = modelGltf.scene.getObjectByName('Wolf3D_Avatar') as THREE.SkinnedMesh
			const clip1 = THREE.AnimationClip.findByName(modelGltf.animations, "sitting")
			const clip2 = THREE.AnimationClip.findByName(modelGltf.animations, "cheering")
			const clip3 = THREE.AnimationClip.findByName(modelGltf.animations, "clapping")
			const ex = new InstancedAvatarExtension(model, [clip1, clip2, clip3], spots2)
		}
	})
}
