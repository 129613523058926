<template>
	<div v-if="isMounted" id="boatHint" @click="leave()">{{ exitBoatText }}</div>
</template>

<script lang="ts">
import Engine, { EngineUtils, HighlightCylinder } from "@ravespaceio/rave-engine";
import Vue from 'vue'
import * as THREE from "three"
import { getSpace } from "~/space/space";
import { BlockingHintCollider, HintCollider } from "~/space/lib/manager/PCISystem";
import { getEngine } from "~/space/engine";


export default {
	data() {
		return {
			isMounted: false,
			exitBoatText: "Press F to exit boat",
		}
	},
	mounted() {
		const space = getSpace()
		const engine = getEngine()
		const hc = new HighlightCylinder()
		engine.scene.add(hc)
		hc.scale.set(0.7, 0.7, 0.7)
		hc.position.set(11.36, -0.6, 44.3)
		engine.debuggui.biggui.addToSceneFolder(hc)

		if (space.browser.isMobile) {
			this.exitBoatText = "Click to exit boat"
		}


		space.pciSystem.add(new BlockingHintCollider(new THREE.Vector3(11.36, -0.6, 44.3), 1.5, { title: "Enter Boat" }, {
			onInteract() {
				space.eventbus.emit("toggleBoatMount", "enter")
				setTimeout(() => {
					engine.inputManager.lockCursor()
					engine.inputManager.unBlockInput()
				})
			}
		}))

		space.eventbus.on("toggleBoatMount", (state) => {
			if (state == "enter") {
				this.isMounted = true
			}
			if (state == "leave") {
				this.isMounted = false
			}
		})

		document.addEventListener("keydown", (e) => {
			if (e.key == "f" || e.key == "F") {
				if (this.isMounted) {
					space.eventbus.emit("toggleBoatMount", "leave")
				}
			}
		})
	},
	methods: {
		leave() {
			const space = getSpace()
			space.eventbus.emit("toggleBoatMount", "leave")
		}
	}
}
</script>

<style scoped lang="scss">
#boatHint {
	position: fixed;
	top: 20px;
	left: calc(50% - 80px);
	text-align: center;
	width: 160px;
	padding: 8px;
	font-size: 14px;
	color: white;
	font-weight: 700;
	z-index: 20;
	background: rgba(0, 0, 0, 0.8);
	border: 1px solid white;
	padding: 5px 10px;
}
</style>
