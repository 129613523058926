import { MyLoaders, RPMAvatar, InstancedSkinnedMesh } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { findObjects, testName, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { getEngine } from "~/space/engine";


export default class InstancedAvatarExtension {

	public readonly group = new THREE.Group()

	constructor(public readonly skinnedMesh: THREE.SkinnedMesh, clips: THREE.AnimationClip[], spots: THREE.Object3D[]) {

		const engine = getEngine()

		const count = spots.length
		const imesh = new InstancedSkinnedMesh(skinnedMesh.geometry, skinnedMesh.material as THREE.Material, count)
		imesh.bind(skinnedMesh.skeleton, skinnedMesh.bindMatrix)
		imesh.frustumCulled = false
		skinnedMesh.visible = false

		const mixer = new THREE.AnimationMixer(skinnedMesh)
		const actions: THREE.AnimationAction[] = []
		for (const clip of clips) {
			const action = mixer.clipAction(clip).play()
			action.loop = THREE.LoopRepeat
			actions.push(action)
		}

		const variance: { action: THREE.AnimationAction, offset: number }[] = []
		for (let i = 0; i < count; i++) {
			const action = actions[Math.floor(Math.random() * clips.length)]
			variance.push({
				action,
				offset: action.getClip().duration * Math.random()
			})
			const e = spots[i]
			skinnedMesh.rotation.copy(e.rotation)
			skinnedMesh.position.copy(e.position)
			skinnedMesh.position.y -= 0.5
			skinnedMesh.updateMatrix()
			imesh.setMatrixAt(i, skinnedMesh.matrix)
			// imesh.setColorAt(i, new THREE.Color(`hsl(${Math.random() * 360}, 100%, 50%)`))
		}

		this.group.position.set(0, 0, 0)
		this.group.add(imesh)
		this.group.name = "InstancedAvatarExtension"
		engine.scene.add(this.group)

		// loop
		engine.loop.register((dt) => {
			const time = performance.now() / 1000

			for (let i = 0; i < spots.length; i++) {
				const v = variance[i]
				const t = (v.offset + time)
				mixer.setTime(t)

				for (const a of actions) a.setEffectiveWeight(0)
				v.action.setEffectiveWeight(1)

				skinnedMesh.skeleton.bones.forEach((b) => { b.updateMatrixWorld() })
				imesh.setBonesAt(i, skinnedMesh.skeleton)
			}

			imesh.instanceMatrix.needsUpdate = true
			if (imesh.skeleton && imesh.skeleton.boneTexture) {
				imesh.skeleton.boneTexture.needsUpdate = true
			}
		})
	}
}
