import Engine from "@ravespaceio/rave-engine";
import * as THREE from 'three';
import { Material, Sphere } from 'cannon-es'
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import SphereCollider from "@ravespaceio/rave-engine/build/engine/src/engine/physics/old/SphereCollider";

export function setupBalls(engine: Engine, initialoffset: { x: number, y: number, z: number } = { x: 0, y: 0, z: 0 }, physicsMaterial?: Material) {

	const meshMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff', name: 'ballMaterial' });

	const space = getSpace()
	meshMaterial.map = getMatMap(space.loader, "/rspSpace/textures/ball.jpg", { encoding: THREE.sRGBEncoding });
	meshMaterial.needsUpdate = true;

	const ballMesh = new THREE.Mesh(new THREE.SphereGeometry(0.15), meshMaterial);
	ballMesh.name = `ball`;
	// ballMesh.position.set(-12 + (Math.random() * i), 2, 12 + (Math.random() * i))
	ballMesh.position.set(-21.68 - initialoffset.x, 3.12, -28 - initialoffset.z);
	const ballCollider = new SphereCollider(engine, { mass: 1, material: physicsMaterial });
	ballMesh.add(ballCollider);
	ballCollider._collider!.removeShape(ballCollider._collider!.shapes[0]);
	ballCollider._collider!.addShape(new Sphere(0.2));
	ballCollider.renderWireframe();
	// @ts-ignore
	ballMesh.collider.name = 'ball';
	engine.scene.add(ballMesh);



}

