import { VolumetricLightMaterial } from "@ravespaceio/rave-engine"
import { Object3D, Group, Vector3, MeshBasicMaterial, ConeGeometry, Mesh } from "three"
import * as THREE from "three"



export default class LaserArrayC extends Object3D {

	public static laserBaseModel: Mesh
	public static helper: Mesh = new Mesh(new THREE.SphereGeometry(0.1), new MeshBasicMaterial({ color: "green" }))

	private lightArr: Object3D[][]
	private lightStates: number[][]

	public sequenzState: number = 0

	constructor() {
		super()
		this._initConeArray()
	}


	static CreateLightLaser(): Mesh {
		if (!LaserArrayC.laserBaseModel) {
			// const mat = new MeshBasicMaterial({
			// 	color: new THREE.Color(0xF32C6B),
			// 	transparent: true,
			// 	opacity: 0.3
			// })
			const mat = new VolumetricLightMaterial(new Vector3(23.676, -1, 25.42))

			const mesh = new Mesh(new THREE.CylinderGeometry(0.15, 0.15, 6), mat)
			mesh.geometry.translate(0, 3, 0)
			mesh.geometry.scale(1, 1, 1) // cone length
			// m.geometry.rotateX(-90)
			LaserArrayC.laserBaseModel = mesh
		}

		const lightCone = LaserArrayC.laserBaseModel.clone()
		return lightCone
	}

	private _initConeArray() {
		const amountX = 6
		const amountZ = 6
		const scale = 1.5

		// cones
		this.lightArr = []
		for (var x = 0; x < amountX; x++) {
			this.lightArr[x] = []
			for (var z = 0; z < amountZ; z++) {
				const l = LaserArrayC.CreateLightLaser()
				l.position.set(x - (amountX - 1) / 2, 0, z - (amountZ - 1) / 2)
				l.position.multiplyScalar(scale)
				this.lightArr[x][z] = l
				this.add(l)

				// const h = LightShowC.helper.clone()
				// this.group.add(h)
				// h.position.copy(l.position)
			}
		}

		// state/weights
		this.lightStates = []
		for (x = 0; x < this.lightArr.length; x++) {
			this.lightStates[x] = []
			for (z = 0; z < this.lightArr[x].length; z++) {
				this.lightStates[x][z] = 0
			}
		}
	}

	public update(dt: number) {
		this._resetState()
		if (this.sequenzState == 1) {
			this._inOut(4)
		} else {
			const t = performance.now() / 1000 % 4
			if (t < 2)
				this._lineLoop("x", 4)
			else if (t < 4)
				this._lineLoop("z", 4)
		}
		this._applyState()
	}

	private _loopStates(cb: (x: number, z: number) => void) {
		for (var x = 0; x < this.lightStates.length; x++) {
			for (var z = 0; z < this.lightStates[x].length; z++) {
				cb(x, z)
			}
		}
	}

	private _applyState() {
		this._loopStates((x, z) => {
			const l = this.lightArr[x][z]
			l.visible = this.lightStates[x][z] == 1
		})
	}

	private _resetState() {
		this._loopStates((x, z) => {
			this.lightStates[x][z] = 0
		})
	}

	private _lineLoop(dir: "x" | "z", speed: number = 1) {
		const t = Date.now() / 1000 * speed
		const l = dir == "x" ? this.lightArr.length : this.lightArr[0].length
		const i = Math.round(t % l)

		this._loopStates((x, z) => {
			const on = dir == "x" ? (i == x) : (i == z)
			if (on) this.lightStates[x][z] = 1
		})
	}

	private _inOut(speed: number = 1) {
		const t = Date.now() / 1000 * speed
		const l = this.lightArr.length
		const i = Math.round(t % l)

		this._loopStates((x, z) => {
			const onX = (x >= i && x <= l - i - 1) && (i == z || i == l - z - 1)
			const onZ = (z >= i && z <= l - i - 1) && (i == x || i == l - x - 1)
			const on = onX || onZ
			if (on) this.lightStates[x][z] = 1
		})
	}

	public getMaterial(): VolumetricLightMaterial {
		return LaserArrayC.laserBaseModel.material as VolumetricLightMaterial
	}
}
