<template>
	<SpaceModal :show=showBarModal @closed="showBarModal = false">
		<div class="content">
			<div class="drink-wrapper">
				<div v-for="drink of drinks" :key="drink.drinkId" class="drink" :class="activeDrinkID == drink.drinkId ? 'active' : ''" @click="activeDrinkID = drink.drinkId;">
					<div class="bg"><img :src="drink.img"></div>
				</div>
			</div>

			<template v-if="activeDrink">
				<h3 style="margin: 40px 0 8px 0px;">{{ activeDrink.name }}</h3>
				<p style="margin: 0 0 30px;">{{ activeDrink.text }}</p>
			</template>

			<div v-if="$gamepadActive" class="bottom">
				<div class="btn btn--controller" style="background:#2189FF;">Press <img src="/SpaceShared/img/buttons/a.png"> to drink</div>
			</div>
			<button v-else class="btn" @click="selectDrink()">
				<span class="btn-text">Drink now</span>
			</button>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { getEngine } from '~/space/engine'
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space';

const spaceStore = useSpaceStore()
const space = getSpace()
const engine = getEngine()
const drinks = [
	{
		"drinkId": "mate",
		"name": "Lemonade",
		"text": "Makes you active",
		"img": space.project.publicPath + "/img/bar/mate.png",
		"duration": 20
	}, {
		"drinkId": "beer",
		"name": "Smoothie",
		"text": "Makes you dizzy",
		"img": space.project.publicPath + "/img/bar/smoothie.png",
		"duration": 20
	}, {
		"drinkId": "pizza",
		"name": "Saftig lecker",
		"text": "Soo delicious",
		"img": space.project.publicPath + "/img/bar/pizza.png",
		"duration": 20
	}
]


const { showBarModal } = storeToRefs(spaceStore)
const activeDrinkID = ref("" as string)

const activeDrink = computed(() => {
	return drinks.find(drink => drink.drinkId == activeDrinkID.value)
})

engine.inputManager.gamepad.on("buttonup", (btn) => {
	if (!showBarModal.value) return;

	if (btn == "A") selectDrink()
	var i = drinks.findIndex(d => d.drinkId == activeDrinkID.value)
	if (i == -1) return;
	i += drinks.length // damit von 0 nicht auf -1 geht
	if (btn == "Left") {
		activeDrinkID.value = drinks[(i - 1) % drinks.length].drinkId
	}
	else if (btn == "Right") {
		activeDrinkID.value = drinks[(i + 1) % drinks.length].drinkId
	}
})

let tid: any
function selectDrink() {
	const drink = activeDrink.value!!
	showBarModal.value = false
	spaceStore.drink.drinkId = drink.drinkId
	spaceStore.drink.name = drink.name
	spaceStore.drink.img = drink.img
	spaceStore.drink.duration = drink.duration

	clearTimeout(tid)
	tid = setTimeout(() => {
		spaceStore.drink.drinkId = ""
		spaceStore.drink.name = ""
		spaceStore.drink.img = ""
		spaceStore.drink.duration = 0
	}, drink.duration * 1000)
}

</script>

<style scoped lang="scss">
@import "assets/css/variables";
@import "assets/css/space";


.drink-wrapper {
	@extend .flex-center;
	flex-wrap: wrap;
	margin-bottom: 20px
}


.drink {
	@extend .flex-center;
	flex-direction: column;
	opacity: 0.3;
	transition: ease 0.3s;
	cursor: pointer;
	width: 130px;
	margin: 20px;

	.bg {
		width: 120px;
		background: white;
		opacity: 0.7;
		border-radius: 100%;
		height: 120px;
		position: relative;
		transition: ease 0.3s;
		transform: scale(0.9) translateY(27px);

		img {
			width: 90px !important;
			height: auto !important;
			position: absolute;
			max-width: inherit !important;
			max-height: inherit !important;
			border-radius: 0;
			left: 14px;
			top: -120px;
			transition: ease 0.8s;
			transform: scale(0.6) translateY(22px);
			filter: grayscale(1);
		}
	}

	.btn {
		margin-top: 30px;
	}

	h3 {
		color: black;
		text-align: center;
		margin-top: 30px;
		font-family: "sans";
	}

	p {
		text-align: center;
		color: black;
		opacity: 0;
		transition: ease 0.3s;
		margin: 0 !important;
	}

	&.active {
		opacity: 1 !important;
		pointer-events: none;

		.bg {
			opacity: 1;
			transform: scale(1);

			img {
				transform: scale(1) rotate(-8deg) translate(-2px, -6px);
				filter: grayscale(0);
			}
		}

		p {
			opacity: 1;
			transform: translateY(5px);
		}
	}
}

.btn:hover {
	background: $secondary !important;
	color: white !important;
}
</style>
