import Effect from "./Effect";
import { Pass } from 'three/examples/jsm/postprocessing/Pass.js';

/**
 * Controll and animat properties + post processing shaders
 *
 * How to have multiple effects controlling the same propertie?
 * manage from usage? have a static instance controller managing the blending mode?
 * value modification can be a value, offset, scale, ..
 */
export default class EffectManager {

	private readonly effects: { [name: string]: Effect } = {}

	public update(dt: number) {
		for (const effect of Object.values(this.effects)) {
			effect.update(dt)
		}
	}

	public activateEffect(drinkID: string) {
		if (this.isAnyActive()) return;
		const effect = this.effects[drinkID]
		if (!effect) return;
		effect.fadeIn()
	}

	public isAnyActive(): boolean {
		return !!Object.values(this.effects).find((e) => {
			return e.isRunning()
		})
	}

	public getEffect(effectID: string): Effect | undefined {
		const effect = this.effects[effectID]
		if (!effect) return;
		return effect
	}

	public addEffect(effectID: string, effect: Effect) {
		this.effects[effectID] = effect
	}
}
