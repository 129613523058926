<template>
	<div class="row">
		<div class="l">Name</div>
		<div class="r">
			<div class="wrap">
				<input v-model="userNameModel" maxlength="14" class="input" type="text" @focus="userNameFocus = true" @blur="userNameFocus = false">
				<div class="tgl auto" @click="saveName()">Change</div>
			</div>
		</div>
	</div>
</template>


<script lang="ts" setup>
import { useSpaceStore } from '~/store/space';
import { onlyLetters, validateText } from '~/assets/js/text';


const playerStore = useSpaceStore()
const userNameModel = ref("")
const userNameFocus = ref(false)

watch(() => playerStore.playerName, (state) => {
	userNameModel.value = state
}, { immediate: true })

function saveName() {
	const name = userNameModel.value
	if (name.trim() == "") return;
	if (!validateText(name)) return;
	if (!onlyLetters(name)) return;
	playerStore.playerName = name
}
</script>

<style lang="scss"></style>
