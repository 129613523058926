<template>
	<SpaceModal :show=showTeleportModal @closed="showTeleportModal = false">
		<div class="content">
			<h4>Teleport</h4>
			<div class="wrapper">
				<div v-for="spawn in spawns" :key="spawn" class="btn" @click="spawnAt(spawn)">{{ spawn }}</div>
			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';
import { storeToRefs } from 'pinia';
import { canUseActionKey } from '~/space/lib/manager/PCISystem';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';

const space = getSpace()
const spaceStore = useSpaceStore()
const { showTeleportModal } = storeToRefs(spaceStore)

const spawns = ref([] as string[])
space.loader.on("loaded", () => {
	setTimeout(() => {
		spawns.value = space.spawnManager.getSpawnList()
	}, 1000)
})

space.eventbinder.bindDocument("keyup", (e) => {
	if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
	if ((e.key == "t" || e.key == "T") && canUseActionKey()) {
		showTeleportModal.value = !showTeleportModal.value
	}
});

function spawnAt(spawn: string) {
	space.spawnManager.spawnAt(spawn);
	showTeleportModal.value = false
}
</script>

<style scoped lang="scss">
.box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.content {
		.logo {
			width: 150px !important;
			height: auto;
			margin-bottom: 20px;
		}

		h4 {
			color: white;
			margin-bottom: 12px;
			margin-top: 20px;
			font-weight: 600;
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: 0.4px;
			text-align: center;
		}

		.wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.btn {
				margin: 6px;
				padding: 8px 16px;
				text-transform: capitalize;
			}
		}
	}
}

.disabled {
	color: grey
}
</style>
