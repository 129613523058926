import { EngineUtils } from "@ravespaceio/rave-engine";
import * as THREE from "three";
import { getSpace } from "~/space/space";


export function setupStageScreen() {

	const space = getSpace()
	const screenMat = new THREE.MeshBasicMaterial()
	let urlCMS: undefined; // example

	space.loader.getLoadingPromise("stadium").then((gltf) => {
		const screens = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR") as THREE.Mesh[]
		let screensObj: THREE.Mesh[] = []
		screens.forEach((screen) => {
			// @ts-ignore
			if (screen.userData.CMSR == "stage_screens" && screen.material.name == "Screen") {
				screensObj.push(screen);
			}
		});
		if (urlCMS !== undefined) {
			// change to a different placeholder from the cms
		} else {
			// default placeholder from local file
			space.screenManager.addScreen(100, screensObj, screenMat, "useDefaultVideo");
		}
	})
}
