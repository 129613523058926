import { Vector3, Object3D, Euler, Quaternion, Vector2 } from "three";
import MovementC from "@ravespaceio/rave-engine/build/engine/src/engine/player/MovementC";

import { transformInputSpace } from "./helpers";
import { lerpVectors } from "@ravespaceio/rave-engine/build/engine/src/utils/math";
import BoatObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/BoatObject";
import BoatEmpty from "./BoatEmpty";


/**
 * Get constructor options type
 */


// for the accelaration vector
const tempVec = new Vector2();
const tempVec2 = new Vector3();
const zeroVector = new Vector3(0, 0, 0);
/**
 * Boat entity class. It moves a collider, it calculates the velocity and assign it to the collider. The velocity is base on the provide input
 * It copies the direction of the velocity vector to the object mesh. I am not copying the rotation of the collider.
 */

export class BoatEntity extends BoatEmpty {

	private _yAxis = new Vector3(0, 1, 0);
	private _zAxis = new Vector3(0, 0, 1);
	private _rotationalVector: Vector3 = new Vector3();
	public inputPlayer!: MovementC;
	public movingDirection: number = 1;
	// public events: any[] = [];
	constructor(object: BoatObject) {
		super(object);
		this.name = 'Boat_Entity'
	}

	onEntityEnter(): void {
		this._desiredRotation = this.object.rotation.y;
		// this.events.push(document.addEventListener('keydown', (e) => { this._checkDirection(e.key, -1) }))
		// this.events.push(document.addEventListener('keyup', (e) => { this._checkDirection(e.key, 1) }))
	}

	onEntityExit(): void {
		this._resetVelocity();
		// document.removeEventListener('keydown', this.events[0]);
		// document.removeEventListener('keyup', this.events[1]);
		// this.events = []

	}

	update(dt: any): void {
		if (this.inputPlayer) this.inputPlayer.update(dt)
	}

	fixUpdate(dt: number): void {
		if (!this.object) return;

		this.calculateVelocity(this._getObjectVelocity(), dt)
		this._rotatePlayer();
	}


	private _getObjectVelocity() {
		return this.object.stats.velocity;
	}

	private _getSpeed() {
		return this._getObjectVelocity().lengthSq()
	}

	private _checkDirection(key: string, val: number) {
		if (key === 's' || key === 'S' || key === 'ArrowDown') {
			this.movingDirection = val
		}
	}


	private _getMaxAcceleration() {
		return this.object.stats.maxAcceleration;
	}

	calculateVelocity(outVector: Vector3, dt: number) {
		const acceleration = this.inputPlayer.getInputDirection(tempVec);
		// assuming object is align with z axis
		const dir = this._zAxis;

		// get only z compoent of the input to use as accelerator
		this._acceleration.set(this._acceleration.x, this._acceleration.y, acceleration.y * this._getMaxAcceleration() * dt);
		const currentSpeed = this._getSpeed() * this.movingDirection
		// reverse needs to take into acount reverse without accelerating
		if (currentSpeed > 0) {
			this._desiredRotation -= this.inputPlayer.getLeftRightState() * dt * (this.object.stats.angularVelocity / 10);
		} else if (currentSpeed < 0) {
			this._desiredRotation += this.inputPlayer.getLeftRightState() * dt * (this.object.stats.angularVelocity / 10);
		}

		// rotate forward vector
		// its the direction of the movement
		this._getForwardDirection().copy(this._zAxis).applyAxisAngle(this._yAxis, this._desiredRotation);
		// add acceleration to the velocity
		this._desiredVelocity.add(this._acceleration);
		// friction
		this._desiredVelocity.multiplyScalar(0.995);
		// clamp to max speed
		this._desiredVelocity.clampLength(-this.object.stats.maxSpeed / 2, this.object.stats.maxSpeed);

		transformInputSpace(this._getForwardDirection(), this._getRightDirection(), this._desiredVelocity, outVector);
	}



	private _getRightDirection() {
		return tempVec2.copy(this._getForwardDirection()).applyAxisAngle(new Vector3(0, 1, 0), Math.PI / 2);
	}


	private _getForwardDirection() {
		return this.object.stats.forwardDirection;
	}



	private _rotatePlayer() {
		// more natural movement
		this._currentRotation.setFromEuler(this.object.rotation);
		this._rotationalVector.set(0, this._desiredRotation, 0)
		lerpVectors(this._currentRotation, this._rotationalVector, 0.1, this._currentRotation);
		this.object.rotation.setFromVector3(this._currentRotation);
	}

	private _resetVelocity() {
		this._desiredVelocity.copy(zeroVector);
		this._acceleration.copy(zeroVector);
	}
}
