<template>
	<div class="voicechat-prompt">
		<Transition name="fade">
			<div v-show="vsMsg" class="inner">
				<p class="title">Voicechat</p>
				<p>{{ vsMsg }}</p>
			</div>
		</Transition>

		<Transition name="fade">
			<div v-show="requestedPlayerName" class="inner">
				<p>{{ requestedPlayerName }} wants to join your voicechat group</p>
				<div class="wrap">
					<div class="key" @click="respondVoiceRequest(true)"><span v-show="!$isMobile">Y</span>Yes</div>
					<div class="key" @click="respondVoiceRequest(false)"><span v-show="!$isMobile">N</span>No</div>
				</div>
			</div>
		</Transition>
	</div>
</template>


<script setup lang="ts">
import { VoiceChatResponseTypes } from "@ravespaceio/rave-engine/build/engineserver-common/src/MultiplayerMessages/VoiceChatResponseTypes";
import { getEngine } from "~/space/engine";
import { useVoiceGroupStore } from "~/store/agora/voicegroups.js";
import { getSpace } from "~/space/space";
import { useSpaceStore } from "~/store/space";
import { canUseActionKey } from "~/space/lib/manager/PCISystem";

const vsMsg = ref("")
const requestedPlayerName = ref("")

const space = getSpace()
const engine = getEngine()
const vgStore = useVoiceGroupStore();
const voicehandle = engine.multiplayer!.voiceChatVgHandler;
const spaceStore = useSpaceStore()

watch(() => vgStore.message, (message) => {
	vsMsg.value = message;
})
watch(() => vgStore.requestedName, (requestName) => {
	requestedPlayerName.value = requestName;
})

const voiceMobilebtn = document.getElementById("voicechat--btn");
voiceMobilebtn?.addEventListener("click", () => {
	inviteRaycast();
});

space.eventbinder.bindDocument("keyup", (e) => {
	if ((e.key == "v" || e.key == "V") && canUseActionKey()) {
		inviteRaycast();
	}
});


async function inviteRaycast() {
	spaceStore.showPointerForFiveSec()
	const otherPlayer = engine.multiplayer!.raycastHandler.raycastPlayers();

	if (!otherPlayer) {
		if (voicehandle.invitationPending) return;
		if (space.browser.isMobile) {
			showMsgForSomeTime(`Look at a player and try again.`);
		} else {
			showMsgForSomeTime(`Look at a player and try again.`);
		}
		return;
	}
	spaceStore.showPointer = false
	if (voicehandle.unLockPointerLockMic) {
		engine.inputManager.unLockCursor();
	}
	const res = await voicehandle.getMicPermission();
	if (res) {
		voicehandle.unLockPointerLockMic = false;
		if (voicehandle.invitationPending) {
			voicehandle.cancelInvite();
		} else {
			voicehandle.invite(otherPlayer.playerId)
		}
	} else {
		//  mic error message
		voicehandle.doDeclineInvite({ otherPlayerId: engine.multiplayer!.localOnlinePlayerInstance.playerId, responseCode: VoiceChatResponseTypes.mic_permit_local });
	}
};


let tid
function showMsgForSomeTime(m: string) {
	vsMsg.value = m;
	clearTimeout(tid)
	tid = setTimeout(() => {
		if (vsMsg.value === m) vsMsg.value = "";
	}, 5000);
}


function respondVoiceRequest(yes: boolean) {
	console.log("respondVoiceRequest", yes);
	vgStore.respondKeyListener({ key: yes ? "y" : "n" });
}

</script>


<style scoped lang="scss">
@import "assets/css/variables";

.voicechat-prompt {
	.inner {
		position: absolute;
		bottom: 20px;
		padding: 20px;
		@extend .box;
		width: 250px;
		left: calc(50% - 125px);
		z-index: 4;

		p {
			text-align: center;
			color: $main;
		}

		p.title {
			font-weight: 700;

		}

		.wrap {
			@extend .flex-center;
			margin-top: 10px;
			color: $main;

			.key {
				margin: 5px 9px;
				font-size: 14px;
				font-weight: 700;

				span {
					width: 20px;
					margin-bottom: 4px;
					color: $dark;
					height: 20px;
					background: $main;
					@extend .flex-center;
					font-size: 14px;
					font-weight: 700;
					border-radius: 5px;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.voicechat-prompt {
		.inner {
			bottom: 140px;
		}
	}
}
</style>
