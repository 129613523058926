import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"

export function setupBoatRace() {


	// 10 m breit also 5 r/l collider platzieren
	const space = getSpace()
	const engine = getEngine()

	space.loader.on("loaded", async () => {

		const spots = engine.scene

	})
	//
}
