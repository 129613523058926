<template>
	<Transition name="fade">
		<div v-if="show" class="modal">
			<div class="inner" @click.self="close()">
				<div class=" box">
					<div v-if="$gamepadActive" class="close btn--controller-close" @click="close()">
						<img src="/SpaceShared/img/buttons/b.png"> Close
					</div>
					<div v-else class="close" @click="close()">
						<IconClose />
					</div>
					<slot></slot>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import Vue from 'vue'
import * as THREE from "three"
import { getSpace } from '~~/src/space/space'
import { blockForFrontend, unblockFromFrontend } from '~/space/lib/manager/PCISystem';


/**
 * Usage example
 * <SpaceModal :show=show @closed="show = false">
 * 	<p>my modal text</p>
 * </SpaceModal>
 * */

const props = defineProps({
	show: {
		type: Boolean,
		required: true
	},
})

const emits = defineEmits({
	closed() { return true }
})

watch(() => props.show, (state) => {
	if (!state) { close() } // damit zu geht egal wers zu gemacht hat (Modal, Modal Parent, Collider)
	state ? blockForFrontend() : unblockFromFrontend()
})

const space = getSpace()
function close() {
	space.pciSystem.stopInteracting() // TODO
	emits("closed")
}

</script>

<style scoped lang="scss">
@import "assets/css/variables";
@import "assets/css/space";


.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;

	.inner {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@extend .flex-center;
		background: $darkTransparent;

		.box {
			background: $darkTransparent;
			border: $stroke solid $main;
			box-sizing: border-box;
			border-radius: $radius;
			@extend .flex-center;
			flex-direction: column;
			padding: 30px 30px;
			position: relative;
			width: 750px;
			max-width: 90%;
			height: auto;
			max-height: 80vh;
			overflow: hidden;

			.logo {
				width: 100%
			}

			.content {
				@extend .flex-center;
				flex-direction: column;
				height: 100%;
				color: $bright;

				img,
				video {
					width: auto;
					height: auto;
					max-width: 300px;
					max-height: 210px;
					border-radius: 6px;
				}

				.img--holder {
					margin: 20px 0 12px;
				}

				h3 {
					font-size: 23px;
					margin-bottom: 6px;
				}

				.tag {
					font-size: 16px;
				}

				p {
					text-align: center;
					max-width: 500px;
					font-weight: 400;
					font-size: 15px;
					line-height: 110%;
					margin: 20px 0 30px 0;
				}

				.disclaimer {
					color: white !important;
					margin: 0;
					margin-top: 16px;
				}

				p.disclaimer {
					font-size: 10px;
					line-height: 12px;
					color: #000000;
					opacity: 0.54;
					margin-top: 6px;
				}

				h3 {
					font-weight: bold;
					font-size: 27px;
				}

				h4 {
					font-size: 21px;
					margin: 6px 0 12px;
				}
			}
		}
	}


}
</style>
