<template>
	<SpaceModal :show=show @closed="close()">
		<div v-if="artworkModal" class="content">
			<!--ArViewer3D v-if="artworkModal.placement" :model-info="modelInfo" :standalone="false" style="height: 400px; width: 400px;" /-->
			<img id="artwork" :src="artworkModal.img">

			<div class="details">
				<h2>{{ artworkModal.title }}</h2>
				<p>{{ artworkModal.text }}</p>
				<p>{{ artworkModal.text2 }}</p>
			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~~/src/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';


// const props = defineProps<{
// 	data: ArtworkType
// }>()
// watch(() => props.data, (data) => {
// 	showData(data)
// })


const space = getSpace()
const spaceStore = useSpaceStore()

const { artworkModal } = storeToRefs(spaceStore)
const show = computed(() => !!artworkModal.value)

const modelInfo = computed(() => {
	const data = artworkModal.value
	if (data && data.placement) {
		return {
			url: data.img,
			placement: data.placement,
			...(data.width && { widthInCm: data.width * 100 }),
			...(data.height && { heightInCm: data.height * 100 }),
		}
	}
})

function close() {
	artworkModal.value = undefined
}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";


.content {
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	#artwork {
		max-height: clamp(0px, 60dvh, 750px);
		max-width: 100%;
		margin-bottom: 20px;
	}

	.details {
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h2,
		p {
			color: white;
		}
	}

	.details>* {
		margin-top: 5px;
	}


}
</style>
