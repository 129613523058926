<template>
	<div class="row">

		<div class="l">Server</div>
		<div class="r">
			<div v-if="!mpStore.isAnyRoomOnline">
				<div class="tag" style="float:right;">No server avaiable</div>
			</div>

			<div v-else class="wrap server-wrap">
				<div class="dropdown">
					<button id="dropdownButton" @click="toggleDropdown">
						<div class="desc">
							{{ mpStore.roomId }}
						</div>
						<svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M0.357965 7.95897L8.1358 0.350186C8.61308 -0.116727 9.38692 -0.116727 9.8642 0.350186L17.642 7.95897C18.1193 8.42589 18.1193 9.1829 17.642 9.64981C17.1647 10.1167 16.3909 10.1167 15.9136 9.64981L9 2.88645L2.08637 9.64981C1.60909 10.1167 0.835252 10.1167 0.357965 9.64981C-0.119322 9.1829 -0.119322 8.42589 0.357965 7.95897Z" fill="#AC976E" />
						</svg>
					</button>
					<div id="dropdownInner" class="dropdown-content">
						<div class="top">
							<p>ServerID</p>
							<p>Users</p>
						</div>
						<div class="list">
							<div v-for="room of mpStore.rooms" :key="room.roomId" class="cat" :class="(room.clients >= room.maxClients) || room.roomId === mpStore.roomId ? 'disabled' : ''" @click="joinRoom(room.roomId)">
								<div class="p">
									{{ room.roomId }}
								</div>
								<div class="p">
									{{ room.clients }} /
									{{ room.maxClients }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script lang="ts" setup>
import { getEngine } from "~/space/engine";
import { useMultiplayerStore } from "~/store/multiplayer"
import { useSpaceStore } from "~/store/space";
import { joinSpecificRoom } from "~/space/lib/mechanic/multiplayer";
import { getSpace } from "~~/src/space/space";

const mpStore = useMultiplayerStore()
const spaceStore = useSpaceStore()
// whole menu is using v-if so no need for isActive indicator
// const props = defineProps<{
// 	isActive: boolean
// }>()
// const props = defineProps({
// 	isActive: {
// 		type: Boolean,
// 		required: true
// 	}
// })
// watch(() => props.isActive, (to, from) => {
// 	console.log("isActive", to, from);
// 	if (to) mpStore.fetchRoomsData()
// })

mpStore.fetchRoomsData()
const i = setInterval(() => {
	if (!spaceStore.showMenu) return;
	mpStore.fetchRoomsData()
}, 1000)

onUnmounted(() => {
	clearInterval(i)
})

function toggleDropdown() {
	document.getElementById("dropdownInner")?.classList.toggle("show");
	document.getElementById("dropdownButton")?.classList.toggle("active");
}

function joinRoom(id: string) {
	const space = getSpace();
	const engine = getEngine()
	if (!engine.multiplayer?.roomType) return;
	joinSpecificRoom(engine.multiplayer.roomType, id);

	// hide/close dropdowns
	const dropdowns = document.getElementsByClassName("dropdown-content");
	for (var i = 0; i < dropdowns.length; i++) {
		var openDropdown = dropdowns[i];
		openDropdown.classList.remove("show");
		document.getElementById("dropdownButton")?.classList.remove("active");
	}
}
</script>



<style lang="scss">
@import "assets/css/variables";

.server-wrap {

	.left,
	.right {
		.tgl {
			width: 40px;

			svg {
				* {
					fill: $dark;
				}
			}

			&:hover {
				svg {
					* {
						fill: $bright;
					}
				}
			}
		}
	}

	.left {
		.tgl {
			transform: rotate(180deg);
		}
	}

	.middle {
		margin: 0 10px;

		h3 {
			color: $dark;
			font-size: 20px;
			text-align: center;
		}

		p {
			color: $dark;
			font-size: 11px;
			font-weight: 400;
		}
	}
}

/* SERVER LIST */
/* Dropdown Button */
#dropdownButton {
	font-size: 16px;
	color: $main;
	background: rgba(10, 10, 10, 0.7);
	font-weight: 700;
	padding: 12px 18px;
	border-radius: 50px;
	cursor: pointer;
	display: inline-block;
	border: 1px solid $main;
	position: relative;
	text-decoration: none;
	text-align: left;
	width: auto;
	min-width: 180px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.desc {
		background: transparent !important;
		pointer-events: none;
		font-size: 14px;
		font-weight: 700;
		color: $main;
	}

	h4 {
		font-weight: 900;
		font-size: 17px;
		color: $main;
		pointer-events: none;
	}

	svg {
		transform: rotate(180deg);
		transition: ease 0.3s;
		margin-left: 12px;
		pointer-events: none;

		* {
			fill: $main;
		}
	}

	&:hover {
		background: $main;
		color: $dark;

		.desc {
			color: $dark;
		}

		h4 {
			color: black;
		}

		svg {
			* {
				fill: $dark;
			}
		}
	}

	&.active {
		svg {
			transform: rotate(0);
		}
	}
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	width: 250px;
	left: -40px;
	border-radius: 20px;
	top: -150px;
	height: 150px;
	overflow-y: auto;
	background: $dark;
	border: 1px solid $main;

	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 20px;
		border-bottom: 1px solid $main;
		padding-bottom: 10px;
		margin-bottom: 10px;
		font-size: 13px;

		p {
			color: $main;
			transform: translateY(1px);
		}

		p:nth-child(1) {
			width: 50%;
		}

		p:nth-child(2) {
			width: 50%;
		}
	}

	.list {
		.cat {
			.p {
				color: $main;
			}

			.p:nth-child(1) {
				width: 50%;
			}

			.p:nth-child(2) {
				width: 50%;
			}

			&:nth-child(odd) {
				background: #101010;
			}
		}
	}
}

.dropdown-content::-webkit-scrollbar {
	width: 2px;
}

.dropdown-content::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.dropdown-content .cat {
	color: $dark;
	padding: 7px 20px;
	border-bottom: 1px solid $dark;
	text-decoration: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
	cursor: pointer;

	.p {
		font-size: 15px;
		font-weight: 500;
	}

	&:hover {
		background: $main !important;
		color: $dark !important;

		.p {
			color: $dark;
		}

		* {
			color: $dark;
		}
	}
}

.dropdown-content a:last-child {
	border-bottom: 0;
}

.show {
	display: block;
}
</style>
