<template>
	<div class="row controls-row">
		<div class="l">Mouse</div>
		<div class="r">
			<div v-if="!$isMobile" class="wrap">
				<input id="check-lockmouse" v-model="usePointerlock" type="checkbox" name="check-lockmouse">
				<label for="check-lockmouse">Lock</label>
			</div>
			<div class="wrap">
				<input id="check-invertmouse" v-model="invertMouse" type="checkbox" name="check-invertmouse">
				<label for="check-invertmouse">Invert</label>
			</div>
		</div>
	</div>

	<div class="row controls-row">
		<div class="l">Controls</div>
		<div class="r">
			<div class="wrap">
				<input id="check-sprint" v-model="isAutoSprint" type="checkbox" name="check-sprint">
				<label for="check-sprint">Auto Sprint</label>
			</div>
			<div class="wrap">
				<input id="check-perspective" v-model="isFirstPerson" type="checkbox" name="check-perspective">
				<label for="check-perspective">First Person</label>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { CamControllersNames } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM';
import PlayerObject from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject';
import { getEngine } from '~/space/engine';



const usePointerlock = ref(false)
const isFirstPerson = ref(false)
const invertMouse = ref(false)
const isAutoSprint = ref()

const engine = getEngine();

getFirstPerson()
getInvertMouse()
getPointerlock()
getAutoSprint()

watch(usePointerlock, setPointerLock)
watch(isFirstPerson, setFirstPerson)
watch(invertMouse, setInvertMouse)
watch(isAutoSprint, toggleAutoSprint)

function getFirstPerson() {
	isFirstPerson.value = engine.player.camControllerM.isFirstPerson()
}
function setFirstPerson(fp: boolean) {
	// engine naming bug: ist nicht nur only, aber für den use case hier passt das ja
	// engine.player.camControllerM.setFirstPersonOnly()
	if (fp) engine.player.camControllerM.changeController(CamControllersNames.FIRST_CAM);
	else engine.player.camControllerM.changeController(CamControllersNames.ORBIT_CAMERA);
	isFirstPerson.value = fp
}

function getInvertMouse() {
	invertMouse.value = engine.inputManager.mouseInputTracker.invertMouse;
}
function setInvertMouse(v: boolean) {
	invertMouse.value = v
	engine.inputManager.mouseInputTracker.invertMouse = v;
}

function getPointerlock() {
	usePointerlock.value = engine.inputManager.mouseInputTracker.usePointerLock;
}
function setPointerLock(v: boolean) {
	engine.inputManager.mouseInputTracker.usePointerLock = v;
	usePointerlock.value = v
}

function getAutoSprint() {
	const player = engine.player.getPlayer<PlayerObject>()
	if (player) isAutoSprint.value = player.inputController.emulatedRunning
}
function toggleAutoSprint(v: boolean) {
	const player = engine.player.getPlayer<PlayerObject>()
	if (player) player.inputController.emulatedRunning = v
	isAutoSprint.value = v
}

</script>



