import Engine, { EngineUtils, MyLoaders, Painting } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import confetti from "canvas-confetti"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { PCICollider } from "~/space/lib/manager/PCISystem"
import { useGameStore } from "~/store/game"
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"

export function setupTreasurehunt() {

	const space = getSpace()
	const engine = getEngine()
	engine.userData.treasures = []

	setTimeout(() => {
		let treasure = localStorage.getItem("treasurehuntFinished")
		if (treasure) {
			engine.multiplayer!.badgeStateHandler.sendSetBadge("/rspSpace/img/badge/badge.png")
		} else {
			continueSetup()
		}
	}, 4000);

	const store = useGameStore()

	watch(() => store.treasureHuntState, (progress) => {
		if (progress > 0 && progress < 3) {
			engine.player.getPlayer<PlayerObject>().avatar.triggerAction("joy_jump", 1, false)
		}
		if (progress == 3) {
			localStorage.setItem("treasurehuntFinished", "true")
			engine.player.getPlayer<PlayerObject>().avatar.triggerAction("dance1", 1, true)
			engine.multiplayer!.badgeStateHandler.sendSetBadge("/rspSpace/img/badge/badge.png")
		}
	}, { immediate: true })


	function continueSetup() {
		const products = [
			{
				position: new THREE.Vector3(-41.16, 7.26, -58.6),
				id: 0,
				title: "Postcard 1",
				found: false,
				image: "/rspSpace/img/postcard.jpg",
				object: undefined
			}, {
				position: new THREE.Vector3(-40, 2.3, 72),
				id: 1,
				title: "Postcard 2",
				found: false,
				image: "/rspSpace/img/postcard.jpg",
				object: undefined
			}, {
				position: new THREE.Vector3(27, 2.95, -33.7),
				id: 2,
				title: "Postcard 3",
				found: false,
				image: "/rspSpace/img/postcard.jpg",
				object: undefined
			},
		]

		for (const product of products) {

			space.pciSystem.add(new PCICollider(product.position, 0.6, {
				onEnter: () => { onEnter(product) },
				onLeave: () => { onLeave(product) },
				onInteract: () => { onOpen(product) },
				onClose: () => { onClose(product) },
			}))

			const texture = MyLoaders.texLoader.load(product.image);
			texture.encoding = THREE.sRGBEncoding
			const material = new THREE.MeshLambertMaterial({ map: texture });
			const postcard = new THREE.Mesh(new THREE.PlaneGeometry(0.2, 0.13), material);
			postcard.material.side = THREE.DoubleSide;
			postcard.position.copy(product.position)
			postcard.rotation.x = 5
			postcard.position.y += 1.5
			engine.scene.add(postcard)
			postcard.matrixAutoUpdate = true
			product.object = postcard
			engine.loop.register((dt) => {
				if (!product.found) {
					postcard.rotation.z += dt / 4
					postcard.rotation.y += dt / 4
					postcard.rotation.x += dt / 4
				}
			})
		}


		const onEnter = (data) => {
			if (data.found == false) {
				space.eventbus.emit("showHint", data.title)
			}
		}
		const onLeave = (data) => {
			space.eventbus.emit("showHint", undefined)
		}
		const onOpen = (data) => {
			if (!engine.userData.treasures.includes(data.id)) {
				data.found = true
				store.treasureHuntState++
				engine.userData.treasures.push(data.id)
				if (data.object) data.object.visible = false
			}
			space.eventbus.emit("showHint", undefined)
			space.eventbus.emit("showTreasurehuntModal", data)
			engine.inputManager.unLockCursor()
			if (engine.userData.treasures.length == 3) {
				confetti({
					particleCount: 100,
					spread: 70,
					origin: { y: 0.6 }
				});
			}
		}
		const onClose = (data) => {
			engine.inputManager.lockCursor()
			space.eventbus.emit("showHint", undefined)
			space.eventbus.emit("showTreasurehuntModal", undefined)
		}
	}


}
