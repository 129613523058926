import { AvatarConfig_I, RPMAvatar } from "@ravespaceio/rave-engine"


export function getAvatarPreviewImg(modelUrl: string, callback: (prev: string) => void) {
	const params = {
		model: modelUrl,
		scene: "fullbody-posture-v1-transparent",
	}
	const http = new XMLHttpRequest()
	http.open("POST", "https://render.readyplayer.me/render")
	http.setRequestHeader("Content-type", "application/json")
	http.send(JSON.stringify(params))
	http.onload = () => {
		const response = JSON.parse(http.responseText)
		if (response.renders) {
			callback(response.renders[0])
		} else {
			callback("")
		}
	}
}

export function rpmRndScale(): number {
	return 1 + (Math.random() - 0.5) / 10
}


export type AvatarConfigWithoutPreview = {
	model: string,
	gender: "masculine" | "feminine"
	scale: number
}
export type AvatarConfig = AvatarConfigWithoutPreview & {
	preview: "loading" | string
}


// https://readyplayer.me/
// export const RPM_API_CONFIG = ".glb?textureSizeLimit=1024&meshLod=2&textureAtlas=1024&useDracoMeshCompression=true&morphTargets=none"
export const RPM_API_CONFIG = ".glb?quality=medium"
export const RPM_API_URL = "https://api.readyplayer.me/v1/avatars/"
export const RPM_PRE_AVATARS: AvatarConfig[] = [
	{ model: "/RaveEngine/avatar/male_model.glb", gender: "masculine", preview: "/RaveEngine/avatar/male_engine.png", scale: 1 },
	{ model: "/RaveEngine/avatar/female_model.glb", gender: "feminine", preview: "/RaveEngine/avatar/female_model.png", scale: 1 },
	{ model: RPM_API_URL + "63ee32a6d2a60a1bbcaffa1c" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/qvYv5lZBb.png", scale: 1 },
	{ model: RPM_API_URL + "646f791b61f4eb2f3456da5b" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/O4b6ih1ka.png", scale: 1 },
	{ model: RPM_API_URL + "6400f314ce7f75d51cda4133" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/TAnFJhXAb.png", scale: 1 },
	{ model: RPM_API_URL + "6400ec676fad80b65b0f0628" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/QlGt3wXoI.png", scale: 1 },
	{ model: RPM_API_URL + "645270530fc89d09fcdcd184" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/jGn457N3h.png", scale: 1 },
	{ model: RPM_API_URL + "630df3a5ce4e22ca81f47ed8" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/tTzXGcGxw.png", scale: 1 },
	{ model: RPM_API_URL + "646f7b3e61130ed224d9c024" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/8WSoJnp4m.png", scale: 1 },
	{ model: RPM_API_URL + "646f7b6431cbbc83675effb9" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/FGWkTae6U.png", scale: 1 },
	{ model: RPM_API_URL + "646f7ca1625b479a13870e88" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/OiVMY5Kum.png", scale: 1 },
	{ model: RPM_API_URL + "646f7ce361f4eb2f3456e69b" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/jSYhotyVZ.png", scale: 1 }
]

export function transformAvatarConfig(config: AvatarConfigWithoutPreview): AvatarConfig_I {
	return {
		modelPath: config.model,
		animationsPath: config.gender == "masculine" ? RPMAvatar.defaultMaleConfig.animationsPath : RPMAvatar.defaultFemaleConfig.animationsPath,
		scale: rpmRndScale()
	}
}
