<template>
	<div class="center">
		<div class="box">
			<div class="row">
				<p>Avatar</p>
				<div class="avatar" :style="'background-image: url(' + spaceStore.avatar.preview + ')'">
					<IconLoader v-if="spaceStore.avatar.preview == 'loading'">
					</IconLoader>
				</div>
				<div class="btn-avatar" @click="spaceStore.showAvatarGenerator = true">Change Avatar</div>
			</div>
			<div class="row">
				<p>Username</p>
				<div class="input">
					<form autocomplete="off" method="post" action="">
						<input autocomplete="false" name="hidden" type="text" style="display:none;">
						<input id="username" type="text" :placeholder="playerName" v-model="playerName" maxlength="15" autocomplete="new-password">
					</form>

				</div>
			</div>
			<!--div class="row">
				<p>Quality</p>
				<div class="w">
					<div :class="quality == 'low' ? 'active' : ''" @click="quality = 'low'">Low</div>
					<div :class="quality == 'mid' ? 'active' : ''" @click="quality = 'mid'">Fair</div>
					<div :class="quality == 'high' ? 'active' : ''" @click="quality = 'high'">High</div>
				</div>
			</div-->
		</div>
	</div>
</template>

<script setup lang="ts">
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';

const spaceStore = useSpaceStore()
const { quality, playerName } = storeToRefs(spaceStore)
</script>

<style scoped lang="scss">
@import "assets/css/variables";

.center {
	@extend .flex-center;
	flex-direction: column
}

.box {
	@extend .flex-center;
	flex-direction: column;
	color: $main;
	margin-top: 30px;
	width: 340px;
	max-width: 100%;
	background: rgba(8, 8, 8, 0.3);

	div {
		@extend .flex-center;
		flex-direction: column;
	}

	p {
		color: $main;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.input {
		width: calc(100% - 100px);
		border: none;
	}

	.row {
		border-bottom: 1px solid $lineColor;
		width: 100%;
		padding: 20px 0;

		&:last-child {
			border-bottom: none;
		}
	}

	.btn-avatar {
		width: auto;
		height: 30px;
		border: $stroke solid $main;
		background: transparent;
		color: $main;
		cursor: pointer;
		@extend .flex-center;
		border-radius: $radius;
		margin: 4px 8px;
		padding: 0 12px;
		font-size: 12px;
		font-weight: 600;

		&:hover {
			background: $main;
			color: $dark;
		}
	}

	.w {
		@extend .flex-center;
		width: 100%;
		flex-direction: row;

		div {
			width: 50px;
			height: 30px;
			border: $stroke solid $main;
			background: transparent;
			color: $main;
			cursor: pointer;
			@extend .flex-center;
			border-radius: $radius;
			margin: 4px 8px;
			padding: 0 0 2px 0;
			font-size: 12px;
			font-weight: 600;

			&:hover {
				opacity: 0.7;
			}

			&.active {
				background: $main;
				color: $dark;
			}
		}
	}
}

@media(max-height:700px) {
	#settings {
		.box {
			.row {
				padding: 10px 0 15px;
			}
		}
	}
}
</style>
