<template>
	<div id="treasure-progress" v-if="store.treasureHuntState > 0 && store.treasureHuntState < 3">
		<p>Treasurehunt</p>
		<div class="wrap" :class="'progress-' + store.treasureHuntState"><span></span><span></span><span></span></div>
	</div>

	<SpaceModal :show=show @closed="show = false">
		<div v-if="treasurehuntData" class="content">
			<img :src="treasurehuntData.image">
			<h1>{{ treasurehuntData.title }}</h1>
			<div v-if="progress < 3">
				<h4>You found {{ progress }} of 3 Postcards.</h4>
			</div>
			<div v-else class="win">
				<h3>You found all Postcards and won a special spot on our newsletter ;););););)</h3>
				<a href="http://eepurl.com/h_z3Iv" target="_blank" class="btn">Sign up</a>
			</div>
		</div>
	</SpaceModal>
</template>


<script lang="ts">
import Vue from 'vue'
import { getEngine } from '~/space/engine'
import { getSpace } from '~/space/space'
import { useGameStore } from '~/store/game'


export default {
	data() {
		return {
			treasurehuntData: undefined,
			progress: undefined,
			show: false,
			store: useGameStore()
		}
	},
	mounted() {
		const space = getSpace()
		const engine = getEngine()
		space.eventbus.on("showTreasurehuntModal", (data) => {
			if (data) {
				this.treasurehuntData = data
				this.progress = engine.userData.treasures.length
			}
			this.show = !this.show
		})
	}
}
</script>

<style scoped lang="scss">
@import "assets/css/variables";

.content {
	img {
		width: 330px !important;
		height: auto !important;
		max-height: inherit !important;
		max-width: 100% !important;
		position: relative !important;
		z-index: 4 !important;
		margin-bottom: 20px;
	}

	p {
		margin: 0 0 30px !important;
	}
}

.win {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;

	.btn {
		margin-top: 20px;
	}
}

#treasure-progress {
	width: 200px;
	height: auto;
	padding: 10px;
	position: fixed;
	left: calc(50% - 100px);
	top: 20px;
	z-index: 8;
	background: $mainTransparent;
	border-radius: $radius;

	text-align: center;
	@extend .flex-center;
	flex-direction: column;
	color: white;

	p {
		font-weight: 700;
		font-size: 12px;
		text-transform: uppercase;
	}

	svg {
		width: 30px;
		height: auto;
		margin-bottom: 6px;
	}

	.wrap {
		@extend .flex-center;
		margin-top: 4px;

		span {
			display: inline-block;
			width: 10px;
			height: 10px;
			border-radius: 100%;
			margin: 4px;
			opacity: 0.4;
			background: white;
		}

		&.progress-1 {
			span:nth-child(1) {
				opacity: 1;
			}
		}

		&.progress-2 {
			span:nth-child(-n+2) {
				opacity: 1;
			}
		}

		&.progress-3 {
			span:nth-child(-n+3) {
				opacity: 1;
			}
		}

		&.progress-4 {
			span:nth-child(-n+4) {
				opacity: 1;
			}
		}

		&.progress-5 {
			span:nth-child(-n+5) {
				opacity: 1;
			}
		}
	}
}
</style>
