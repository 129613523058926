import setupShowElements from "./showelements/main";
import { setupStage } from "./stage";
import { setupStadiumNpcs } from "./npcs";

export function setupStadium() {

	setupStage()
	setupShowElements()
	setupStadiumNpcs()
}
