import { Logging, LoggingArea, VideoScreenF } from '@ravespaceio/rave-engine';
import { Mesh, MeshBasicMaterial, VideoTexture, LinearFilter, sRGBEncoding } from 'three';


type ScreenInfo = {
	screenMesh: Mesh | Mesh[],
	screenMat: MeshBasicMaterial,
	imageUrl: string,
	videoElement: HTMLVideoElement,
}

export enum CustomUrlStrings { defaultVideo = 'useDefaultVideo' }

export default class ScreenManager {

	private _allowedUids: number[] = [];
	get allowedUids() { return this._allowedUids };
	private _castToAllScreensId = 1000; // if this id is passed to the manager, the video will be cast to all screens
	private _debugUid = 999; // when an id is pass to the manager but no screen is found, instead to show an error a html will show the incoming video. better for debugging. with this we know that the video arrive so cloudplayer is fine but maybe the screen is missing or was not added to the manager.
	private _defaultPlaceHolder = 2000;
	get defaultPlaceHolderId() { return this._defaultPlaceHolder }
	get debugUid() { return this._debugUid };
	public videoScreens: Map<number, (Mesh | Mesh[])> = new Map();
	public videoElements: Map<number, HTMLVideoElement> = new Map();
	public urlCMS: Map<number, string> = new Map();
	public screenMaterials: Map<number, MeshBasicMaterial> = new Map();
	// * notes* //
	/**
	 * I have seem through different projects and the way a texture its replaced differs a bit. Its almost the same but not quite, so I am leaving this function to be set up later by the engine.
	 * That is basically all that this class needs from the engine side.
	 */
	public replaceMaterial: ((screens: Mesh | Mesh[], imageUrl: string) => void) | undefined;

	constructor() {
		this.videoElements.set(this._debugUid, ScreenManager.createVideoHTML());
		this.videoElements.set(this._castToAllScreensId, ScreenManager.createVideoHTML());
		this.screenMaterials.set(this._castToAllScreensId, new MeshBasicMaterial());
		// custom stuff default placeholder for rsp
		const defaultPlaceHolderVideo = ScreenManager.createVideoHTML();
		defaultPlaceHolderVideo.src = "/rspSpace/textures/viz.mp4"
		defaultPlaceHolderVideo.loop = true;
		this.videoElements.set(this._defaultPlaceHolder, defaultPlaceHolderVideo);
	}

	public addScreen(uid: number, screen: Mesh | Mesh[], screenMaterial: MeshBasicMaterial, imageUrl: string) {
		this.videoScreens.set(uid, screen);
		this.screenMaterials.set(uid, screenMaterial);
		this.urlCMS.set(uid, imageUrl);
		this.castToAllScreenFromHTMLVideoElement(this.videoElements.get(this._defaultPlaceHolder)!);

	}

	public setAllowedUids(uids: number[]) {
		this._allowedUids = uids;
		this._setVideoElements();
		Logging.info(`ScreenManager setup complete, screenIds: ${this._allowedUids}`, LoggingArea.Space);
	}

	private _setVideoElements() {
		this.allowedUids.forEach(uid => {
			this.videoElements.set(uid, ScreenManager.createVideoHTML());
		});

	}

	public replaceTexturebyId(videoData: MediaStream | undefined, uid: number) {
		if (this.allowedUids.length === 0) {
			Logging.warn(`Screen manager has no allowed uids, please set them up before trying to update the screen texture`, LoggingArea.Space)
			return;
		}

		if (this.videoScreens.size === 0) {
			return;
		}

		if (!this.allowedUids.includes(uid)) {
			Logging.warn(`Screen with id ${uid} was not found sending video to debugElement`, LoggingArea.Space);
			// create debug video element
			return;
		}
		const screenInfo = this._getScreenInfo(uid);
		const { screenMesh, screenMat, imageUrl, videoElement } = screenInfo!;

		if (videoData === undefined) {

			if (uid === this._castToAllScreensId) {
				this.resetAllScreens();
				return;
			}

			if (imageUrl === CustomUrlStrings.defaultVideo) {
				const defaultVideoElement = this.videoElements.get(this._defaultPlaceHolder);
				this._updateScreenFromVideoElement(defaultVideoElement!, screenMesh, screenMat);
				return;
			}

			if (this.replaceMaterial) {
				this.replaceMaterial(screenMesh, imageUrl);
			} else {
				Logging.warn(`Screen manager needs a way to replace the texture with the old placeholder picture. This functionality comes from the engine so make sure to set it up first.`, LoggingArea.Space)
			}
			return;
		}

		// cast to all screens
		if (this._castToAllScreensId === uid) {
			this.castToAllScreens(videoData);
			return;
		}

		// cast to one screen
		this._updateScreenTexture(videoElement, screenMesh, screenMat, videoData);
	}

	public cleanAllScreensOnLeaving() {
		this.videoScreens.forEach((screen, uid) => {
			const imageUrl = this.urlCMS.get(uid);
			if (this.replaceMaterial) this.replaceMaterial(screen, imageUrl!);
		})
	}

	public resetAllScreens() {
		this.videoScreens.forEach((screen, uid) => {
			this.replaceTexturebyId(undefined, uid);
		})
	}

	public castToAllScreens(videoData: MediaStream) {
		this.videoScreens.forEach((screen) => {
			this._updateScreenTexture(this.videoElements.get(this._castToAllScreensId)!, screen, this.screenMaterials.get(this._castToAllScreensId)!, videoData);
		});
	}

	public castToAllScreenFromHTMLVideoElement(videoElement: HTMLVideoElement) {
		this.videoScreens.forEach((screen) => {
			this._updateScreenFromVideoElement(videoElement, screen, this.screenMaterials.get(this._castToAllScreensId)!);
		});

	}

	private _getScreenInfo(uid: number): ScreenInfo | undefined {
		const screenMesh = this.videoScreens.get(uid);
		const screenMat = this.screenMaterials.get(uid);
		const imageUrl = this.urlCMS.get(uid);
		const videoElement = this.videoElements.get(uid);

		if (screenMat === undefined || screenMesh === undefined || imageUrl === undefined || videoElement === undefined) {
			Logging.warn(`Something went wrong please check that the screen or material or placeholder url image exist`, LoggingArea.Space);
			return undefined;
		} else {
			return { screenMesh, screenMat, imageUrl, videoElement }
		}
	}



	private _updateScreenTexture(videoElement: HTMLVideoElement, screen: Mesh | Mesh[], screenMat: MeshBasicMaterial, videoData: MediaStream) {
		const videoTexture = ScreenManager.createTexture(videoElement);
		videoElement.srcObject = videoData;
		//using event here to fix the first black frame issue
		videoElement.addEventListener('loadeddata', () => {
			screenMat.map = videoTexture
			screenMat.needsUpdate = true
			screenMat.map.flipY = false;
			this._updateScreensMat(screen, screenMat);
			videoElement.play();
		})
	}

	private _updateScreenFromVideoElement(videoElement: HTMLVideoElement, screen: Mesh | Mesh[], screenMat: MeshBasicMaterial) {
		const videoTexture = ScreenManager.createTexture(videoElement);
		screenMat.map = videoTexture
		screenMat.needsUpdate = true
		this._updateScreensMat(screen, screenMat);
	}

	private _updateScreensMat(screens: Mesh | Mesh[], screenMat: MeshBasicMaterial) {
		if (Array.isArray(screens)) {
			screens.forEach(screen => {
				screen.material = screenMat;
			})
		} else {
			screens.material = screenMat;
		}
	}

	// does the same as the one from the engine just makes sense that this class does it on its own instead of relying on the engine.
	static createVideoHTML() {
		const videoElement = document.createElement("video");
		videoElement.style.display = "none"
		videoElement.setAttribute("webkit-playsinline", "webkit-playsinline") // required for ios to not pop out after it started to play
		videoElement.playsInline = true
		videoElement.crossOrigin = "anonymous"
		videoElement.autoplay = false
		videoElement.loop = false
		videoElement.muted = true // on iOS Safari, video has to be muted
		videoElement.autoplay = false;
		videoElement.muted = true;
		videoElement.style.display = 'none'
		return videoElement
	}

	static createTexture(videoElement: HTMLVideoElement) {
		const videoTexture = new VideoTexture(videoElement)
		videoTexture.minFilter = LinearFilter;
		videoTexture.magFilter = LinearFilter;
		videoTexture.encoding = sRGBEncoding
		return videoTexture
	}



}
