<template>
	<div id="avatar-generator" v-show="showAvatarGenerator">
		<div class="container">
			<div class="close" @click="showAvatarGenerator = false">
				<IconClose />
			</div>
			<div class="wrap">
				<UtilityRpmIframe @avatar-chosen="config => { saveConfig(config); showAvatarGenerator = false }" @avatar-preview="preview => savePreview(preview)"></UtilityRpmIframe>

				<div class="avatar-quickchooser">
					<div class="avatar-scaler">
						<input type="range" class="range" min="160" max="190" step="1" v-model="scale" @mouseup="changeScale">
						<p class="scale">{{ scale }}<span>cm</span></p>
					</div>
					<div class="avatar-holder">
						<div class="avatar" v-for="avatar in RPM_PRE_AVATARS " :style="'background-image: url(' + avatar.preview + ');'" @click="() => savePredefinedAvatar(avatar)" :class="avatar.model == spaceStore.avatar.model ? 'active' : ''"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useToast } from 'vue-toastification';
import { getEngine } from '~/space/engine';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { changeAvatar } from '~/space/lib/mechanic/multiplayer';
import { RPMAvatar } from '@ravespaceio/rave-engine';
import { AvatarConfig, RPM_PRE_AVATARS } from '~/space/lib/utils/rpm';


const space = getSpace()
const engine = getEngine()
const toast = useToast()
const spaceStore = useSpaceStore() // TODO ist der reaktiv? aktueller avatar wird nich gehighlighted

const { showAvatarGenerator } = storeToRefs(spaceStore)
const scale = ref(180)
// const scale = ref(playerStore.avatar!.scale * 180 || 180)

// show/hide
watch(showAvatarGenerator, (to, from) => {
	if (to == true) engine.renderer.paused = true;
	else engine.renderer.paused = false;
})


// predefined
function savePredefinedAvatar(config: AvatarConfig) {
	changeAvatar(config)
	toast.success("Avatar changed.");
	spaceStore.avatar.preview = config.preview
	//show.value = false
}

// custom avatar
function saveConfig(config: AvatarConfig) {
	changeAvatar(config)
	toast.success("Avatar changed.");
	spaceStore.avatar.preview = config.preview
	showAvatarGenerator.value = false
}

//
function savePreview(preview: string) {
	if (spaceStore.avatar.preview) spaceStore.avatar.preview = preview
}

// scale
function changeScale() {
	// console.log(scale.value)
	// let rpmScale = scale.value / 180
	// rpmScale = Math.round(rpmScale * 100) / 100
	// if (rpmScale > 1.06 || rpmScale < 0.89) return
	// const a: AvatarConfig = {
	// 	modelPath: playerStore.avatar!.modelPath,
	// 	gender: playerStore.avatar!.gender,
	// 	scale: rpmScale,
	// 	preview: playerStore.avatar!.preview,
	// 	isCustom: playerStore.avatar!.isCustom
	// }
	// playerStore.avatar! = a
}



</script>



<style scoped lang="scss">
@import "assets/css/variables";

.close {
	position: relative;
	right: inherit;
	top: inherit;
	margin-bottom: 12px;
}

#avatar-generator {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.97);
	z-index: 99;

	.container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: calc(100% - 50px);
			max-height: 80vh;

			iframe {
				width: 770px;
				height: 600px;
				max-width: 100%;
				max-height: 100%;
				border: none;
				border-radius: $radius;
				border: 1px solid $main;
			}


			.avatar-quickchooser {
				width: 280px;
				height: 600px;
				max-height: 100%;
				margin-left: 20px;
				@extend .flex-center;
				align-items: flex-start;
				justify-content: center;
				flex-wrap: wrap;

				.avatar-holder,
				.avatar-scaler {
					border: 1px solid $main;
					border-radius: $radius;
					padding: 10px;
				}

				.avatar-scaler {
					height: 70px;
					width: 280px;
					margin-bottom: 10px;
					@extend .flex-center;

					.range {
						width: 100px;
						padding: 0;
						margin-right: 10px;
					}

					.btn {
						padding: 4px;
						font-size: 10px;
						border-radius: 4px;
					}

					.scale {
						color: $main;
						font-size: 16px;
						font-weight: 700;
						margin-right: 12px;

						span {
							color: $main;
							font-size: 10px;
							margin-left: 2px;
							font-weight: 700;
						}
					}
				}

				.avatar-holder {
					overflow-y: auto;
					height: calc(100% - 80px);
					@extend .flex-center;
					flex-wrap: wrap;

					.avatar {
						margin: 10px;
						display: inline-block;
						width: 90px;
						height: 90px;
						border-radius: $radius;
						opacity: 0.6;
						cursor: pointer;
						transition: ease 0.2s;

						&:hover {
							transform: scale(1.1);
							opacity: 1;
							background-size: 144%;
							background-position: 37% 0%;
						}

						&.active {
							opacity: 1 !important;
							border: 1px solid $main;
						}
					}

					&::-webkit-scrollbar {
						width: 1px;
						height: 1px;
					}

					&::-webkit-scrollbar-thumb {
						background: transparent;
						border-radius: 4px;
					}

					&::-webkit-scrollbar-thumb:hover {
						background: transparent;
					}

					&::-webkit-scrollbar-track {
						background: transparent;
						border-radius: 4px;
					}
				}

			}
		}
	}
}

@media(max-width:1000px) {
	#avatar-generator {
		.container {
			.wrap {
				flex-direction: column;

				iframe {
					width: 100%;
				}

				.avatar-quickchooser {
					width: 100%;
					height: 100px;
					margin: 20px 0 0 0;
					background: $darkTransparent;

					.avatar-holder {
						height: 100%;
						padding: 5px;

						.avatar {
							width: 75px;
							height: 75px;
							margin: 5px;
						}
					}

					.avatar-scaler {
						display: none !important;
					}
				}
			}
		}
	}
}
</style>
