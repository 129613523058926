import Engine, { MyLoaders } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { useGameStore } from "~/store/game"



export function setupAudio() {
	const engine = getEngine()
	const space = getSpace()
	const loader = space.loader

	const musicPath = "/rspSpace/audio/game.mp3"

	loader.addFile(musicPath)

	engine.audioManager.setupPromise.then(() => {
		let gameMusic: THREE.PositionalAudio

		gameMusic = new THREE.PositionalAudio(engine.audioManager.listener!)
		loader.getLoadingPromise(musicPath).then((buffer) => {
			gameMusic.setBuffer(buffer);
			gameMusic.setVolume(0.6)
			gameMusic.setRefDistance(10);
			gameMusic.setMaxDistance(1000)
			gameMusic.setLoop(true)
			gameMusic.setRolloffFactor(1)
			gameMusic.position.set(27, 2, -10.6)
			engine.scene.add(gameMusic);
		})

		const store = useGameStore()
		watch(() => store.gameStarted, (state) => {
			if (state) {
				gameMusic.play()
			} else {
				gameMusic.pause()
			}
		})
	})
}
