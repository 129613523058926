import { defineStore } from 'pinia'
import { getEngine } from '../space/engine';
import { RoomAvailable } from 'colyseus.js';
import { Logging, LoggingArea } from '@ravespaceio/rave-engine';
import { getSpace } from '~/space/space';
import { Chatmessage_I } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Chatmessage_I';



export const useMultiplayerStore = defineStore({
	id: "multiplayer",
	state: () => ({
		isConnected: false,
		playerId: "",
		roomId: "",
		rooms: [] as RoomAvailable[],
		chats: [] as Chatmessage_I[]
	}),
	getters: {
		isInRoom(): boolean {
			return this.roomId !== ""
		},
		isAnyRoomOnline(): boolean {
			return this.rooms.length != 0
		}
	},
	actions: {
		fetchRoomsData() {
			Logging.trace("fetchRoomsData", LoggingArea.Api)
			const space = getSpace()
			const url = space.ENV.GAMESERVER_URI + "/api/allRooms"
			fetch(url).then(res => res.json()).then((roomList) => {
				this.rooms = roomList
			}).catch((e) => {
				this.rooms = []
			})
		},
		updateRoomAndPlayerID() {
			const engine = getEngine()
			this.roomId = engine.multiplayer!.roomId || ""
			this.playerId = engine.multiplayer!.playerId || ""
		}
	}
})


