import Engine, { EngineUtils, FlickerAnimatedLight, Logging, LoggingArea } from "@ravespaceio/rave-engine"
import LightFlickerAnimation from "@ravespaceio/rave-engine/build/engine/src/helper/three/FlickerAnimatedLight"
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import * as THREE from "three"
import { Group, Light, Object3D } from "three"
import { getEngine } from "~/space/engine"


export default class LightExtension {

	public readonly specProp = "LightID"
	public readonly group = new THREE.Group()

	constructor(scene: Object3D, lightMap: { [lightId: string]: (obj: Object3D) => (Light | LightFlickerAnimation) }) {

		const engine = getEngine()
		const spots = findObjects(scene, testUserData(this.specProp))
		const lights = []

		engine.scene.add(this.group)
		const lightAnis: FlickerAnimatedLight[] = []

		// add to spots
		spots.forEach((spot: THREE.Object3D) => {
			const spotLightId = spot.userData[this.specProp]
			const mapEl = lightMap[spotLightId]
			if (!mapEl) {
				Logging.warn("No light found for spot " + spotLightId, LoggingArea.Space)
				return;
			}
			const el = mapEl(spot)
			let light: Light
			let ani: FlickerAnimatedLight | undefined
			if (el instanceof LightFlickerAnimation) {
				light = el.light
				ani = el
			} else {
				light = el
			}

			light.name = spotLightId
			engine.debuggui.biggui.addToSceneFolder(light)
			spot.add(light)
			lights.push(light)
			this.group.add(spot)

			if (ani) lightAnis.push(ani)
		});

		// register animation update
		engine.loop.register((delta, time) => {
			lightAnis.forEach(l => {
				l.update(delta, time)
			})
		})

		// check for unused lights registrations
		for (const lid of Object.keys(lightMap)) {
			if (!spots.find((s) => s.userData[this.specProp] === lid)) {
				Logging.warn("No spot found for light " + lid)
			}
		}
	}
}

