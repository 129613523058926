<template>
	<div v-if="!spaceStore.onboardingDone" id="onboarding">


		<div id="onboarding-background" :style="`background-image:url(${space.project.publicPath + '/img/loader.jpg'})`" />

		<div class="grid">
			<div class="left">
				<img src="https://ravespace.io/img/press/png/RaveSpaceLogo_White.png" alt="Logo" class="logo">
				<h2>Rave Space Paradise</h2>
				<p></p>
				<div class="progress-bar" id="loader--progress">
					<div class="progress" :style="{ width: (loadingProgress * 100) + '%' }" />
				</div>
			</div>

			<div class="right">
				<SpaceOnboardingUserSettings id="settings" class="settings" />
				<div :class="canContinue ? '' : 'disabled'">
					<div id="continue" class="btn" @click="continueStuff()">Continue
						<IconLoader v-if="loadingProgress < 1" />
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup lang="ts">
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear, random } from 'gsap'
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space'
import { storeToRefs } from 'pinia'
import { validateText } from '~/assets/js/text'

const space = getSpace()
const spaceStore = useSpaceStore()

const skipOnboarding = space.browser.urlParameter.get("skipOnboarding") || !space.ENV.IS_PROD && true
const fastOnboarding = space.browser.urlParameter.get("fastOnboarding")

const loadingProgress = ref(0)
const loaded = ref(false)
const { playerName } = storeToRefs(spaceStore)
const canContinue = computed(() => validateText(playerName.value) && loaded.value)

if (skipOnboarding) {
	nextTick(() => {
		spaceStore.onboardingDone = true;
		spaceStore.tutorialDone = true;
	})
}
else {
	onMounted(() => {
		space.loader.onAlready("loadingprogress", (prog) => {
			loadingProgress.value = prog;
		});
		space.loader.onceAlready("loaded", () => {
			loaded.value = true;
			if (fastOnboarding) continueStuff()
		});

		const tl = new TimelineLite({ repeat: -1 });
		tl.to("#onboarding-background", 20, { scale: 1.2 });
		tl.to("#onboarding-background", 20, { scale: 1 });
	})
}

// finished, fade out stuff
function continueStuff() {
	if (canContinue.value) {
		const tl = new TimelineLite({
			onComplete: () => { spaceStore.onboardingDone = true; spaceStore.tutorialDone = true }
		});
		tl.to("#onboarding", 0.6, { opacity: 0 });
	}
};

</script>

<style scoped lang="scss">
@import "assets/css/variables";
@import "assets/css/space";

#onboarding {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $dark;
	z-index: 999999999;

	#onboarding-background {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		z-index: 0;
		opacity: 0.2;
		filter: blur(6px);
	}

	.grid {
		width: 100%;
		max-width: calc(100% - 200px);
		margin: 0 auto;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;

		.left {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			width: 500px;

			h2 {
				color: white;
				text-align: left;
				font-weight: 600;
				max-width: 100%;
				margin-bottom: 12px;
			}

			p {
				color: white;
				text-align: left;
				font-size: 14px;
				max-width: 100%;
			}

			.progress-bar {
				margin: 20px 0 0 0;
				border: none;
				background: #ffffff24;
				height: 6px;
				width: 100%;
				max-width: 400px;

				.progress {
					border: none;
				}
			}

			.logo {
				width: 200px;
				margin-bottom: 12px;
			}
		}

		.right {
			.btn {
				width: 100%;
				height: 55px;
				margin-top: 12px;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					background: rgba(8, 8, 8, 0.3);
				}
			}
		}
	}

}
</style>
