import Engine, { EngineUtils } from "@ravespaceio/rave-engine"
import { findObjectsByUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import { addAxis } from "@ravespaceio/rave-engine/build/engine/src/utils/three"
import * as THREE from "three"
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { setupMaterials } from "./materials"
import { setupNavigation } from "~/space/lib/mechanic/navigation"
import AnimationExtension, { AESequences } from "~/space/lib/gltfExtensions/AnimationExtension"
import ArtworkExtension from "~/space/lib/gltfExtensions/ArtworkExtension"
import InstancedMeshExtension from "~/space/lib/gltfExtensions/InstancedMeshExtension"
import InteractionExtension from "~/space/lib/gltfExtensions/InteractionExtension"
import LightExtension from "~/space/lib/gltfExtensions/LightExtension"
import { setupPhysicObjects } from "../physics/main"
import { setupBoat } from "./boat"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"



export function setupModels() {

	const engine = getEngine()
	const space = getSpace()

	if (!space.ENV.IS_PROD) addAxis(engine.scene)

	space.loader.addFiles({
		"navmesh": "/rspSpace/models/navigation.glb",
		"boat": "/rspSpace/models/boat/boat.glb"
	})
	const roomFiles = {
		"island": "/rspSpace/models/island/island.glb",
		"env": "/rspSpace/models/env/env.glb",
		"city": "/rspSpace/models/city/city.glb",
		"club": "/rspSpace/models/club/club.glb",
		"stadium": "/rspSpace/models/stadium/stadium.glb",
		"skybar": "/rspSpace/models/skybar/skybar.glb",
	}

	space.loader.addFiles(roomFiles)

	const applyMaterials = setupMaterials()

	space.loader.on("loaded", async () => {
		// models
		const modelGroup = new THREE.Group()
		modelGroup.name = "ModelGroup"
		engine.scene.add(modelGroup)
		for (const [name, path] of Object.entries(roomFiles)) {
			const model = (space.loader.getLoaded(name) as GLTF).scene
			modelGroup.add(model)
		}
		EngineUtils.Three.makeStatic(modelGroup)
		EngineUtils.Three.makeFrontSide(modelGroup)
		EngineUtils.Three.makeShadowEnabled(modelGroup, true, false)


		// empties
		const navmeshGltf = space.loader.getLoaded("navmesh") as GLTF
		setupNavigation(navmeshGltf.scene)
		setupPhysicObjects();

		// boat
		setupBoat()

		const awData: any[] = []
		new ArtworkExtension(modelGroup, awData)
		new LightExtension(modelGroup, {})
		new InteractionExtension(modelGroup)
		new AnimationExtension(modelGroup, {
			levitatePortal: (obj) => AESequences.translationPreset({ axis: "Y", offset: AESequences.seedRndm() * 3, amplitude: .2, speed: 1 }, obj),
		})

		const ime = new InstancedMeshExtension(modelGroup)
		await ime.isLoaded
		modelGroup.add(ime.group)

		// materials
		applyMaterials(engine.scene)
	})
}
