import Engine, { EngineUtils, HighlightCylinder } from "@ravespaceio/rave-engine";
import * as THREE from "three"
import { BlockingHintCollider } from "~/space/lib/manager/PCISystem";
import { replaceMaterial } from "~/space/lib/utils/material";
import { getSpace } from "~/space/space";


export function setupPortals() {
	const space = getSpace()

	space.loader.getLoadingPromise("island").then((gltf) => {
		const screens = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR")
		screens.forEach((screen) => {
			// @ts-ignore
			if (screen.userData.CMSR == "portal1" && screen.material.name == "Screen") {
				// @ts-ignore
				replaceMaterial(screen, "/rspSpace/img/portals/tbt.png")
				createPortal(new THREE.Vector3(3.72, 3.65, 7.5), "Festival Ground for twelve x twelve", "https://metaverse.twlvxtwlv.com/")
			}
			// @ts-ignore
			if (screen.userData.CMSR == "portal2" && screen.material.name == "Screen") {
				// @ts-ignore
				replaceMaterial(screen, "/rspSpace/img/portals/azn.png")
				createPortal(new THREE.Vector3(-4.93, 3.58, 7.8), "Amazon Museum of Modern Ads", "https://prod.ama.ravespace.xyz/")
			}
			// @ts-ignore
			if (screen.userData.CMSR == "portal3" && screen.material.name == "Screen") {
				// @ts-ignore
				replaceMaterial(screen, "/rspSpace/img/portals/eev.png")
				createPortal(new THREE.Vector3(-12.36, 3.59, 12.84), "Virtual Store for Eeva Ria", "https://eeva-ria.com/pages/virtual-store")
			}
			// @ts-ignore
			if (screen.userData.CMSR == "portal4" && screen.material.name == "Screen") {
				// @ts-ignore
				replaceMaterial(screen, "/rspSpace/img/portals/chu.png")
				createPortal(new THREE.Vector3(-13.2, 3.6, 23.2), "Holyverse", "https://prod.chu.ravespace.xyz/")
			}
			// @ts-ignore
			if (screen.userData.CMSR == "portal5" && screen.material.name == "Screen") {
				// @ts-ignore
				replaceMaterial(screen, "/rspSpace/img/portals/kn.png")
				createPortal(new THREE.Vector3(-23.58, 3.58, 13.38), "Keiino World", "https://enter.keiino.world/")
			}
		}
		)
	})

	space.loader.getLoadingPromise("env").then((gltf) => {
		const screens = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR")
		screens.forEach((screen) => {
			// @ts-ignore
			if (screen.userData.CMSR == "dalle" && screen.material.name == "Screen") {
				// @ts-ignore
				replaceMaterial(screen, "/rspSpace/img/onart.jpg")
				createPortal(new THREE.Vector3(-6.92, -0.74, 62.4), "Join our Art Platform OnArt", "https://onart.io/")
			}
		})
	})



}



function createPortal(pos: THREE.Vector3, title: string, link: string | URL | undefined) {

	const space = getSpace()
	space.pciSystem.add(new BlockingHintCollider(pos, 1.3, { title: "Teleport to " + title }, {
		onEnter() {

		},
		onInteract() {
			//@ts-ignore
			window.open(link, '_blank').focus();
		},
		onLeave() {
			space.eventbus.emit("showArtworkModal", undefined)
		}
	}))
}
