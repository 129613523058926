<template>
	<div v-if="store.gameStarted" class="game-blocker" :class="showFlash ? 'active' : ''"></div>
	<div v-if="store.gameStarted" class="game-ui">
		<img src="/rspSpace/img/whack.png" v-if="!$isMobile">
		<div class="wrap">
			<div class="el">
				<h2>Countdown</h2>
				<p>{{ countdown }}</p>
			</div>
			<div class="el">
				<h2>Hits</h2>
				<p>{{ store.hits }}</p>
			</div>
		</div>
		<!-- <div class="btn--white" @click="endGame()">Spiel abbrechen</div> -->
	</div>

	<div id="cursor" v-if="store.gameStarted" :style="'left: ' + mousePosX + 'px;top: ' + mousePosY + 'px;'">
		<img src="/rspSpace/img/game/hammer1.png" v-if="cursor == 1">
		<img src="/rspSpace/img/game/hammer2.png" v-else>
	</div>

	<template v-if="!$isMobile">
		<div class="cinescope1" v-if="store.miniGamePopup" :class="store.gameStarted ? 'active' : ''"></div>
		<div class="cinescope2" v-if="store.miniGamePopup" :class="store.gameStarted ? 'active' : ''"></div>
	</template>


	<div class="gameModal" v-if="!store.gameStarted && store.miniGamePopup">
		<div class="box">
			<img src="/rspSpace/img/game.png">
			<p>Test your reaction and hit the troublemaker</p>
			<div class="btn" @click="startGame()">Start Game</div>
			<p class="abort" @click="store.miniGamePopup = false">Abort</p>
		</div>
	</div>
</template>

<script lang="ts">
import Engine, { EngineUtils, HighlightCylinder } from "@ravespaceio/rave-engine";
import Vue from 'vue'
import * as THREE from "three"
import { useGameStore } from "~/store/game";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";


const gameTime = 20

export default {
	data() {
		return {
			store: useGameStore(),
			countdown: gameTime,
			interval: undefined as any,
			mousePosX: 0,
			mousePosY: 0,
			cursor: 1,
			showFlash: false
		}
	},
	mounted() {
		const space = getSpace()
		const engine = getEngine()

		watch(() => this.store.miniGamePopup, (state) => {
			this.store.miniGamePopup = state
			if (state) {
				setTimeout(() => {
					engine.inputManager.unLockCursor()
				}, 1);
			} else {
				setTimeout(() => {
					engine.inputManager.lockCursor()
				}, 1);
			}
		});

		watch(() => this.store.gameStarted, (state) => {
			if (state) {
				window.addEventListener('mousemove', listener, false);
				document.getElementById("keyboard--controls")?.classList.add("hide");
			} else {
				window.removeEventListener('mousemove', listener, false);
				document.getElementById("keyboard--controls")?.classList.remove("hide");
			}
		});

		watch(() => this.store.hits, (state) => {
			this.showFlash = true
			setTimeout(() => {
				this.showFlash = false
			}, 100);
		});

		var listener = (event: { clientX: number; clientY: number; }) => {
			this.mousePosX = event.clientX;
			this.mousePosY = event.clientY;
		};


		document.addEventListener("click", () => {
			this.cursor = 2
			setTimeout(() => {
				this.cursor = 1
			}, 100)
		});

	},
	methods: {
		startGame() {
			this.store.gameStarted = true
			this.countdown = gameTime
			this.store.hits = 0
			this.interval = setInterval(() => {
				this.countdown = this.countdown - 1
				if (this.countdown == 0) {
					this.gameFinished()
				}
			}, 1000);
		},
		endGame() {
			clearInterval(this.interval)
			this.store.miniGamePopup = false
			this.store.gameStarted = false
		},
		gameFinished() {
			this.endGame()
			const space = getSpace()
			space.eventbus.emit("gameFinished", true)
		}
	}
}
</script>

<style scoped lang="scss">
#cursor {
	width: 10px;
	height: 10px;
	position: fixed;
	z-index: 999;
	pointer-events: none;

	img {
		width: 100px;
		height: auto;
		position: absolute;
		left: -40px;
		top: -30px;
	}
}

.game-blocker {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 30;
	width: 100%;
	height: 100%;
	cursor: none;

	&.active {
		background: rgba(255, 255, 255, 0.6);
	}
}

.game-ui {
	position: fixed;
	top: 20px;
	left: calc(50% - 200px);
	text-align: center;
	width: 400px;
	padding: 8px;
	border-radius: 4px;
	font-size: 14px;
	color: white;
	font-weight: 700;
	z-index: 20;
	background: rgba(0, 0, 0, 0.8);
	border: 1px solid white;
	padding: 15px 10px;

	img {
		width: 160px;
		height: auto;
	}

	.btn--white {
		font-size: 12px;
		padding: 7px 20px;
		display: inline-block;
	}

	.wrap {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.el {
			background: linear-gradient(121.88deg, #FF6002 14.48%, #F08F56 99.37%);
			box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.59);
			border-radius: 8px;
			width: 150px;
			height: 70px;
			margin: 10px;
			margin-bottom: 20px;

			h2 {
				font-size: 15px;
				height: 30px;
				line-height: 30px;
				border-bottom: 1px solid #D96A28;
				font-weight: 700;
			}

			p {
				font-size: 30px;
				font-weight: 700;
			}
		}
	}
}

.cinescope1,
.cinescope2 {
	position: fixed;
	pointer-events: none;
	z-index: 18;
	height: 10vh;
	background: black;
	width: 100%;
	left: 0;
	transition: ease 2s;
}

.cinescope1 {
	top: -10vh;

	&.active {
		top: -1px;
	}
}

.cinescope2 {
	bottom: -10vh;

	&.active {
		bottom: -1px;
	}
}

.gameModal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 19;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.8);

	.box {
		padding: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: white;
		background: rgba(0, 0, 0, 0.9);
		width: 600px;
		max-width: calc(100% - 60px);

		img {
			margin-bottom: 20px;
			width: 500px;
			height: auto;
			max-width: 100%;
		}

		p {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 14px;
			max-width: 310px;
			text-align: center;
		}

		.btn {
			margin: 10px 0;
		}

		.abort {
			text-decoration: underline;
			font-size: 12px;
			cursor: pointer;
			opacity: 0.6;

			&:hover {
				opacity: 1;
			}
		}
	}
}
</style>
