import Engine, { EngineUtils, RPMAvatar, MirrorSphere, MyLoaders } from "@ravespaceio/rave-engine"
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import * as THREE from "three"


export async function setupAnimatedObjects(engine: Engine) {

	// Boat
	const planeGltf = await MyLoaders.gltfLoader.loadAsync("/rspSpace/models/misc/plane.glb")
	const plane = planeGltf.scene
	plane.scale.set(1, 1, 1)
	plane.position.set(-23, 28, -200)
	engine.debuggui.biggui.addToSceneFolder(plane)
	engine.scene.add(plane)
	startAnimation1(plane)

	function startAnimation1(mesh: any) {
		const speed = 50
		const tl = new TimelineLite({
			onComplete: () => {
				plane.remove()
			}
		});
		tl.to(mesh.position, speed, { y: 70, z: 800, ease: Linear.easeNone }, "+=10");
		tl.to(mesh.rotation, speed, { x: -0.2, ease: Linear.easeNone }, "-=" + speed);
		tl.to(mesh.rotation, 5, { z: -1 }, "-=30");
		tl.to(mesh.rotation, 10, { z: 0.5 }, "-=20");

	}


}

