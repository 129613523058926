<template>
	<div>
		<div v-if="adminRole" id="adminHint">
			<p>Admin Access: {{ adminRole }}</p>
			<p>{{ adminRoleInfos }}</p>
			<div @click="voiceRoleStore.join_leave()" class="btn" :class="voiceRoleStore.isJoined ? 'red' : ''">
				<template v-if="voiceRoleStore.isJoined">Leave Master Voicechat</template> <template v-else>Join Master Voicechat</template>
			</div>
			<div v-for="(item, index) of hostChannels" :key="index" class="broadcast-hint">
				<p :class="hostChannelInfo[item]?.muted ? 'muted' : ''"><template v-if="hostChannelInfo[item]?.muted">Muted</template><template v-else>Speaking</template> to: {{ item }}</p>
				<div v-if="hostChannelInfo[item]?.muted" class="mute" @click="toggleMute()">
					<div class="key">P</div>Unmute
				</div>
				<div v-else class="mute" @click="toggleMute()">
					<div class="key">P</div>Mute
				</div>
				<div class="volumeLevel">
					<p>Microphone Volume Level</p>
					<div class="progress-bar">
						<div class="progress" :style="'width: ' + volumenLevel + '%'" />
					</div>
				</div>
				<div v-if="screenStore.isScreenShareActive" class="screenshare">
					<p>Share Screen</p>
					<div class="wrap">
						<div v-for="(item, index) of screenStore.screenIds">
							<div class="btn" @click="screenStore.castScreen_To_Screen(item)">{{ item }}</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup lang="ts">

import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Role";
import { useScreenStore } from "~/store/agora/screens";
import { useSpaceStore } from "~/store/space";
import { ActiveChannelsInfo, useVoiceRolesStore } from "~/store/agora/voiceroles";
import { getSpace } from "~~/src/space/space";

const voiceRoleStore = useVoiceRolesStore();
const screenStore = useScreenStore();
const hostChannelInfo = ref({} as ActiveChannelsInfo);
const audienceChannelInfo = ref({});
const hostChannels = ref([] as string[]);
const audienceChannels = ref([] as string[]);
const adminRole = ref(0 as Role);
const adminRoleInfos = ref('');
const volumenLevel = ref(0);


watch(() => voiceRoleStore.activeHostChannelInfo, (channelInfo) => {
	hostChannelInfo.value = channelInfo;
})
watch(() => voiceRoleStore.activeAudienceChannelInfo, (channelInfo) => {
	audienceChannelInfo.value = channelInfo;
})
watch(() => voiceRoleStore.activeAudienceChannels, (channels) => {
	audienceChannels.value = channels;
})
watch(() => voiceRoleStore.activeHostChannels, (channels) => {
	hostChannels.value = channels;
})
watch(() => voiceRoleStore.volumenLevel, (level) => {
	volumenLevel.value = level;
})


watch(() => voiceRoleStore.userRole, (role) => {
	switch (role) {
		case Role.superAdmin:
			adminRoleInfos.value = 'You are the host of this space. You can be heard by everyone in the space'
			initMuteKey()
			break;
	}
	adminRole.value = role;
})



/* Admin Hint */


const space = getSpace();
const spaceStore = useSpaceStore()

const initMuteKey = () => {
	space.eventbinder.bindDocument("keyup", (e) => {
		if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
		if (e.key == "p" || e.key == "P") {
			toggleMute()
		}
	});
}

function mute(channelName: string) {
	const voiceRoleStore = useVoiceRolesStore()
	voiceRoleStore.mute(channelName)
};

function unmute(channelName: string) {
	const voiceRoleStore = useVoiceRolesStore()
	voiceRoleStore.unmute(channelName)
};

function toggleMute() {
	if (hostChannelInfo.value[hostChannels.value[0]].muted) {
		unmute(hostChannels.value[0])
	} else {
		mute(hostChannels.value[0])
	}
}


</script>

<style lang="scss" scoped>
@import "assets/css/variables";

#adminHint {
	position: fixed;
	left: 10px;
	top: 10px;
	z-index: 99;
	background: $darkTransparent;
	color: white;
	border: 1px solid white;
	border-radius: $radius;
	padding: 5px 10px;
	font-weight: 700;
	max-width: 200px;

	.btn {
		padding: 6px 17px;
		margin-top: 9px;
		margin-bottom: 6px;
		font-size: 12px;

		&.red {
			background: #ec4959;
			border-color: #ec4959;

			&:hover {
				color: white;
			}
		}
	}

	p:first-child {
		font-weight: 700;
	}

	p {
		font-size: 12px;
	}

	.progress-bar {
		width: 100%;
		margin-bottom: 12px;

		.progress {
			transition: ease 0.1s !important;
		}
	}

	.volumeLevel {
		p {
			text-align: center;
			margin-bottom: 5px;
			font-size: 11px;
			background: transparent !important;
		}
	}

	.broadcast-hint {
		p {
			background: #5bad5b;
			color: white;
			margin: 12px 0 6px 0;
			padding: 4px 10px;
			border-radius: 4px;
			text-align: center;
			font-weight: 700;
			font-size: 11px;

			&.muted {
				background: #da6262;
				color: white;
			}
		}
	}
}

.mute {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 12px 0;
	font-weight: 700;
	font-size: 13px;

	.key {
		width: 20px;
		height: 20px;
		background: white;
		color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-size: 13px;
		border-radius: 4px;
		margin-right: 4px;

		&.muted {
			background: #5bad5b;
			color: white;
		}
	}
}

.screenshare {
	border-top: 1px solid white;

	p {
		background: transparent !important;
		margin-bottom: 0
	}

	.wrap {
		@extend .flex-center;
		flex-wrap: wrap;

		.btn {
			margin: 4px;
			margin-top: 0;
		}
	}
}
</style>
