import Engine, { Logging, LoggingArea, SphereColliderSystem } from "@ravespaceio/rave-engine";
import * as THREE from "three"
import { SphereColliderI } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SphereColliderSystem";
import { Vector3 } from "three"
import { addAxis } from "@ravespaceio/rave-engine/build/engine/src/utils/three";
import { GuiHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiHelper";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import { useSpaceStore } from "~/store/space";
import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { ArtworkType } from "../gltfExtensions/ArtworkExtension";
import { sitOutsideOfNavmesh } from "../gltfExtensions/InteractionExtension";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";

/**
 * PlayerColliderInteractionSystem
 *
 * Problem
 * - Wenn man auf den hint klickt weiß er nicht ob ers zum hmSys oder woanders hin schicken soll (zb interactionExtension)
 * - hintSystem.showHint("bla", onClickCallback)
 * - bus.emit("showHint", onClickCallback)
 * */
export default class PCISystem {

	private readonly _collidersys = new SphereColliderSystem()
	private _openOne: PCI_Object | undefined
	public readonly helper = new THREE.Group()


	// setup after space is init and space object is created
	public setup() {
		const hintModalSys = this._collidersys

		const space = getSpace()
		const engine = getEngine()
		const spaceStore = useSpaceStore()

		// gui
		const f = engine.debuggui.biggui.getFolderOrCreate("Space")
		f.add(this.helper, "visible").name("PCI visible")
		engine.scene.add(this.helper)
		engine.loop.register(() => {
			const player = engine.player.getPlayer()
			if (player) hintModalSys.update(player.position)
		})

		// 3d helper
		if (!space.ENV.IS_PROD) {
			const helperSphere = new THREE.Mesh(new THREE.SphereGeometry(0.1, 6, 6), new THREE.MeshBasicMaterial({ color: "red", wireframe: true }))
			addAxis(helperSphere).scale.set(0.5, 0.5, 0.5)
			hintModalSys.onAddedAny = (s) => {
				const h = helperSphere.clone();
				h.position.copy(s.position); // TODO use object to keep emptie rotation?
				this.helper.add(h)
			}
		}

		// default onNearestChange
		hintModalSys.onNearestChange = (to, from) => {
			if (from && from.onLeave) from.onLeave()
			if (to && to.onEnter) to.onEnter()
			Logging.trace("PCI onNearestChange", LoggingArea.Space)
		}

		// user interaction
		space.eventbinder.bindDocument("keyup", (e) => {
			if (e.key === " " && canUseActionKey()) { this.checkColliding() }
			if (e.key == "Escape") { this.stopInteracting() }
		})
		engine.inputManager.gamepad.on("buttonup", (btn) => {
			if (btn === "Y" && canUseActionKey()) { this.checkColliding() }
			if (btn == "B") { this.stopInteracting() }
		})
		engine.renderer.canvasElement.addEventListener("click", () => {
			this.stopInteracting()
		})
	}

	public checkColliding() {
		const col = this._collidersys.getNearestColiding() as PCI_Object
		if (col) {
			this._openOne = col
			if (col.onInteract) {
				Logging.trace("PCI onInteract", LoggingArea.Space)
				col.onInteract()
			}
		}
		// else { // TODO
		// 	this.stopInteracting()
		// }
	}

	// to be called from outside e.g. vue
	// or send collider to vue an call it there?
	public stopInteracting() {
		const current = this._openOne
		if (!current) return
		this._openOne = undefined
		if (current.onClose) {
			Logging.trace("PCI onClose", LoggingArea.Space)
			current.onClose()
		}
	}

	public add(collider: PCI_Object) {
		this._collidersys.add(collider)
	}
}


export type PCI_Event = undefined | (() => any)
export type PCI_Events = {
	onEnter?: PCI_Event
	onLeave?: PCI_Event
	onInteract?: PCI_Event
	onClose?: PCI_Event
}
export type PCI_Collider = {
	position: THREE.Vector3,
	radius: number,
}
export type PCI_Object = PCI_Events & PCI_Collider


/**
 * abstract base class
 */
export abstract class PCIColliderA implements PCI_Object {
	public position: Vector3;
	public radius: number;
	public onEnter: PCI_Event
	public onLeave: PCI_Event
	public onInteract: PCI_Event
	public onClose: PCI_Event;
	constructor(pos: Vector3, radius: number) {
		this.position = pos
		this.radius = radius
	}
}

/**
 * minimal PCI object class with unset events
 * when a PCI_Events return false the chain is supposed break
 */
export class PCICollider extends PCIColliderA {
	constructor(pos: Vector3, radius: number, events?: PCI_Events) {
		super(pos, radius)
		if (events?.onEnter) this.onEnter = events.onEnter
		if (events?.onLeave) this.onLeave = events.onLeave
		if (events?.onInteract) this.onInteract = events.onInteract
		if (events?.onClose) this.onClose = events.onClose
	}
}

/**
 * sending data to hint frontend
 */
export type HintType = {
	title: string,
	text?: string
}
export class HintCollider extends PCIColliderA {
	constructor(pos: Vector3, radius: number, public hint: HintType, events?: PCI_Events) {
		super(pos, radius)
		const spaceStore = useSpaceStore()
		this.onEnter = () => {
			if (events?.onEnter) if (events.onEnter() === false) return;
			spaceStore.hint.title = hint.title
			spaceStore.hint.text = hint.text
		}
		this.onLeave = () => {
			if (events?.onLeave) if (events.onLeave() === false) return;
			spaceStore.hint.title = undefined
			spaceStore.hint.text = undefined
		}
		this.onInteract = events?.onInteract
		this.onClose = events?.onClose
	}
}


/**
 * sending data to hint frontend and handles player blocking on interaction
 */
export class BlockingHintCollider extends HintCollider {
	constructor(pos: Vector3, radius: number, hint: HintType, events?: PCI_Events) {
		const spaceStore = useSpaceStore()
		super(pos, radius, hint, {
			onEnter: events?.onEnter,
			onLeave: events?.onLeave,
			onInteract: () => {
				if (events?.onInteract) if (events.onInteract() === false) return false;
				spaceStore.hint.title = undefined
				spaceStore.hint.text = undefined
				blockForFrontend();
			},
			onClose: () => {
				if (events?.onClose) if (events.onClose() === false) return false;
				spaceStore.hint.title = hint.title
				spaceStore.hint.text = hint.text
				unblockFromFrontend();
			}
		})
	}
}


export function blockForFrontend() {
	const engine = getEngine()
	engine.inputManager.blockInput()
	engine.inputManager.unLockCursor();
}

export function unblockFromFrontend() {
	const engine = getEngine()
	engine.inputManager.unBlockInput();
	engine.inputManager.lockCursor();
}


/**
 * Action keys are keys that are used ingame to interact with the world or others. e.g. key to trigger dance
 */
export function canUseActionKey(): boolean {
	const space = getSpace();
	const engine = getEngine()
	const spaceStore = useSpaceStore()
	if (space.browser.isMobile) return false;
	var ok = !isAnyTextInputActive() && spaceStore.onboardingDone && !spaceStore.showMenu
	if (engine.inputManager.mouseInputTracker.usePointerLock) ok = ok && engine.gui.isPointerLocked()
	return ok
}

export function canAnswerVoicehat(): boolean {
	const space = getSpace();
	const spaceStore = useSpaceStore()
	if (space.browser.isMobile) return false;
	var ok = !isAnyTextInputActive() && spaceStore.onboardingDone && !spaceStore.showMenu
	return ok
}



export class ArtworkCollider extends BlockingHintCollider {
	constructor(pos: Vector3, radius: number, hint: HintType, artworkData: ArtworkType) {
		super(pos, radius, hint, {
			onClose: () => {
				const spaceStore = useSpaceStore()
				spaceStore.artworkModal = undefined
			},
			onInteract: () => {
				const spaceStore = useSpaceStore()
				spaceStore.artworkModal = artworkData
			}
		})
	}
}


export class SICollider extends PCICollider {
	constructor(pos: Vector3, private spot: THREE.Object3D) {
		super(pos, 1, {
			onEnter: () => {
				const spaceStore = useSpaceStore()
				spaceStore.hintTop.title = "Sit down"
			},
			onLeave: () => {
				const spaceStore = useSpaceStore()
				spaceStore.hintTop.title = undefined
			},
			onInteract: () => {
				const engine = getEngine()
				const spaceStore = useSpaceStore()

				const avatar = engine.player.getPlayer<PlayerObject>().avatar
				if (!avatar) return
				avatar.triggerAction("sitting", 0.5, true)
				sitOutsideOfNavmesh(0.001, 0.5, this.spot);
				spaceStore.hintTop.title = undefined
			}
		})
	}
}
