import Engine, { EngineUtils, Painting } from "@ravespaceio/rave-engine"
import { Mesh, Group, MeshBasicMaterial, Sphere, Object3D, Vector3 } from "three"
import * as THREE from "three"
import { PaintingCanvas } from "@ravespaceio/rave-engine/build/engine/src/objects/exhibition/Painting"
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import { getSpace } from "~~/src/space/space"
import { ArtworkCollider, HintType } from "../manager/PCISystem"
import { useSpaceStore } from "~/store/space"



export type ArtworkType = {
	GalleryID: string,
	title: string,
	text?: string,
	text2?: string,
	img: string,
	placement?: "wall" | "floor"
	width?: number,
	height?: number,
}


export default class ArtworkExtension {

	public readonly specProp = "GalleryID"
	public readonly group: Group = new THREE.Group()

	constructor(scene: Object3D, artworks: ArtworkType[]) {
		const space = getSpace()

		const artworksGroup = this.group
		artworksGroup.name = "ArtworkExtensionGroup"
		scene.add(artworksGroup)

		const artworkSpots = findObjects(scene, testUserData(this.specProp))
		artworkSpots.forEach((spot) => {
			const data = artworks.find(d => d[this.specProp] == spot.userData[this.specProp])
			if (!data) { console.warn("Could not find data for gallery spot " + spot.userData[this.specProp]); return; }

			const hpos = spot.getWorldPosition(new Vector3())
			hpos.y = 0
			space.pciSystem.add(new ArtworkCollider(hpos, 1.5, data, data))

			const p = new PaintingCanvas({
				maxHeight: data.height ? data.height : data.width ? data.width * 2 : 1,
				maxWidth: data.width ? data.width : data.height ? data.height * 2 : 1,
				src: data.img,
				shadow: true,
			})
			artworksGroup.add(spot)
			spot.add(p)
		})
	}
}


