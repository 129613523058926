import Engine from "@ravespaceio/rave-engine";
import BoxCollider from "@ravespaceio/rave-engine/build/engine/src/engine/physics/old/BoxCollider";
import { Body, Plane, Material, Box, Vec3 } from "cannon-es";
import { Object3D, Mesh, BoxGeometry, MeshBasicMaterial, Vector3 } from 'three';
const visibleWalls = false;
const visiblegoal = false;
const renderMaterial = new MeshBasicMaterial({ wireframe: false })



export function setupGround(engine: Engine, physicsMaterial?: Material) {


	const ground = new Body({ material: physicsMaterial });
	const planeShape = new Plane();
	ground.addShape(planeShape);
	ground.position.set(0, 2.93, 0); // not the same zero as three js?
	ground.quaternion.setFromEuler(-Math.PI / 2, 0, 0) // make it face up
	engine.physics.world.addBody(ground);

}


export function setupWalls(engine: Engine, offset: { x: number, y: number, z: number }, physicsMaterial?: Material) {

	createBoxCollider(engine, { size: new Vector3(0.1, 10, 16), position: new Vector3(-24 - offset.x, 2.95, -30 - offset.z), visible: visibleWalls, material: physicsMaterial, isTrigger: false })
	createBoxCollider(engine, { size: new Vector3(8, 10, 0.1), position: new Vector3(-22 - offset.x, 2.95, -36.5 - offset.z), visible: visibleWalls, material: physicsMaterial, isTrigger: false })
	createBoxCollider(engine, { size: new Vector3(8, 10, 0.1), position: new Vector3(-22 - offset.x, 2.95, -24.5 - offset.z), visible: visibleWalls, material: physicsMaterial, isTrigger: false })
	createBoxCollider(engine, { size: new Vector3(0.1, 10, 16), position: new Vector3(-19.85 - offset.x, 2.95, -30 - offset.z), visible: visibleWalls, material: physicsMaterial, isTrigger: false })
}


function createBoxCollider(engine: Engine, options: any) {
	const wall1 = new Mesh(new BoxGeometry(options.size.x, options.size.y, options.size.z), renderMaterial)
	wall1.visible = options.visible;
	wall1.position.set(options.position.x, options.position.y, options.position.z);
	const wallCollider = new BoxCollider(engine, { mass: 0, material: options.material, isTrigger: options.isTrigger })
	wall1.add(wallCollider);
	wallCollider.renderWireframe();
	engine.scene.add(wall1)
	return wallCollider;
}



export function completeGoal(engine: Engine, offset: { x: number, y: number, z: number }, physicsmaterial?: Material) {

	createBoxCollider(engine, { size: new Vector3(2.4, 1.3, 0.28), position: new Vector3(-22.5 - offset.x, 3.65, -35.6 - offset.z), visible: visiblegoal, material: physicsmaterial, isTrigger: false });
	createBoxCollider(engine, { size: new Vector3(2.5, 1.3, 0.28), position: new Vector3(-21.5 - offset.x, 4.7, -35.6 - offset.z), visible: visiblegoal, material: physicsmaterial, isTrigger: false });
}

export function setUpTrigger(engine: Engine, physicsmaterial?: Material) {
	const trigger = createBoxCollider(engine, { size: new Vector3(5, 3, 2.5), position: new Vector3(-22.5, 2.95, -30), visible: false, material: physicsmaterial, isTrigger: true })
	trigger._collider!.addEventListener('collide', (event: any) => {
		console.log('inside');
	})
	engine.physics.world.addEventListener('endContact', (event: any) => {
		console.log('outside');

	})



}

