<template>
	<SpaceModal :show=show @closed="show = false">
		<div class="content">
			<img src="/rspSpace/img/game.png">
			<h2>Your Score:</h2>
			<h1>{{ store.hits }}</h1>
			<div class="btn" @click="show = false">Close</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { ArtworkType } from '~/space/lib/gltfExtensions/ArtworkExtension';
import Engine from '@ravespaceio/rave-engine';
import { useGameStore } from '~/store/game';
import { getSpace } from '~/space/space';
import { getEngine } from '~/space/engine';

const show = ref(false as boolean)

const space = getSpace()
const engine = getEngine()

const store = useGameStore()

space.eventbus.on("gameFinished", (state) => {
	show.value = state
	if (state) {
		setTimeout(() => {
			engine.inputManager.unLockCursor()
		}, 50);
	}
})


</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.content {
	color: white;
	@extend .flex-center;
	flex-direction: column;

	img {
		width: 300px;
		height: auto;
		max-width: 100%;
	}

	h2 {
		margin: 10px 0 20px;
	}

	h1 {
		font-size: 40px;
		background: white;
		color: black;
		font-weight: 700;
		padding: 20px;
		min-width: 80px;
		@extend .flex-center;
		border-radius: $radius;
		margin-bottom: 20px;
	}
}
</style>
