import { Role } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Role'
import { defineStore } from 'pinia'


// https://pinia.vuejs.org/core-concepts/getters.html#passing-arguments-to-getters
// https://www.youtube.com/watch?v=JGC7aAC-3y8
// the key of the type is the channelName

export type ActiveChannelsInfo = { [key: string]: { muted: boolean, broadcasting: boolean } };

export const useVoiceRolesStore = defineStore({
	id: "voiceRolesStore",
	state: () => ({
		// own user states
		userRole: Role.default,
		activeHostChannelInfo: {} as ActiveChannelsInfo,
		activeAudienceChannelInfo: {} as ActiveChannelsInfo,
		isJoined: false,
		volumenLevel: 0,
	}),
	getters: {
		// combined states
		activeHostChannels(): string[] {
			return Object.keys(this.activeHostChannelInfo);
		},
		activeAudienceChannels(): string[] {
			return Object.keys(this.activeAudienceChannelInfo);
		},
		activeHostChannelInfoLength(): number {
			return Object.keys(this.activeHostChannelInfo).length
		}
	},
	actions: {
		mute(channelName: string) {
			const state = { ...this.activeHostChannelInfo };
			state[channelName].muted = true;
			this.activeHostChannelInfo = state;
		},
		unmute(channelName: string) {
			const state = { ...this.activeHostChannelInfo };
			state[channelName].muted = false;
			this.activeHostChannelInfo = state;
		},
		join_leave() {
			this.isJoined = !this.isJoined;
		},
	}
})

