import Engine, { NavPlayeableObjComponent, RavespaceEvent } from "@ravespaceio/rave-engine";
import GUI from "lil-gui";
import { findObject, findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { Object3D } from "three"
import { GuiHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiHelper";
import BigGui from "@ravespaceio/rave-engine/build/engine/src/helper/gui/BigGui";
import { getEngine } from "~/space/engine";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";


export type NavigationControllerTypes = 'playerNav' | 'camNav'

export default class SpawnManager<T extends string = string> {

	public defaultSpawnName: T = "spawn" as T
	public readonly userDataIdentifier: string = "PlayerLocation"
	private readonly _spawns: { [spawnID: string]: Object3D } = {}

	constructor() {

	}

	/**
	 * parse a objects children for emptys and find spawn points
	 */
	public parseScene(scene: Object3D) {
		const engine = getEngine()
		const spawnFolder = engine.debuggui.biggui.getFolderOrCreate("Space").getFolderOrCreate("Spawns")

		const spawns = findObjects(scene, testUserData(this.userDataIdentifier))
		if (spawns.length === 0) return;

		for (const spawn of spawns) {
			const spawnID = spawn.userData[this.userDataIdentifier]
			this._spawns[spawnID] = spawn
			GuiHelper.addButton(spawnFolder, () => this.spawnAt(spawn.userData.PlayerLocation)).name(spawn.userData.PlayerLocation);
		}
	}

	public getSpawn(spawnID: T): Object3D {
		return this._spawns[spawnID]
	}

	public getSpawnList(): string[] {
		return Object.keys(this._spawns)
	}

	public hasSpawn(spawnID: T): boolean {
		return !!this.getSpawn(spawnID)
	}

	public spawnDefault(offset?: THREE.Vector3) {
		const defaultSpawned = this.spawnAt(this.defaultSpawnName, offset)
		if (!defaultSpawned) { console.warn("Cannot find default spawn point") }
	}

	public spawnAt(spawnID: T, offset?: THREE.Vector3): boolean {
		var spawn = this.getSpawn(spawnID)
		if (!spawn) return false
		if (offset) {
			spawn = spawn.clone()
			spawn.position.add(offset)
		}
		this.spawnAtObject(spawn)
		return true
	}

	public spawnAtObject(spawn: THREE.Object3D) {
		const engine = getEngine()
		engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, NavPlayeableObjComponent>('playerNav').jumpToEmpty(spawn, "XZY")
		engine.player.getPlayer<PlayerObject>().avatar.cancelCurrentUserAction()
		engine.player.camControllerM.goBehindPlayer(false);
	}
}
