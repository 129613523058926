import Engine, { EngineUtils, FlickerAnimatedLight, FlickerAnimation, MyLoaders, VolumetricLightMaterial } from "@ravespaceio/rave-engine";
import ParticleFactory from "@ravespaceio/rave-engine/build/engine/src/objects/particles/ParticlePresets";
import * as THREE from "three";
import { Vector3, Object3D, Color } from "three";
import LaserArrayC from "./LaserArrayC";
import LaserPlane from "./LaserPlane";
import MovingHead from "./MovingHead";
import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { usePanelStore } from "~/store/panel";
import { useGameStore } from "~/store/game";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import setupNeons from "./neons";



export default async function setupShowElements() {

	const space = getSpace()
	const engine = getEngine()
	const gltf = await space.loader.getLoadingPromise("stadium")
	const gameStore = useGameStore()
	const panelStore = usePanelStore()

	setupNeons()

	const clubEffectsGroup = new THREE.Group()
	clubEffectsGroup.name = "ClubEffectsGroup"
	engine.userData.clubEffectsGroup = clubEffectsGroup
	engine.scene.add(clubEffectsGroup)

	// SMOKE
	let smokeState: any
	space.eventbus.onAlready("smoke", (data: any) => { smokeState = data })
	const smokeEmpties = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "FXID", "smoke")
	for (const e of [smokeEmpties[0], smokeEmpties[1]]) {
		const smoke = ParticleFactory.createSmoke(engine.player.camera)
		smoke.initialVelocity.set(0, 1, 25)
		smoke.size = 0.8
		engine.debuggui.biggui.addToSceneFolder(smoke)
		e.add(smoke)

		engine.loop.register((dt) => {
			dt = Math.min(dt, 0.5)
			smoke.update(dt)
			if (smokeState) smoke.spawnParticles(dt)
		})
	}

	// FIRE
	var fireState: any
	space.eventbus.onAlready("fire", (data: any) => { fireState = !!data })
	const fireEmpties = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "FXID", "fire")

	for (const e of fireEmpties) {
		const fire = ParticleFactory.createFire(engine.player.camera)
		fire.size = 0.3
		fire.speed = 0.15
		fire.lifetime = 0.3
		fire.amount = 1
		fire.drag = 0.2
		e.add(fire)
		fire.initialVelocity.y = 18
		fire.pointSpawnRange.set(0.2, 0.3, 0.2)
		engine.debuggui.biggui.addToSceneFolder(fire)

		const pl = new THREE.PointLight(0xFF3300, 0)
		fire.add(pl)
		const fl = new FlickerAnimation(1)
		fl.offset = Math.random()

		engine.loop.register((dt) => {
			fire.update(dt)
			if (!fireState) {
				pl.intensity = 0
				return;
			}
			dt = Math.min(dt, 0.5)
			fire.spawnParticles(dt)
		})
	}


	// // FIRE SOUND
	// engine.audioManager.setupPromise.then(() => {
	// 	const fireSound = new THREE.PositionalAudio(engine.audioManager.listener);
	// 	fireSound.name = "FireAudio"
	// 	fireSound.position.set(22, 0.5, 24)
	// 	engine.scene.add(fireSound);
	// 	MyLoaders.audioLoader.load('/YouSpace/audio/fire.mp3', (buffer) => {
	// 		fireSound.setBuffer(buffer);
	// 		fireSound.setRefDistance(10);
	// 		fireSound.setLoop(false)
	// 		fireSound.setVolume(0.6)
	// 		fireSound.hasPlaybackControl = true
	// 		engine.audioManager.registerMedia(fireSound)
	// 		space.eventbus.onAlready("fire", (data: any) => { if (data) { if (fireSound.isPlaying) fireSound.stop(); fireSound.play() } }) // try catch
	// 	});
	// })



	// LASER MARTIX
	let laserArrayVisible = true
	let laserarray_sequence: number
	const lc = new LaserArrayC()
	const lcMat = lc.getMaterial()
	lc.position.set(-38.53284916625601, 5, 57.980)
	clubEffectsGroup.add(lc)

	space.eventbus.onAlready("laserarray_sequence", (data: any) => {
		laserArrayVisible = !!data
		laserarray_sequence = data
	})
	space.eventbus.onAlready("laserarray_color", (data: any) => {
		lcMat.getLightColor().setHex(hashToHexColor(data))
	})
	engine.loop.register((dt) => {
		lc.visible = laserArrayVisible
		lc.sequenzState = laserarray_sequence
		if (laserArrayVisible) lc.update(dt)
	})


	// MOVING HEADS
	const goboTargetVecL = new THREE.Vector3(-50, 14, 88)
	// GuiThreeHelper.addVector(engine.debuggui.biggui, goboTargetVecL).title("GoboTargetVectorL")

	const goboTargetVecR = new THREE.Vector3(-50, 18, 30)
	// GuiThreeHelper.addVector(engine.debuggui.biggui, goboTargetVecR).title("GoboTargetVectorR")


	const goboLampMat = new THREE.MeshLambertMaterial()
	EngineUtils.Findings.findMeshs(gltf.scene, testUserData("FXID", "stage_lights")).forEach((m) => { if ((m.material as THREE.Material).name == "Emission") m.material = goboLampMat })
	const goboEmtpies = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "FXID", "gobo")
	const goboSpots = [goboEmtpies[0], goboEmtpies[1], goboEmtpies[2], goboEmtpies[3]]
	const mhGroup = new THREE.Group()
	clubEffectsGroup.add(mhGroup)

	const mhMatL = new VolumetricLightMaterial(goboTargetVecL)
	mhMatL.uniforms.attenuation.value = 16
	mhMatL.uniforms.anglePower.value = 6

	const mhMatR = new VolumetricLightMaterial(goboTargetVecR)
	mhMatR.uniforms.attenuation.value = 16
	mhMatR.uniforms.anglePower.value = 6


	engine.debuggui.biggui.addToSceneFolder(mhMatL)
	engine.debuggui.biggui.addToSceneFolder(mhMatR)

	for (var i = 0; i < goboSpots.length; i++) {
		const gobo = goboSpots[i]
		const leftOrRight = gobo.userData.REID
		// console.log(gobo.userData.REID)
		// const leftOrRight = i % 2 == 0 ? 1 : -1
		let mh: any
		if (leftOrRight == "goboR") {
			mh = new MovingHead(mhMatR)
		} else {
			mh = new MovingHead(mhMatL)
		}

		mh.scale.set(3, 10, 3)

		mh.position.copy(gobo.position)
		mhGroup.add(mh)
		// gobogroup.add(mh.target) // helper

		engine.loop.register((dt) => {
			if (!mhGroup.visible) return;
			const t = performance.now() / 1000
			if (leftOrRight == "goboR") {
				mh.target.position.copy(goboTargetVecR)
			} else {
				mh.target.position.copy(goboTargetVecL)
			}

			mh.target.position.x += Math.sin(t) * 4
			mh.target.position.y += Math.sin(t) * 2
			mh.target.position.z += 1 * 1.2 + Math.sin(i + t * 0.8) * 2
			mh.update()
		})
	}

	space.eventbus.onAlready("movingHeads_intensity", (data: any) => {
		mhMatR.uniforms.anglePower.value = 10 - data // [0; 10] coming from admin panel
		mhMatL.uniforms.anglePower.value = 10 - data // [0; 10] coming from admin panel
		mhGroup.visible = data != 0
	})
	space.eventbus.onAlready("movingHeads_color", (data: any) => {
		const hexColor = hashToHexColor(data)
		mhMatR.uniforms.lightColor.value.setHex(hexColor)
		mhMatL.uniforms.lightColor.value.setHex(hexColor)
		// goboLampMat.color.setHex(hexColor) // updated irgendwie nich
	})



	// STROBO POINT LIGHTS
	const stroboGroup = new THREE.Group()
	clubEffectsGroup.add(stroboGroup)
	const light0 = new THREE.PointLight(0xFF0000, 0, 7.5, 0.5);
	light0.position.set(-44.6, 3, 64);
	stroboGroup.add(light0);
	engine.debuggui.biggui.addToSceneFolder(light0)
	const flicker0 = new FlickerAnimatedLight(light0, 1)
	const light1 = new THREE.PointLight(0xFF0000, 0, 7.5, 0.5);
	light1.position.set(-44.35, 3, 55.7);
	stroboGroup.add(light1);
	engine.debuggui.biggui.addToSceneFolder(light1)
	const flicker1 = new FlickerAnimatedLight(light1, 1)
	engine.loop.register((dt, time) => {
		if (!stroboEnabled) return;
		flicker0.update(dt, time)
		flicker1.update(dt, time)
	})
	// Live panel
	space.eventbus.onAlready("pointLightLeft_color", (color) => {
		light0.color.setHex(color.replace('#', '0x'));
	})
	space.eventbus.onAlready("pointLightRight_color", (color) => {
		light1.color.setHex(color.replace('#', '0x'));
	})
	space.eventbus.onAlready("pointLightLeft_intensity", (intensity) => {
		flicker0.baseIntensity = intensity
		light0.intensity = intensity
	})
	space.eventbus.onAlready("pointLightRight_intensity", (intensity) => {
		flicker1.baseIntensity = intensity
		light1.intensity = intensity
	})

	var stroboEnabled = false
	space.eventbus.onAlready("enableStrobo", (enabled) => {
		stroboEnabled = !!enabled
		light0.intensity = flicker0.baseIntensity
		light1.intensity = flicker1.baseIntensity
	})
}

export function hashToHexColor(color: string): number {
	return parseInt(color.replace("#", "0x"), 16)
}
