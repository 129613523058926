<template>
	<div id="mobile-chat-background" v-show="showChat && $isMobile"></div>
	<div id="chat-wrap" v-show="showChat" :class="[isChattingDesktop ? 'isChatting' : '', $isMobile ? 'mobile' : '']">

		<IconCloseBlack v-if="$isMobile" style="z-index:5; right: 0px; top: -50px;" @click="showChat = false" />

		<div v-if="mpStore.isInRoom" class="ui-tag-w">
			<div class="tag ui-tag">ServerId: {{ mpStore.roomId }} </div>
		</div>

		<div id="chat">
			<p v-for="(chat, i) in chats" :key="i">
				<span :style="chat.SenderClientId == mpStore.playerId ? 'color: white;' : chat.SenderPlayerName == 'admin' ? 'color: yellow;' : ''">
					{{ chat.SenderPlayerName }}:
				</span>
				{{ chat.chatMessage }}
			</p>
		</div>

		<div class="input-wrap">
			<p v-if="!$isMobile">Press Enter to toggle Chat</p>
			<form autocomplete="off" method="post" action="">
				<input autocomplete="false" name="hidden" type="text" style="display:none;">
				<input id="chat-input" type="text" role="presentation" autoComplete="off">
			</form>
			<svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" @click="sendMsg()">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33873 0.159095L10.8389 3.61591C11.0537 3.82804 11.0537 4.17196 10.8389 4.38409L7.33873 7.8409C7.12394 8.05303 6.7757 8.05303 6.56091 7.8409C6.34612 7.62878 6.34612 7.28485 6.56091 7.07272L9.12218 4.54319L0.55 4.54319C0.246244 4.54319 3.75917e-07 4.29999 3.49691e-07 4C3.23465e-07 3.70001 0.246244 3.45681 0.55 3.45681L9.12218 3.45681L6.56091 0.927276C6.34612 0.715149 6.34612 0.371222 6.56091 0.159095C6.7757 -0.0530325 7.12394 -0.0530325 7.33873 0.159095Z" fill="#FA5325" />
			</svg>
		</div>
	</div>
</template>


<script setup lang="ts">
import { Chatmessage_I } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Chatmessage_I'
import { blockForFrontend, canUseActionKey, unblockFromFrontend } from '~/space/lib/manager/PCISystem'
import { useToast } from 'vue-toastification'
import { validateText } from '~/assets/js/text'
import { getEngine } from '~/space/engine'
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space'
import { useMultiplayerStore } from '~~/src/store/multiplayer'
import { storeToRefs } from 'pinia'

const space = getSpace()
const engine = getEngine()
const toast = useToast()
const spaceStore = useSpaceStore()
const mpStore = useMultiplayerStore()

const { chats } = storeToRefs(mpStore)
const { showChat } = storeToRefs(spaceStore)
showChat.value = !space.browser.isMobile // initially hide on mobile
const isChattingDesktop = ref(false) // for chat animation desktop

watch(showChat, (state) => {
	if (space.browser.isMobile) state ? engine.inputManager.blockInput() : engine.inputManager.unBlockInput();
})
watch(chats, () => {
	chatEl.scrollTo(0, chatEl.scrollHeight);
})

// elements
let chatInputEl: HTMLInputElement
let chatEl: HTMLDivElement
onMounted(() => {
	chatInputEl = document.getElementById("chat-input") as HTMLInputElement
	chatEl = document.getElementById("chat") as HTMLDivElement
})

// open close wihth keyboard
space.eventbinder.bindDocument('keydown', (event) => {
	if (!showChat.value) return;
	if (event.key == "Enter") {
		if (space.browser.isMobile) {
			sendMsg()
		} else {
			if (!isChattingDesktop.value && canUseActionKey()) {
				isChattingDesktop.value = true
				engine.inputManager.blockInput();
				chatInputEl.focus()
			} else if (isChattingDesktop.value) {
				sendMsg()
				isChattingDesktop.value = false
				engine.inputManager.unBlockInput();
				chatInputEl.blur()
			}
		}
	}
})

// send msg
function sendMsg() {
	const text = chatInputEl.value
	if (text.trim() != "") {
		if (!validateText(text)) {
			toast.error("Please do not be that mean.")
		} else {
			engine.multiplayer!.chatHandler.sendAllChatMessage(text)
		}
	}
	chatInputEl.value = ""
	setTimeout(() => { chatEl.scrollTo({ top: chatEl.scrollHeight, behavior: "smooth" }); }, 501)
}

</script>


<style scoped lang="scss">
@import "assets/css/variables";


#mobile-chat-background {
	width: 100%;
	height: 100%;
	bottom: 0;
	background: $darkTransparent;
	left: 0;
	position: fixed;
	z-index: 10;
	padding: 10px 10px 80px 10px;
}



.ui-tag-w {
	display: flex;
	position: absolute;
	top: 4px;
	left: 4px;

	.ui-tag {
		position: relative;
		left: inherit;
		top: inherit;

		span {
			font-weight: 700;
		}
	}
}



#chat-wrap.mobile {
	opacity: 1;
	position: fixed;
	bottom: 50px;
	left: 20px;
	z-index: 99;
	width: calc(100% - 40px);
	display: flex;

	#chat,
	#chat-input {
		background: rgba(0, 0, 0, 0.9);
	}

	#chat {
		height: 400px;
		max-height: 80vh;
	}
}

#chat-wrap {
	width: 300px;
	flex-direction: column;
	opacity: 0.55;
	transition: opacity 0.3 ease;

	#chat {
		@extend .box;
		width: 100%;
		padding: 14px;
		margin-bottom: 6px;
		font-size: 15px;
		height: 150px;
		overflow-y: hidden;
		padding-top: 30px;
		transition: height ease 0.5s;
		border: 1px solid #ffffff26;

		p {
			font-size: 12px;
			padding-bottom: 3px;
			word-break: break-all;
			font-weight: 400;
			border-bottom: 1px solid rgba(255, 255, 255, 0.05);
			color: $main;
			opacity: 0;
			transition: opacity 0.3s;

			span {
				font-weight: 700;
				margin-right: 4px;
				opacity: 0.7;
				white-space: nowrap;
				max-width: 80px;
				overflow: hidden;
				color: $main;
				text-overflow: ellipsis;
				position: relative;
				display: inline-block;
				transform: translateY(3px);
			}
		}

		p:nth-last-child(-n+5) {
			opacity: 1;
		}

		p:last-child {
			border-bottom: none;
			margin-bottom: 12px;
		}
	}

	.input-wrap {
		position: relative;
		height: 40px;

		svg {
			position: absolute;
			right: 16px;
			top: 13px;
			width: 18px;
			height: auto;
			transition: ease 0.3;
			opacity: 0;
			transform: translateX(4px);
			transition: ease 0.3s;

			* {
				fill: $main;
			}
		}

		p {
			position: absolute;
			left: 14px;
			top: 12px;
			font-size: 14px;
			transition: ease 0.3s;
			color: $main;
			font-weight: 600;
		}

		form {
			height: 100%;
		}

		#chat-input {
			border: 1px solid #ffffff26;
			width: 100%;
			height: 100%;
			@extend .box;
			color: $main;
			padding: 0 40px 0 10px;
			font-weight: 700;
			font-size: 15px;
			-webkit-appearance: none;
			outline: none;
			text-align: left;
		}
	}

	&.isChatting {
		opacity: 1;

		#chat {
			height: 200px;

			p:nth-last-child(-n+7) {
				opacity: 1;
			}
		}

		.input-wrap {
			svg {
				opacity: 1;
				transform: 0;
			}

			p {
				opacity: 0;
				transform: translateX(-6px);
			}
		}
	}
}
</style>
