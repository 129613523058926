import { Logging, LoggingArea } from "@ravespaceio/rave-engine";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";


export async function setupAgoraCloudPlayer(): Promise<any> {
	const engine = getEngine()
	const space = getSpace()
	if (!engine.multiplayer || !engine.config.voicechat) {
		return;
	}

	engine.multiplayer.agoraSdk.setAgoraLogLevel(4);
	const cloudPlayerHandler = engine.multiplayer.cloudPlayerHandler;


	try {
		const agoraAppID = await fetch(`${engine.config.gameserverSocketURL}/agora/get_AppID`).then(res => res.json());

		// cloud Player client
		cloudPlayerHandler.setAgoraController({
			appId: agoraAppID.appID,
			codec: 'vp8',
			mode: "live",
		})
		Logging.trace("CP setup done", LoggingArea.Agora)

	} catch (err) {
		Logging.error("Failed to setup cloudPlayer", LoggingArea.Agora)
		return;
	}

	cloudPlayerHandler.debugAgoraClient().on('stream-fallback', (uid: any, isFallbackOrRecover: 'fallback' | 'recover') => {
		if (isFallbackOrRecover === 'fallback') {
			Logging.debug('only audio fallback', LoggingArea.Agora);
		} else if (isFallbackOrRecover === 'recover') {
			Logging.debug('recover using video');
		}
	})

	cloudPlayerHandler.onCloudPlayerPublish.on(channel => {
		cloudPlayerHandler.joinChannel(channel!);
	})

	cloudPlayerHandler.onCloudPlayerUnpublish.on(() => {
		cloudPlayerHandler.leaveChannel()
	})

	cloudPlayerHandler.debugAgoraClient().on('media-reconnect-end', (uid: any) => {
		Logging.debug('reconnect ends', LoggingArea.Agora);
		Logging.debug(uid, LoggingArea.Agora);
	})

	cloudPlayerHandler.debugAgoraClient().on('live-streaming-error', (url: any, err: any) => {
		Logging.debug(err, LoggingArea.Agora);

	})

	cloudPlayerHandler.debugAgoraClient().on('connection-state-change', (curState: string, revState: string, reason: string) => {

	})

	cloudPlayerHandler.debugAgoraClient().on('exception', (event: any) => {
		Logging.debug('exception', LoggingArea.Agora);
		Logging.debug(event, LoggingArea.Agora);

	})

}
