import Engine, { Water } from "@ravespaceio/rave-engine";
import GUI from "lil-gui";
import { Mesh, MeshStandardMaterial, Vector3 } from "three";
import * as THREE from "three"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import { getSpace } from "~~/src/space/space";
import { findMaterial, testName } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { getEngine } from "~/space/engine";


export function setupWaterMesh(waterBaseMesh: Mesh) {

	const space = getSpace()
	const engine = getEngine()

	waterBaseMesh.name = "WaterMeshBase"
	engine.debuggui.biggui.addToSceneFolder(waterBaseMesh)

	const waterParams = {
		textureWidth: 512,
		textureHeight: 512,
		waterNormals: getMatMap(space.loader, "/RaveEngine/textures/water/waternormals.jpg"),
		sunDirection: new THREE.Vector3(),
		sunColor: new THREE.Color(0xFFFFFF),
		waterColor: new THREE.Color(0x5d9bc2),
		distortionScale: 1,
		normal: new Vector3(0, 1, 0),
		fog: true
	}

	const realWater = new Water(waterBaseMesh.geometry, waterParams)
	realWater.applyMatrix4(waterBaseMesh.matrix);
	realWater.material.userData.__noQualityTransfer = true
	engine.debuggui.biggui.addToSceneFolder(realWater, Water.addToGui).title(waterBaseMesh.name)
	realWater.material.side = THREE.DoubleSide

	engine.scene.add(realWater)
	engine.loop.register((dt) => {
		realWater.material.uniforms.time.value += dt / 5
	})

	space.eventbus.onAlready("qualitysetting", (q) => {
		realWater.visible = q === "high"
		waterBaseMesh.visible = !realWater.visible
	})
}
