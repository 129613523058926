import Engine, { EngineUtils, NavPlayeableObjComponent } from "@ravespaceio/rave-engine";
import { CamControllerSwitchOptions } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import { ObjectStats, StatsOptions } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Entities/ObjectStats";
import BoatBuilder from "@ravespaceio/rave-engine/build/engine/src/engine/player/Factories/BoatBuilder";
import NavigationEntity from "@ravespaceio/rave-engine/build/engine/src/engine/player/NavigationEntity";
import BoatObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/BoatObject";
import * as THREE from 'three';
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { NavigationControllerTypes } from "~/space/lib/manager/SpawnManager";
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import BoatEmpty from "../Boat/BoatEmpty";
import { BoatEntity } from "../Boat/Boat";
import MovementC from "@ravespaceio/rave-engine/build/engine/src/engine/player/MovementC";
import { toggleMount } from "~/space/lib/mechanic/mount";



export function setupBoat() {

	const space = getSpace()
	const engine = getEngine()
	const boatBuilder = new BoatBuilder();

	function boatFactory(options: StatsOptions) {
		boatBuilder.setStats(ObjectStats, options);
		boatBuilder.addEntity<BoatEmpty>('emptyboat', BoatEmpty);
		boatBuilder.addEntity<BoatEntity>('playerBoat', BoatEntity);
		const boatInstance = boatBuilder.getProduct();
		const boatNavComp = new NavPlayeableObjComponent(engine.navigation, { constrainedObject: boatInstance });
		engine.navigation.navigationComponentM.addNavigationComponent('boatNav', boatNavComp);
		boatNavComp.updateTargetVectorConstrain(boatInstance.position)
		boatInstance.render(engine);
		const boatEntity = boatInstance.getEntity('playerBoat') as BoatEntity
		boatEntity.inputPlayer = new MovementC(engine.inputManager);
		engine.loop.add(boatInstance);
		boatInstance.variableBehaviour.push((selfReference, dt) => { boatInstance.animateBoat(selfReference, dt) });

		const boatGltf = space.loader.getLoaded("boat") as GLTF
		const boatMesh = boatGltf.scene

		boatInstance.mesh = boatMesh
		boatInstance.add(boatInstance.mesh);
		boatInstance.name = "Boat"

		boatMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2)
		boatInstance.rotation.y = -Math.PI / 4;
		boatInstance.mesh!.position.set(0, -0.5, 0);
		boatInstance.position.set(12.7, -1, 47.65);
		engine.debuggui.biggui.addToSceneFolder(boatInstance)

		boatBuilder.reset();
		return boatInstance;
	}


	const bigBoat = boatFactory({ speed: 4, maxSpeed: 30, angularVelocity: 12, maxAcceleration: 8 })
	engine.navigation.navigationComponentM.getNavigationComponent('boatNav').setNavigationZones(["boat"]);

	const mountOptions_bigBoat: CamControllerSwitchOptions = {
		targetObject: bigBoat,
		maxZoomOut: 12,
		focusRadius: 0.15,
		autoRotateEnabled: false,
		autoDesiredDistance: 9,
		desiredManualDistance: 8,
	};

	const unmountPlayerObject: CamControllerSwitchOptions = {
		targetObject: engine.player.getPlayer<PlayerObject>(),
		maxZoomOut: 3,
		focusRadius: 0,
		autoRotateEnabled: false,
		autoRotationSpeed: 0.5
	}

	const camNav = engine.navigation.navigationComponentM.getNavigationComponent('camNav');
	const playerNav = engine.navigation.navigationComponentM.getNavigationComponent('playerNav');

	space.eventbus.on("toggleBoatMount", (state) => {
		if (state == "enter") {
			toggleMount(engine, engine.player.getPlayer<PlayerObject>(), bigBoat, mountOptions_bigBoat, { navName: 'playerNav', active: false });
			engine.player.camControllerM.desiredRotation.y = 2.4;
			engine.player.getPlayer<PlayerObject>().avatar.triggerAction("sitting", 0, true);
			engine.player.getPlayer<PlayerObject>().position.set(-0.75, 0.55, -1.2);
			engine.multiplayer!.mountHandler.setMountState("boat");
			camNav.updateCurrentZone('boat');

		}
		if (state == "leave") {
			toggleMount(engine, bigBoat, engine.player.getPlayer<PlayerObject>(), unmountPlayerObject, { navName: 'playerNav', active: true });
			engine.player.getPlayer<PlayerObject>().avatar.triggerAction("idle", 0, true);
			engine.player.getPlayer().position.set(0, 0, 0);
			engine.multiplayer!.mountHandler.setMountState("");
			playerNav.jumpTo(11.5, -0.62, 44.57);
			camNav.updateCurrentZone('main');

		}
	})

	engine.multiplayer!.mountHandler.onPlayerChangedMountStateEvent.on((mountData) => {
		const player = engine.multiplayer!.getOnlinePlayerInstance(mountData!.playerId)
		const hasboat = mountData!.mountValue == "boat"
		if (hasboat) {
			if (!player.userData.boat) {
				const boatClone = bigBoat.mesh!.clone()
				boatClone.position.set(0.75, -1.05, 1.2) // fix offset with player sitting
				boatClone.rotation.set(0, - Math.PI / 2, 0) // fix mesh default rotation
				player.userData.boat = boatClone
				player.add(boatClone)
			}
			player.userData.boat.visible = true
		} else if (player.userData.boat) player.userData.boat.visible = false
	})

	// @ts-ignore
	window['BoatRsp'] = bigBoat


}
