<template>
	<div class="row controls-row">
		<div class="l">Text</div>
		<div class="r">
			<div class="wrap">
				<input id="check-name-vis" v-model="showMy" type="checkbox" name="check-name-vis">
				<label for="check-name-vis">Show avatar names and chats</label>
			</div>
		</div>
	</div>
</template>


<script setup lang="ts">
import PlayerObject from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject';
import PlayerStatusBar from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerStatusBar';
import { getEngine } from '~/space/engine';



const showMy = ref(getVisible())
watch(showMy, setVisible)

const engine = getEngine();

function getVisible() {
	return PlayerStatusBar.initialVisibility
}
function setVisible(v: boolean) {
	PlayerStatusBar.initialVisibility = v
	engine.multiplayer.group.traverse((child) => {
		if (child instanceof PlayerStatusBar) { child.visible = v }
	})
	const player = engine.player.getPlayer<PlayerObject>()
	player.statusbar.visible = v
}
</script>
