<template>
	<div>
		<div id="spaceWrapper" ref="spaceWrapper">
			<Transition name="fade">
				<canvas v-show="onboardingDone" id="spaceCanvas" ref="spaceCanvas" />
			</Transition>

			<Transition name="fade">
				<div v-if="!onboardingDone" id="bgLoader">
					<IconLoader />
					<!--p>Teleporting to the Metaverse</p-->
				</div>
			</Transition>

			<SpaceOnboardingLoader />

			<template v-if="engineCreated">
				<SpaceInterface />
				<SpaceInterfaceMpDisconnectInfo v-if="hasMultiplayer" />
				<SpaceInterfaceDrinkProgress />
				<SpaceInterfaceHint />
				<SpaceInterfaceHintTop />
				<SpaceInterfaceTutorial />

				<SpaceModalNpc />
				<SpaceModalArtwork />
				<SpaceModalBar />
				<SpaceModalTreasurehunt />
				<SpaceModalGameFinished />
				<SpaceInterfaceMiniGame />
				<SpaceModalBigChat v-if="hasMultiplayer" />
				<SpaceMenu />
				<SpaceMenuAvatarGenerator />
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { getEngine, setupEngine } from '~/space/engine';
import { getSpace, setupSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';


setupSpace()

const spaceWrapper = ref({} as HTMLDivElement)
const spaceCanvas = ref({} as HTMLCanvasElement)

const engineCreated = ref(false)
const hasMultiplayer = ref(false)
const { onboardingDone } = storeToRefs(useSpaceStore())

onMounted(async () => {
	try {
		const engine = setupEngine(spaceCanvas.value)
		engineCreated.value = true
		hasMultiplayer.value = !!engine.config.gameserverSocketURL

		// move debuggui to spacewrapper only when not destroyed yet
		if (engine.debuggui.domElement.parentElement) { spaceWrapper.value.appendChild(engine.debuggui.domElement) }
	} catch (e) {
		console.log(e);
	}
})

onUnmounted(() => {
	try {
		const engine = getEngine()
		engine.destroy()
	} catch (e) {
		console.log(e);
	}
})
</script>



<style scoped lang="scss">
@import "assets/css/variables";
@import "assets/css/space";


#bgLoader {
	position: absolute;
	width: 100px;
	height: 100px;
	left: calc(50% - 50px);
	top: calc(50% - 50px);
	z-index: 0;
	@extend .flex-center;
	flex-direction: column;

	p {
		color: white;
		margin-top: 4px;
		font-size: 12px;
		text-align: center;
	}
}

#spaceCanvas {
	width: 100vw;
	height: 100vh;
	position: relative;
	z-index: 1;
}


#spaceWrapper {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: relative;

	#loader--museum {
		position: absolute;
		left: 50%;
		top: 50%;
	}
}
</style>
