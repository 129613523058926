import * as THREE from "three"
import { findMeshs, testUserData } from '@ravespaceio/rave-engine/build/engine/src/utils/findings'
import { getEngine } from '~/space/engine'


export function setupAlwaysPlayerFacing(obj: THREE.Object3D) {
	const engine = getEngine()
	engine.loop.register(() => {
		const ref = engine.player.getPlayer()
		obj.rotation.y = Math.atan2((ref.position.x - obj.position.x), (ref.position.z - obj.position.z));
		obj.updateMatrix()
	})
}


export function setupBillboardLockedY(obj: THREE.Object3D) {
	const engine = getEngine()
	engine.loop.register(() => {
		const ref = engine.player.getPlayer()
		obj.rotation.y = Math.atan2((ref.position.x - obj.position.x), (ref.position.z - obj.position.z));
		obj.updateMatrix()
	})
}
