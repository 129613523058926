import Engine, { CircleShadow, EngineUtils, RPMAvatar } from "@ravespaceio/rave-engine";
import * as THREE from "three"
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Group } from "three"
import { setupDj } from "./djVideo";
import { setupStageScreen } from "./screen";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";

export function setupStage() {
	setStageLayout()
	setupDj()
	setupStageScreen()
}


async function setStageLayout() {
	const engine = getEngine()
	const space = getSpace()

	var stage_chairs = new Group() // chairs
	var stage_lectern = new Group() // pult

	await space.loader.getLoadingPromise("stadium")
		.then((gltf: GLTF) => {
			const empties = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR")
			for (var i = 0; i < empties.length; i++) {
				if (empties[i].userData.CMSR == "stage_chairs") {
					stage_chairs.attach(empties[i])
				}
				if (empties[i].userData.CMSR == "stage_booth") {
					stage_lectern.attach(empties[i])
				}
			}
		})

	engine.scene.add(stage_chairs)
	engine.scene.add(stage_lectern)

	space.eventbus.onAlready("StageMode", (data: any) => {
		if (data == "panel") {
			stage_chairs.visible = false
			stage_lectern.visible = true
		}
		if (data == "chairs") {
			stage_chairs.visible = true
			stage_lectern.visible = false
		}
		if (data == "clear") {
			stage_chairs.visible = false
			stage_lectern.visible = false
		}
	});
}
