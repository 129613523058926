import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import * as THREE from "three";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";

export function replaceMaterial(mesh: THREE.Mesh, image: string) {
	const engine = getEngine()
	const space = getSpace()
	const mat = new THREE.MeshBasicMaterial()
	mesh.material = mat
	mat.alphaTest = 0.2
	mat.map = getMatMap(space.loader, image, { encoding: THREE.sRGBEncoding });
	mat.color.setHex(0xFFFFFF);
	mat.needsUpdate = true;
}
