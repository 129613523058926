<template>
	<SpaceModal :show=show @closed="show = false" class="big-chat">
		<div class="content">
			<div class="sidebar">
				<section>
					<p class="title">Chats</p>
					<div v-for="chat in chats" :key="chat.id" class="btn--menu" :class="activeChat == chat ? 'active' : ''" @click="loadChat(chat)">
						<IconGlobal v-if="chat.id == 'global'" />
						<IconGroup v-if="chat.id == 'room'" />
						<IconUser v-if="chat.id != 'global' && chat.id != 'room'" />
						{{ chat.name }}
						<template v-if="chat.id == 'room'"><span class="group-indicator">(Your Group)</span></template>
						<span v-if="chat.unread" class="unread">1</span>
					</div>
				</section>
				<section>
					<p class="title">Users</p>
					<div v-for="user in playersList" :key="user.id" class="btn--menu" @click="loadChat(user)">
						<IconUser />{{ user.name }}
					</div>
				</section>
			</div>
			<div class="main-chat">
				<div id="chat-wrapper" class="chats">
					<div v-for="(message, index) in messages" :key="index" class="m" :class="message.myMessage ? 'myMessage' : ''"><span>{{ message.SenderPlayerName }}</span>{{ message.chatMessage }}</div>
				</div>
				<div class="input-wrap">
					<div class="emojiList">
						<span v-for="emoji in emojis" :key="emoji" @click="addEmoji(emoji)">{{ emoji }}</span>
					</div>
					<input id="big-chat-input" v-model="messageInput" class="chat-input" type="text" autocomplete="off" @keyup.enter="send()">
					<div class="btn send" :class="messageInput ? '' : 'disabled'" @click="send()">Send</div>
				</div>
			</div>
		</div>
	</SpaceModal>
</template>

<script lang="ts">
import Vue from 'vue'
import { ChatMessageType, Chatmessage_I } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Chatmessage_I'
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser'
import { getSpace } from '~~/src/space/space'
import { useToast } from 'vue-toastification'
import { PlayerData_I } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/BaseTypes/Playerdata_I'
import { getEngine } from '~/space/engine'
import { useSpaceStore } from '~/store/space'


type Chat = {
	id: string,
	name: string,
	unread?: boolean
}

export default {
	data() {
		return {
			show: false,
			roomId: "",
			activeChat: undefined as any,
			chats: [
				{ id: "global", name: "Global", unread: false },
				{ id: "room", name: "Room", unread: false }
			] as Chat[],
			playersList: [] as PlayerData_I[],
			messages: [] as (Chatmessage_I & { myMessage?: boolean })[],
			messageStoreGroup: [] as Chatmessage_I[],
			messageStoreGlobal: [] as Chatmessage_I[],
			messageStoreDM: new Map(),
			messageInput: "",

			emojis: ["😀", "😅", "🤣", "😍", "😬", "🙄", "🤯", "😕", "😱", "😭", "😖", "👽", "💩"],
			notificationMessage: "Press 'B' to open."
		}
	},
	mounted() {
		const space = getSpace();
		const engine = getEngine()
		const spaceStore = useSpaceStore()

		if (space.browser.isMobile) {
			this.notificationMessage = "Open the Chat."
		}

		space.eventbinder.bindDocument("keyup", (e) => {
			if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
			if (e.key == "b" || e.key == "B") {
				if (!this.show) {
					this.show = true;
					engine.inputManager.unLockCursor();
					this.roomId = engine.multiplayer!.roomId || "";
					this.chats[1].name = this.roomId as string
					this.loadChat(this.chats[0])
					this.refreshPlayerlist();
					this.getRoomHistory()
				}
			}
		});



		this.activeChat = this.chats[0] as any
		engine.multiplayer!.chatHandler.onNewChatMessageEvent.on((msg: Chatmessage_I | undefined) => {
			if (!msg) return;
			/* DM */
			if (msg.messageType == ChatMessageType.Direct) {
				if (!this.show) {
					useToast().success("New DM from " + msg.SenderPlayerName + ". " + this.notificationMessage);
				}
				// TODO CHECK IF CHAT ALREADY EXISTS IN CHAT LIST
				let chatExists
				this.chats.forEach((chat) => {
					if (chat.id == msg.SenderClientId) {
						chatExists = true
					}
				});
				if (!chatExists) {
					this.chats.push({ name: msg.SenderPlayerName, id: msg.SenderClientId, unread: true })
				} else {
					this.activeChat.unread = true
				}
				if (!this.messageStoreDM.get(msg.SenderClientId)) {
					this.messageStoreDM.set(msg.SenderClientId, [])
				}
				this.messageStoreDM.get(msg.SenderClientId).push(msg)
			}

			/* ROOM */
			if (msg.messageType == ChatMessageType.Room) {
				this.messageStoreGroup.push(msg)
			}
			/* GLOBAL */
			if (msg.messageType == ChatMessageType.All) {
				this.messageStoreGlobal.push(msg)
			}
			this.scrollChatDown()
		});

		engine.multiplayer!.onGetAllPlayersDataEvent.on((allPlayersData) => {
			if (!allPlayersData) return;

			this.playersList = allPlayersData
			let chatExistsinList
			// remove chats from playerslist that already exist
			this.chats.forEach((chat) => {
				if (this.playersList.filter(e => e.id === chat.id).length > 0) {
					chatExistsinList = true
					this.playersList.splice(this.playersList.findIndex(e => e.id === chat.id), 1);
				}
			});
			this.playersList = allPlayersData

			// remove yourself from players list
			if (this.playersList.filter(e => e.id === engine.multiplayer!.playerId).length > 0) {
				this.playersList.splice(this.playersList.findIndex(e => e.id === engine.multiplayer!.playerId), 1);
			}

		});

	},
	methods: {
		refreshPlayerlist() {
			const engine = getEngine();
			engine.multiplayer!.requestGetAllPlayersData(); // triggers on allPlayersdata
		},
		send() {
			const engine = getEngine();
			if (this.messageInput == "" || !this.messageInput) {
				return
			}
			// Send Message
			if (this.activeChat == this.chats[0]) {
				engine.multiplayer!.chatHandler.sendAllChatMessage(this.messageInput);
			} else if (this.activeChat == this.chats[1]) {
				engine.multiplayer!.chatHandler.sendRoomChatMessage(this.messageInput);
			} else {
				engine.multiplayer!.chatHandler.sendDirectMessage(this.messageInput, this.activeChat.id)
			}
			this.activeChat.unread = false

			// Add own Message to map

			if (!this.messageStoreDM.get(this.activeChat.id)) {
				this.messageStoreDM.set(this.activeChat.id, [])
			}
			this.messageStoreDM.get(this.activeChat.id).push({
				SenderPlayerName: "Me",
				chatMessage: this.messageInput,
				myMessage: true
			})


			this.scrollChatDown()
			// Clear Input
			this.messageInput = ""
			if (this.messages.length < 1) { this.loadChat(this.activeChat) }
		},
		loadChat(user: Chat | PlayerData_I) {
			this.messages = []
			/* DM */
			if (user.id == "global") {
				this.activeChat = this.chats[0]
				this.messages = this.messageStoreGlobal
			}
			else if (user.id == "room") {
				this.messages = this.messageStoreGroup
				this.activeChat = this.chats[1]
			}
			else {
				// Push to Active Chat List
				let chatExists
				this.chats.forEach((chat) => {
					if (user.id == chat.id) {
						chatExists = true
					}
				});
				if (!chatExists) {
					this.chats.push(user)
				}
				this.activeChat = user
				this.activeChat.unread = false
				// Get Messages
				if (this.messageStoreDM.get(user.id)) {
					this.messages = this.messageStoreDM.get(user.id)
				} else {
					console.log("Error Loading DMs")
				}
			}
			this.refreshPlayerlist()
			document.getElementById("big-chat-input")!.focus();
			this.scrollChatDown()
		},
		getRoomHistory() {
			const engine = getEngine();
			console.log("get history")
			// for (const roomChatMessage of engine.multiplayer.chatHandler.chatHistoryRoom) {
			// 	console.log(roomChatMessage)
			// }
		},
		addEmoji(emoji: string) {
			this.messageInput = this.messageInput ? this.messageInput + emoji : '' + emoji
			document.getElementById("big-chat-input")!.focus();
		},
		scrollChatDown() {
			if (this.show) {
				const chatWrapper = document.getElementById("chat-wrapper")!
				setTimeout(function () {
					chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
				}, 50)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "assets/css/variables";
$line: #ffffff17;

.big-chat {
	.content {
		height: 100%;
		width: 100% !important;
		justify-content: flex-start !important;
		align-items: flex-start !important;
		overflow: hidden;
		flex-direction: row !important;

		.sidebar {
			width: 200px;
			border-right: 1px solid $line;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			overflow-y: auto;
			height: 100%;

			section {
				border-bottom: 1px solid $line;
				padding: 20px 20px 8px 20px;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-direction: column;
				width: 100%;

				.btn--menu {
					span {
						font-size: 10px;
						color: white;
						margin-left: 4px;
						opacity: 0.5;
					}

					&:hover {
						background: #ffffff21;
					}

					&.active {
						span {
							color: $dark;
							opacity: 1;
						}
					}
				}
			}

			p {
				margin: 0 !important;
				padding: 0 !important;
				text-align: left !important;
				color: $main;
			}

			p.title {
				font-weight: 600;
				letter-spacing: 1px;
				text-transform: uppercase;
				font-size: 11px;
				margin-bottom: 12px !important;
			}
		}

		.main-chat {
			height: 100%;
			position: absolute;
			left: 200px;
			width: calc(100% - 200px);
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			overflow-y: auto;
			top: 0;

			.input-wrap {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 20px;

				.btn {
					height: 40px;
					margin-left: 12px;
					padding: 0 10px;
					@extend .flex-center;
					font-size: 13px;
				}

				.chat-input {
					border: 1px solid #ffffff26;
					width: 100%;
					height: 40px;
					@extend .box;
					color: $main;
					font-weight: 700;
					font-size: 15px;
					-webkit-appearance: none;
					outline: none;
					padding: 0 0 0 15px !important;
					text-align: left;
				}
			}

			.chats {
				height: calc(100% - 140px);
				display: flex;
				align-items: flex-start;
				width: 100%;
				flex-direction: column;
				padding: 20px;
				overflow-y: scroll;
				margin-top: 20px;

				.m {
					display: flex;
					flex-direction: column;
					background: #ffffff;
					border-radius: $radius;
					padding: 7px 12px;
					font-size: 13px;
					color: black;
					margin-bottom: 12px;
					max-width: calc(100% - 100px);

					span {
						font-weight: 700;
						margin-bottom: 2px;
						font-size: 13px;
					}

					&.myMessage {
						align-self: flex-end;
						background: $main;
					}
				}
			}
		}


	}

}

.active {
	.unread {
		display: none;
	}
}

.unread {
	background: white;
	width: 14px;
	height: 14px;
	border-radius: 100%;
	margin-left: 6px;
	font-size: 11px;
	color: black;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
}

.emojiList {
	position: absolute;
	border-radius: $radius;
	width: calc(100% - 40px);
	left: 20px;
	bottom: 70px;
	opacity: 0.5;

	span {
		width: 27px;
		height: 27px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		border-radius: $radius;
		background: #ffffff26;
		cursor: pointer;
		margin-right: 6px;
		margin-bottom: 6px;
		opacity: 0.8;

		&:hover {
			opacity: 1;
			background: white;
			transform: scale(1.5);
		}
	}

	&:hover {
		opacity: 1;
	}
}

::-webkit-scrollbar {
	width: 2px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px transparent;
	-webkit-box-shadow: inset 0 0 6px transparent;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px $main;
	-webkit-box-shadow: inset 0 0 6px $main;
}

@media(max-width:750px) {
	.big-chat {
		.inner {
			.box {
				.content {
					flex-direction: column !important;

					.sidebar {
						width: 100%;
						height: 120px;

						section {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: row;
							padding: 10px;
							flex-wrap: wrap;

							p.title {
								display: none;
							}

							.btn--menu {
								width: auto;
								display: inline-flex;
								margin: 4px;
								background: #1b1b1b;
							}
						}
					}

					.main-chat {
						width: 100%;
						height: calc(100% - 120px);
						left: inherit;
						position: relative;

						.chats {
							border-top: 1px solid $main;
							margin-top: 0;
						}
					}
				}
			}
		}
	}

	.emojiList {
		display: none;
	}
}
</style>
