import Engine from "@ravespaceio/rave-engine"
import { clamp } from "@ravespaceio/rave-engine/build/engine/src/utils/math"


export type EffectPropertie = {
	/**
	 * @param enabled wather effect was enabled or disabled
	 */
	onEnableChanged: (enabled: boolean) => void,
	/**
	 * @param dt frame delta time
	 * @param intenisty [0; 1] */
	onUpdate: (dt: number, intenisty: number) => void,
}

export default class Effect {

	protected _updatedLinearIntensity: number = 0
	protected _effectIncreasig: boolean = false
	protected _enabled = false
	protected _timeRunning = 0

	constructor(public readonly props: EffectPropertie[], public duration: number = 20, public fadeDuration: number = 4) { }

	public update(dt: number) {
		if (!this.isRunning()) return;

		const c = dt / this.fadeDuration
		this._updatedLinearIntensity += this._effectIncreasig ? c : -c
		this._updatedLinearIntensity = clamp(this._updatedLinearIntensity, 0, 1)
		// console.log(this._effectIncreasig, c, this._updatedLinearIntensity);


		this._timeRunning += dt
		if (this._effectIncreasig) {
			if (this._timeRunning >= this.duration) {
				// console.log(this._timeRunning, this.duration, "out");
				this.fadeOut()
			}
		}
		else if (this._updatedLinearIntensity <= 0) {
			this._disable()
		}

		for (const prop of this.props) {
			prop.onUpdate(dt, this._updatedLinearIntensity)
		}
	}

	public fadeIn() {
		this._enable()
		this._effectIncreasig = true
	}

	public fadeOut() {
		this._effectIncreasig = false
	}

	public isEnabled(): boolean {
		return this._enabled
	}

	public isRunning(): boolean {
		return this.isEnabled() && (this._effectIncreasig == true || (this._effectIncreasig == false && this._updatedLinearIntensity != 0))
	}

	protected _enable(): void {
		this._reset()
		this._enabled = true
		this.props.forEach((prop) => { prop.onEnableChanged(true) })
	}

	protected _disable(): void {
		this._reset()
		this._enabled = false
		this.props.forEach((prop) => { prop.onEnableChanged(false) })
	}

	private _reset(): void {
		this._timeRunning = 0
		this._updatedLinearIntensity = 0
	}
}
