import Engine, { CircleShadow, EngineUtils, MyLoaders, RPMAvatar } from "@ravespaceio/rave-engine"
import { AmbientLight, MeshStandardMaterial, Group, Material, Matrix4, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import { getSpace } from "../space";
import { GuiEngineHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiEngineHelper";
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { findAllMaterials } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { getEngine } from "../engine";
import { getEnvMap } from "./scene/materials";



export function setupEnviroment() {

	const space = getSpace()
	const engine = getEngine()

	// renderer
	engine.renderer.renderer.toneMapping = THREE.ACESFilmicToneMapping
	engine.renderer.renderer.toneMappingExposure = 1
	engine.renderer.renderer.physicallyCorrectLights = true
	engine.renderer.renderer.outputEncoding = THREE.sRGBEncoding;

	// bg/env
	const scene = engine.scene
	scene.fog = new THREE.Fog(0x291c45, 20, 150);
	scene.background = new THREE.Color(0xA0A0A0);
	// engine.scene.overrideMaterial = new THREE.MeshPhysicalMaterial()

	let useNightSky = false
	function loadSkyMap() {
		const q = space.eventbus.getEventValue("qualitysetting")
		const mapRes = space.browser.isMobile ? 2 : q == "low" ? 2 : q == "mid" ? 4 : q == "high" ? 8 : 2
		let path = `/${space.project.publicPath}/textures/sky_${mapRes}k.jpg`
		if (useNightSky) path = `/${space.project.publicPath}/textures/sky_8k_night.jpg`
		getMatMap(space.loader, path, {
			encoding: THREE.sRGBEncoding,
			mapping: THREE.EquirectangularReflectionMapping,
			flipY: true,
			onLoad: (tex) => { if (space.eventbus.getEventValue("qualitysetting") == q) scene.background = tex }
		})
	}

	// avatars
	const shadowPlane = new CircleShadow()
	RPMAvatar.onInstanceCreated = (avatar) => {
		const sp = shadowPlane.clone()
		sp.position.y += 0.01
		avatar.add(sp)

		const env = getEnvMap()
		findAllMaterials(avatar).forEach((m) => {
			const mat = m as THREE.MeshStandardMaterial
			mat.envMap = env
			mat.envMapIntensity = 1
		})
	}

	const sunLightIntensity = 1.6
	const ambiLightIntensity = 0.3
	const directionalLightIntensity = .4

	const ambiLight = new THREE.AmbientLight(0x9980ff);
	ambiLight.intensity = ambiLightIntensity
	engine.scene.add(ambiLight);
	engine.debuggui.biggui.addToSceneFolder(ambiLight);


	const sunLight = new THREE.DirectionalLight(0xffbb9e);
	sunLight.position.set(-500, 200, 700)
	sunLight.intensity = sunLightIntensity;
	sunLight.castShadow = false;
	sunLight.target = engine.player.getPlayer<PlayerObject>();
	engine.scene.add(sunLight);
	engine.debuggui.biggui.addToSceneFolder(sunLight)

	const directionalLight = new THREE.DirectionalLight(0xc0b8ff);
	directionalLight.position.set(300, 120, -200)
	directionalLight.intensity = directionalLightIntensity;
	directionalLight.castShadow = false;
	directionalLight.target = engine.player.getPlayer<PlayerObject>();
	engine.scene.add(directionalLight);
	engine.debuggui.biggui.addToSceneFolder(directionalLight)



	space.eventbus.onAlready("qualitysetting", (q) => {
		loadSkyMap()
	})

	space.eventbus.onAlready("dimLights", (value: any) => {
		if (value) {
			useNightSky = true
			sunLight.intensity = 0
			ambiLight.intensity = 0
			directionalLight.intensity = 0
			space.eventbus.emit("changeLightmapIntensity", 0.3)
		} else {
			useNightSky = false
			sunLight.intensity = sunLightIntensity
			ambiLight.intensity = ambiLightIntensity
			directionalLight.intensity = directionalLightIntensity
			space.eventbus.emit("changeLightmapIntensity", engine.userData.lightMapIntensity)
		}
		loadSkyMap()
	})
}


