// import * as THREE from "three"
// import VRManager, { FontInfo } from "./VRManager"
// import { getSpace } from "~/space/main";
// import { Logging } from "@ravespaceio/rave-engine";


export async function setupVR() {

	// const space = getSpace();

	// // no tutorial for VR
	// Logging.info("Skipping tutorial because VR device")
	// localStorage.setItem("tutorialPlayed", "true")
	// space.eventbus.emit("tutorialDone");

	// // VR SETUP ------------------

	// const font: FontInfo = { json: "/rspSpace/fonts/Roboto-msdf.json", png: "/rspSpace/fonts/Roboto-msdf.png" };
	// const vr = new VRManager(space, "StartZone", font, false);

	// vr.addArea("Spawn", new THREE.Vector3(22.64, 7.06, 20.16), false);

	// vr.addArea("SkyBar", new THREE.Vector3(-50.43, 19.59, -43.90), false);

	// vr.addArea("Stadium", new THREE.Vector3(-24.89, 2.35, 59.77), false);

}
